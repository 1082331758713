import { Grid, Typography } from "@mui/material";
import React from "react";

const Banner = (props) => {
  const { content } = props;
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      item
      xs={12}
      style={{ background: "#D4AF37" }}
    >
      <Typography variant="caption" style={{ color: "black" }}>
        {content}
      </Typography>
    </Grid>
  );
};

export default Banner;
