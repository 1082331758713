import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import React from "react";
import { Corp } from "./ContractRequestStepper";

export default function InitialStep(props: any) {
  const {
    setSelectedCarrier,
    carrierOptionsArray,
    selectedCarrier,
    setStatesRequested,
    states,
    statesRequested,
    setLicenseNumber,
    licenseNumber,
    isScan,
    corp,
    setCorp,
    isAnthem,
    mobile,
    setNotes,   
    notes,
    setUnlistedCarrier,
    unlistedCarrier,
    defaultCarrier,
  } = props;
  const { isAsCorp, corpName, corpNpn, corpTin, isAsIndividual } = corp;
  const corpChange = (value: boolean | string, name: string) => {
    setCorp((corp: Corp) => {
      return { ...corp, [name]: value };
    });
  };

  const unlistedCarrierChange = (e: { target: { name: any; value: any; }; }) => {
        setSelectedCarrier({ id: "-999", name: e.target.value });
      };

  return (
    <Box component="form" noValidate autoComplete="off">
        <Grid
                item
                xs={12}
                container
                alignItems="center"
                style={{ padding: 2 }}
              >
                <Grid item>
                  <Typography variant="body2">Carrier not listed?</Typography>
                </Grid>
                <Checkbox
                  id="filled-unlistedCarrier-input"
                  name="unlistedCarrier"
                  checked={unlistedCarrier}
                  onChange={(e) => {
                        setUnlistedCarrier(e.target.checked);
                        setSelectedCarrier(defaultCarrier);
                      }} // unlistedCarrier is boolean
                  color="primary"
                  size="small"
                />
        </Grid>
        <Grid item container>
                <Grid item xs={6} style={{ padding: 2 }}>
                {
                !unlistedCarrier ? (
                <TextField
                        fullWidth
                        select
                        name="listCarrier"
                        label="Carrier"
                        value={selectedCarrier}
                        onChange={(e) => {
                                setLicenseNumber("");
                                setNotes("");
                                corpChange(false, "isAsIndividual");
                                setSelectedCarrier(e.target.value);
                        }}
                        sx={{ mb: 1, mt: 1 }}
                        required
                >
                         {carrierOptionsArray?.map((carrier: any) => (
                        <MenuItem key={carrier.id} value={carrier}>          
                        {carrier.name}
                        </MenuItem>
                        ))}
                </TextField>
                ) : (
                <TextField
                        fullWidth

                        name="textCarrier"
                        inputMode="text"
                        label="Enter Carrier"
                        value={selectedCarrier.name}
                        onChange={unlistedCarrierChange}  
                                
                        sx={{ mb: 1, mt: 1 }}
                        required

                        error={
                                selectedCarrier.name === "" && selectedCarrier.id !== ""
                        } 
                        helperText={
                                selectedCarrier.name === "" && "Please enter a carrier."
                        }
                />
                )
                }
                </Grid>
        </Grid>


      <Autocomplete
        multiple
        limitTags={mobile ? 2 : 5}
        options={states}
        value={statesRequested}
        onChange={(e: any, value: any) => setStatesRequested(value)}
        disableCloseOnSelect
        getOptionLabel={(option: any) => option.name}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: any, index: number) => (
            <Chip
              color="primary"
              variant="filled"
              label={option.code}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBox />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="States Requested"
            sx={{ mb: 1 }}
            required
          />
        )}
      />

        <TextField
          fullWidth
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          sx={{ mb: 1 }}
        />



      {isScan && (
        <TextField
          fullWidth
          label="License Number"
          value={licenseNumber}
          onChange={(e) => setLicenseNumber(e.target.value)}
          required={isScan}
          sx={{ mb: 1 }}
        />
      )}
      <FormGroup row sx={{ justifyContent: "flex-start" }}>
        <FormControlLabel
          value="top"
          control={
            <Checkbox
              checked={isAsCorp}
              onChange={(e) => {
                selectedCarrier === "24" && corpChange(false, "isAsIndividual");
                corpChange(e.target.checked, "isAsCorp");
              }}
            />
          }
          label="Contracting As Corp?"
          labelPlacement="start"
        />
        {selectedCarrier === "24" && isAsCorp && (
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                checked={isAsIndividual}
                onChange={(e) => corpChange(e.target.checked, "isAsIndividual")}
              />
            }
            label="Contracting As Individual?"
            labelPlacement="start"
          />
        )}
      </FormGroup>
      {isAsCorp && (
        <>
          <TextField
            fullWidth
            label="Corp Name"
            value={corpName}
            onChange={(e) => corpChange(e.target.value, "corpName")}
            sx={{ mb: 1 }}
            required
          />
          <Grid container flexDirection="row" spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Corp NPN"
                value={corpNpn}
                onChange={(e) => corpChange(e.target.value, "corpNpn")}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Corp Tin"
                value={corpTin}
                onChange={(e) => corpChange(e.target.value, "corpTin")}
                required={isAnthem}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
