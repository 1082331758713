import { Typography, TextField, Stack, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import StepTitle from "./StepTitle";
import { numbersOnlyInput, validEmail } from "../../../services/utils";

const OnboardStepThree = (props) => {
  const { stepThreeValues, setStepThreeValues, setStepThreeComplete, states } =
    props;
  const { firstName, lastName, phone, email, residentState } = stepThreeValues;

  const changeValue = (e, state, stateValue) => {
    const val = stateValue ?? e.target.value;
    setStepThreeValues((rest) => {
      return {
        ...rest,
        [state]: val,
      };
    });
  };

  const invalidEmail = !validEmail(email) && email !== "";
  const invalidPhone = phone.length < 10 && phone !== "";

  const disabled =
    !firstName ||
    !lastName ||
    !residentState ||
    !phone ||
    !email ||
    invalidEmail ||
    invalidPhone;

  useEffect(() => {
    setStepThreeComplete(!disabled);
  }, [disabled]);

  return (
    <>
      <StepTitle
        title="Please provide good contact information in order to complete the onboarding process"
        variant="h6"
      />
      <Typography style={{ color: "#D4AF37" }} variant="caption">
        Please ensure the mobile number you provide is SMS capable
      </Typography>
      <form style={{ minWidth: 300 }}>
        <Stack direction="column" spacing={1} align="center">
          <TextField
            style={{ marginBottom: 10 }}
            value={firstName}
            placeholder="First Name"
            onChange={(e) => changeValue(e, "firstName")}
            type="text"
          />
          <TextField
            style={{ marginBottom: 10 }}
            value={lastName}
            placeholder="Last Name"
            onChange={(e) => changeValue(e, "lastName")}
            type="text"
          />
          <TextField
            style={{ marginBottom: 10 }}
            value={phone}
            placeholder="Mobile Phone"
            onChange={(e) => changeValue(e, "phone")}
            type="phone"
            onInput={(e) => numbersOnlyInput(e, 10)}
            error={invalidPhone}
            helperText={
              invalidPhone ? "Your phone number must be 10 digits long" : ""
            }
          />
          <TextField
            style={{ marginBottom: 10 }}
            value={email}
            placeholder="Email"
            onChange={(e) => changeValue(e, "email")}
            type="email"
            error={invalidEmail}
            helperText={
              invalidEmail
                ? "Email is malformed, please check for accuracy"
                : ""
            }
          />
          <TextField
            value={residentState}
            label="Resident State"
            select
            onChange={(e) => changeValue(e, "residentState")}
            style={{ textAlign: "initial" }}
          >
            {states.map((state, i) => (
              <MenuItem key={`${state.code}-${i}`} value={state.code}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </form>
    </>
  );
};

export default OnboardStepThree;
