import client from "../../services/client";
import { agentContactInfo } from "../../services/GraphQl/queries-new";
import { ContactInfo } from "./types";

export interface IContactRepository {
  agentContactInfo(options: ContactOptions): Promise<ContactInfo[]>;
}

export function ContactRepository() {
  const repository: IContactRepository = {
    agentContactInfo: async (options) => {
      const results = await client.query({
        query: agentContactInfo,
        variables: {
          ...options,
        },
      });
      return results.data.agentContactInfo;
    },
  };
  return repository;
}

export interface ContactOptions {
  agentId: string;
}
