import { Grid, Paper, ButtonBase } from "@mui/material";
import React, { useEffect } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import { ObSubmitTextField } from "./ObSubmitTextField";
import StepTitle from "./StepTitle";
import NIPR from "../../../img/NIPR.png";
const OnboardStepTwo = (props) => {
  const {
    submitLoading,
    setSubmitLoading,
    status,
    setStatus,
    classes,
    setNpnFound,
    npnFound,
    setTitle,
    setSubTitle,
    findAgent,
    setStepTwoComplete,
    stepTwoComplete,
    npn,
    setNpn,
  } = props;
  const successStatus = status === "Success";
  const successClass = clsx({
    [classes.successNotification]: successStatus,
  });

  const npnChange = (e) => {
    setNpn(e.target.value);
  };

  const submitNpn = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    findAgent(npn).then((data) => {
      if (data.data.findNpn) {
        setStatus("Failed");
        setNpnFound(true);
      } else {
        setStatus("Success");
        setStepTwoComplete(true);
        setTitles(true);
      }
      setSubmitLoading(false);
    });
  };

  const npnLookup =  async (e) => {
        e.preventDefault();
        Swal.fire({
                title: 'NPN Lookup',
                icon: 'info',
                text: 'Continuing will open a new browser window on the NPN Lookup web site. To return, close that window, or click back onto this one.',
                showCancelButton: true,
              }).then((result) => {
                if(result.isConfirmed) {
                        window.open('https://nipr.com/help/look-up-your-npn')
                }
              })
  }

  const setTitles = (val) => {
    setTitle(
      val ? "Please provide good contact information in the form below" : ""
    );
    setSubTitle(
      val ? "This information is needed to complete the onboarding process" : ""
    );
  };

  const helperText =
    status === "Failed"
      ? "Matching NPN found"
      : successStatus
      ? "Good to go!"
      : "";

  useEffect(() => {
    if (npn === "") {
      setStatus("");
      setNpnFound(false);
    }
  }, [npn, setStatus, setNpnFound]);

  return (
    <>
      <StepTitle
        title={
          "A National Producer Number (NPN) is required for the onboarding process. Please enter your NPN or click the button below to find it."
        }
        variant="h6"
      />
      <Grid item align="center">
        <ObSubmitTextField
          submitFunc={submitNpn}
          value={npn}
          changeFunc={npnChange}
          successStatus={successStatus}
          successClass={successClass}
          submitLoading={submitLoading}
          status={status}
          helperText={helperText}
          disabled={stepTwoComplete}
        />
      </Grid>
      <Grid item align="center">
        <ButtonBase
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            padding: "8px 14px",
            borderRadius: 5,
            backgroundColor: "#1794ff",
            width: 209,
          }}
          onClick={event => npnLookup(event)}
        >
          <span
            style={{
              borderRadius: "50%",
              backgroundColor: "#eee",
              height: 40,
              width: 40,
              display: "grid",
              placeItems: "center",
            }}
          >
            <img src={NIPR} alt="NIPR Logo" style={{ height: 24, width: 24 }} />
          </span>
          NPN Lookup
        </ButtonBase>
      </Grid>
      {npnFound && (
        <Grid
          item
          align="start"
          container
          justifyContent="center"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Paper style={{ padding: 20, color: "#f2fe01" }} align="center" >
            <>
              The NPN you have provided matches one already on record. Please
              contact your recruiter at the email or phone above to continue the
              onboarding process.
            </>
          </Paper>
        </Grid>
      )}
    </>
  );
};
export default OnboardStepTwo;
