import React, { useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordResetContents = (props) => {
  const {
    username,
    setState,
    password,
    code,
    isVerified,
    theme,
    checkPass,
    verifyPassword,
    mQuery,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const preventMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const showPasswordClick = (e) => {
    setShowPassword(!showPassword);
  };
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} align="center" style={{ margin: 5 }}>
        <Typography variant="caption" style={{ color: "#D4AF37" }}>
          {!isVerified
            ? "In order to reset your password please insert the information below."
            : "Please enter the code and a new password below."}
        </Typography>
      </Grid>
      <Grid item xs={12} align="center" style={{ margin: 5 }}>
        <Typography variant="caption" style={{ color: "#D4AF37" }}>
          {!isVerified
            ? `*Note: You will recieve a code in your email or spam inbox.`
            : `*Note: Passwords must be a length of 8 or greater and have at least one of EACH of the following: Capital letter | Lowercase Letter | Number |
          Special Character`}
        </Typography>
      </Grid>
      <Grid item xs={12} align="center" style={{ margin: 15 }}>
        {isVerified ? (
          <>
            <TextField
              style={{ margin: 5 }}
              variant="outlined"
              label="Verification Code"
              value={code}
              onChange={(e) => setState(e.target.value, "code")}
            />
            <TextField
              fullWidth={mQuery}
              style={{ margin: 5 }}
              variant="outlined"
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              autoComplete="current-password"
              onChange={(e) => setState(e.target.value, "password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={showPasswordClick}
                      onMouseDown={preventMouseDownPassword}
                      edge="end"
                      size="small"
                      style={{ color: "inherit" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="New Password"
              error={checkPass && !verifyPassword()}
              helperText={
                checkPass ? (
                  verifyPassword() ? (
                    <Typography
                      variant="subtitle2"
                      component="span"
                      style={{ color: theme.palette.success.main }}
                    >
                      Valid Password
                    </Typography>
                  ) : (
                    "Invalid Password"
                  )
                ) : (
                  ""
                )
              }
            />
          </>
        ) : (
          <TextField
            style={{ margin: 5 }}
            variant="outlined"
            fullWidth={mQuery}
            label="Email"
            value={username}
            onChange={(e) => setState(e.target.value, "username")}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default PasswordResetContents;
