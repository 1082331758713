import {
  ApolloClient,
  split,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { graphqlPath, subscriptionsPath } from "../api.config";
import { currentJwtToken } from "./amplify/ampClient";
import { GQLWebSocketLink } from "./wslink";

const authLink = new ApolloLink(async (operation, forward) => {
  // get the authentication token from local storage if it exists
  const token = await currentJwtToken();
  // return the headers to the context so httpLink can read them
  operation.setContext({
    headers: {
      authorization: token ? `${token}` : "",
      timezone: Intl.DateTimeFormat("en-us", {
        timeZone: "America/Phoenix",
      }).resolvedOptions().timeZone,
      "bit-default-date-format": "M/d/yyyy hh:mm:ss a",
    },
  });
  return forward(operation);
});

const httpLink = createHttpLink({
  uri: graphqlPath,
});

const subscriptionLink = new GQLWebSocketLink({
  url: subscriptionsPath,
  connectionParams: async () => {
    const token = await currentJwtToken();
    return {
      authorization: token ? `${token}` : "",
      timezone: Intl.DateTimeFormat("en-us", {
        timeZone: "America/Phoenix",
      }).resolvedOptions().timeZone,
      "bit-default-date-format": "M/d/yyyy hh:mm:ss a",
    };
  },
  retryTimeout: 1000,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  subscriptionLink,
  httpLink
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache", // Turn off cache. Needs testing before turning on
    },
    watchQuery: {
      fetchPolicy: "no-cache", // Turn off cache. Needs testing before turning on
    },
  },
  queryDeduplication: false,
});

export default client;
