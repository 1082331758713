import { Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSpring, animated as a } from "react-spring";
import { reduceDate } from "../../../../services/utils";
import RtsCertsDataTable from "./RtsCertsDataTable";
import RtsProductsDataTable from "./RtsProductsDataTable";

const RtsCertsTable = (props) => {
  const { classes, certsData } = props;
  const [planYears, setPlanYears] = useState([]);
  const [filteredData, setFilteredData] = useState(certsData);
  const [selectedRtsCertsId, setSelectedRtsCertsId] = useState("");
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedPlanYear, setSelectedPlanYear] = useState("");
  const [flipped, setFlipped] = useState(false);
  const [page, setPage] = useState(0);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(1200px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  useEffect(() => {
    const pYears = [...new Set(certsData.map(({ planYear }) => planYear))].sort(
      (a, b) => b - a
    );
    setSelectedPlanYear(pYears[0]);
    setPlanYears(pYears);
  }, [certsData]);

  const rows = filteredData
    .filter(({ planYear }) => planYear === selectedPlanYear)
    .map(({ rtsCertsId, isCorporation, name, isCertified, isReady }) => {
      return {
        id: rtsCertsId,
        Carrier: name,
        IsCorp: isCorporation,
        "Is Certified": isCertified,
        RTS: isReady,
      };
    });

  const productRows = certsData
    .filter(({ rtsCertsId }) => rtsCertsId === selectedRtsCertsId)
    .map((data) => {
      return data.products.map((item, i) => {
        const asOfDate = filteredData[0]
          ? new Date(data.recordDate).getFullYear() > selectedPlanYear
            ? "12/31/" + selectedPlanYear
            : reduceDate(data.recordDate)
          : "null";
        return {
          id: i,
          IsCorp: data.isCorporation,
          Certified: item.certified,
          Product: item.product,
          State: item.state,
          AsOf: asOfDate,
          CarrierNote: item.carrierNote
        };
      });
    })
    .flat(1);

  const flipClick = (certsId, carrierName) => {
    setSelectedRtsCertsId(certsId);
    setSelectedCarrier(carrierName);
    setFlipped(!flipped);
  };

  const yearSelect = (e) => {
    setPage(0);
    const targetValue = e.target.value;
    setFilteredData(
      certsData.filter(({ planYear }) => planYear === targetValue)
    );
    setSelectedPlanYear(targetValue);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ paddingBottom: 42, width: "100%" }}
    >
      <Grid item xs={12} sm={4} style={{ padding: 10 }}>
        <TextField
          label="Plan Year"
          name="planYear"
          select
          fullWidth
          size="small"
          value={selectedPlanYear}
          onChange={yearSelect}
          variant="outlined"
          disabled={flipped}
        >
          {planYears.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" style={{ height: 460 }}>
          <a.div
            style={{
              willChange: "transform, opacity",
              opacity: opacity.interpolate((o) => 1 - o),
              transform,
              pointerEvents: !flipped ? "all" : "none",
              position: "absolute",
              height: 460,
              width: 600,
              maxWidth: "100%",
            }}
          >
            <RtsCertsDataTable
              rows={rows}
              classes={classes}
              page={page}
              setPage={setPage}
              flipClick={flipClick}
            />
          </a.div>
          <a.div
            style={{
              willChange: "transform, opacity",
              opacity,
              transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
              pointerEvents: flipped ? "all" : "none",
              position: "absolute",
              height: 460,
              width: 600,
              maxWidth: "100%",
            }}
          >
            <RtsProductsDataTable
              rows={productRows}
              selectedCarrier={selectedCarrier}
              classes={classes}
              flipClick={flipClick}
            />
          </a.div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RtsCertsTable;
