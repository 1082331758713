import React, { useState } from "react";
import {
  Card,
  Grid,
  Collapse,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {
  BusinessRounded,
  Details,
  DetailsTwoTone,
  ExpandMore,
} from "@mui/icons-material";
import CustomDivider from "./CustomDivider.jsx";

export const HierarchyView = (props) => {
  const { clickedAgent, item, classes } = props;
  const [collapse, setCollapse] = useState(true);

  const agentLevels = item.agentHierarchyExternal.find(
    (data) => data.agentId === clickedAgent.id
  );

  const parentLevels = item.agentHierarchyExternal
    .filter((data) => data.degreesFromBerwick < agentLevels.degreesFromBerwick)
    .sort((a, b) => a.degreesFromBerwick - b.degreesFromBerwick);

  const childLevels = item.agentHierarchyExternal
    .filter(
      (data) =>
        data.degreesFromBerwick > agentLevels.degreesFromBerwick &&
        !data.isInactive
    )
    .sort((a, b) => Number(a.isInactive) - Number(b.isInactive));

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <Accordion defaultExpanded={true} style={{ padding: 0, marginBottom: 25 }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.expLabels}>
          <b>Carrier Hierarchies</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="center" className={classes.root}>
          {parentLevels.map(({ agentId, agentName, level }, i) => {
            const isAgent = agentId === clickedAgent.id;
            return (
              <Grid item xs={11} key={`${agentId} - ${i}`}>
                <Card
                  className={classes.hierCardStyles}
                  raised={isAgent}
                  style={{
                    background: isAgent && "#0094ff",
                    color: isAgent && "whitesmoke",
                  }}
                >
                  {agentName} - {level}
                </Card>
                <CustomDivider>
                  <div style={{ padding: 12 }}>
                    {/\bBerwick\b/gi.test(agentName) ? (
                      <BusinessRounded size="small" />
                    ) : (
                      <Details size="small" />
                    )}
                  </div>
                </CustomDivider>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Grid container justifyContent="space-around">
              {[agentLevels].map(({ agentId, agentName, level }, i) => {
                return (
                  <Grid item xs={12} key={`${agentId} - ${i}`}>
                    <Card
                      raised
                      style={{
                        padding: 20,
                        background: "#0094ff",
                        color: "whitesmoke",
                        textAlign: "center",
                        margin: 4,
                      }}
                    >
                      {agentName} - {level}
                    </Card>
                    {i !== agentLevels.length - 1 && agentLevels.length > 1 && (
                      <CustomDivider>
                        <div style={{ padding: 12 }}>
                          <Details size="small" />
                        </div>
                      </CustomDivider>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={11}>
            {childLevels.length > 0 && (
              <CustomDivider>
                <IconButton
                  onClick={() => toggleCollapse()}
                  style={{ color: "#0094ff" }}
                >
                  <DetailsTwoTone
                    style={{
                      transform: collapse && "rotate(180deg)",
                      transition: "transform .5s ease-in-out",
                    }}
                  />
                </IconButton>
              </CustomDivider>
            )}
            <Collapse
              in={collapse}
              className={classes.scrollBar}
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: 200,
              }}
            >
              {childLevels.map(({ agentId, agentName, level }, i) => {
                const isAgent = agentId === clickedAgent.id;
                return (
                  <Card
                    key={`${agentId} - ${i}`}
                    raised={agentId === clickedAgent.id}
                    className={classes.hierCardStyles}
                    style={{
                      background: isAgent ? "#0094ff" : "whitesmoke",
                      color: isAgent ? "whitesmoke" : "black",
                    }}
                  >
                    {agentName} - {level}
                  </Card>
                );
              })}
            </Collapse>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default HierarchyView;
