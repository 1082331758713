import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Tabs,
  Tab,
  IconButton,
  Button,
} from "@mui/material";
import {
  Info,
  DoubleArrow,
  ChevronRight,
  ChevronLeft,
  AccountTreeSharp,
} from "@mui/icons-material";

const BottomProfileBar = (props) => {
  const {
    classes,
    pageValue,
    setPageValue,
    handlePageChange,
    handleOpen,
    disabled,
  } = props;
  return (
    <AppBar position="fixed" color="secondary" className={classes.appBar}>
      <Toolbar disableGutters>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item className={classes.buttonDisplaySmall}>
                <IconButton
                  disabled={pageValue === 0}
                  onClick={() => setPageValue(0)}
                  className={classes.navButtonToolbarLeft}
                >
                  <DoubleArrow />
                </IconButton>
              </Grid>
              <Grid item className={classes.buttonDisplaySmall}>
                <IconButton
                  disabled={pageValue === 0}
                  onClick={() => setPageValue(pageValue - 1)}
                  className={classes.navButtonToolbarRest}
                >
                  <ChevronLeft />
                </IconButton>
              </Grid>
              <Button
                variant="contained"
                disabled={disabled}
                size="small"
                color="primary"
                style={{ marginTop: 4 }}
                onClick={() => handleOpen(true)}
              >
                Request Contract
              </Button>
              <Grid className={classes.buttonDisplaySmall}>
                <IconButton
                  disabled={pageValue === 1}
                  onClick={() => setPageValue(pageValue + 1)}
                  className={classes.navButtonToolbarRest}
                >
                  <ChevronRight />
                </IconButton>
              </Grid>
              <Grid className={classes.buttonDisplaySmall}>
                <IconButton
                  disabled={pageValue === 1}
                  onClick={() => setPageValue(1)}
                  className={classes.navButtonToolbarRest}
                >
                  <DoubleArrow />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Tabs
              onChange={handlePageChange}
              value={pageValue}
              className={classes.tabIndicator}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab
                label={<Typography variant="caption">Info</Typography>}
                icon={<Info />}
              />
              <Tab
                label={
                  <Typography variant="caption">Contract requests</Typography>
                }
                icon={<AccountTreeSharp />}
              />
            </Tabs>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default BottomProfileBar;
