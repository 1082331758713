import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const ContractrequestTable = (props: any) => {
  const { contractRequests } = props;
  const [expanded, setExpanded] = useState<number | boolean>(false);

  const handleChange = (i: number) => {
    setExpanded(expanded === i ? false : i);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        <Typography align="center" sx={{ fontSize: 18, mb: 4 }} gutterBottom>
          Contract Requests
        </Typography>
        {contractRequests?.map((row: any, i: number) => (
          <Accordion
            key={i}
            expanded={expanded === i}
            onChange={() => handleChange(i)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>{row.carrierName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <Typography noWrap>{row.statesRequested}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" noWrap>
                    {row.createDate.split(" ")[0]}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default ContractrequestTable;
