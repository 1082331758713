import React, { useState, useContext, useEffect } from "react";
import { useAuth } from "../useAuth";
import {
  Typography,
  Button,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Toolbar,
  AppBar,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";
import {
  AgentStore,
  ContactStore,
  NotificationsStore,
  RootStoreContext,
} from "../../store";
import { observer } from "mobx-react";
import useStyles from "../../common/useStyles";
import NMA from "../../img/NMA.png";
import outOfState from "../../img/OutOfStateCustomers.png";
import Schedule from "../../img/Schedule_eggshell.png";
import SplashLogo from "../../img/SplashLogo.png";
import NIPR from "../../img/NIPR.png";
import calculateIcon from "../../img/calculateIcon.png";
import IntegrityLogo from "../../img/Integrity_Logo.svg";
import BerwickB from "../../img/BerwickB.png";
import {
  AccountTreeSharp,
  ExitToApp,
  ContentPasteSearch,
  Info,
} from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import UtilityLoading from "../../common/UtilityLoading";
import NotificationDialog from "../../common/NotificationDialog";
import { useHistory } from "react-router-dom";
import "./Home.css";
import LandingPageButtons from "./LandingPageButtons.jsx";
import CalcForm from "../Calculator/CalcForm";
import NotificationButton from "../../common/NotificationButton.jsx";
import PDF from "../../common/CY2024 LIS Resource Limits Memo_508.pdf";
import pdfIcon from "../../img/pdf_icon.svg";

const quoting = [
  {
    label: "MedicareCENTER",
    message: "Your online CRM, quoting, and enrollment tool",
    path: "https://www.medicarecenter.com/welcome",
    icon: IntegrityLogo,
  },
  {
    label: "PDP Penalty Calculator",
    message: "Calculate Part D penalty amount.",
    path: "",
    icon: calculateIcon,
  },
  {
    label: "IRMAA",
    message: "Income-related monthly adjustment amount.",
    path: "https://www.cms.gov/newsroom/fact-sheets/2024-medicare-parts-b-premiums-and-deductibles",
    icon: "IR",
  },
  {
    label: "LIS Income Chart",
    message: "View LIS Income Chart.",
    path: PDF,
    icon: pdfIcon,
  },
];

const additionalResources = [
  {
    label: "Berwick Insurance Agent Portal",
    smallLabel: "Berwick Ins. Agent Portal",
    message:
      "Your Portal for Creating Successful Partnerships One Agent at a Time!",
    path: "https://www.berwickinsurance.com/",
    icon: BerwickB,
  },
  {
    label: "E&O Insurance",
    message: "Berwick Insurance sponsored exclusive E&O coverage!",
    path: " https://www.calsurance.com/berins",
    icon: BerwickB,
  },
  {
    label: "NIPR",
    message: "Go to National Insurance Producer Registry",
    path: "https://nipr.com/",
    icon: NIPR,
  },
  {
    label: "License and CE",
    message: "Insurance, Securities, and Continuing Education Training",
    path: "ExamFX Registration Guide - Berwick Insurance Group.pdf",
    icon: "CE",
  },
];

const mainResources = [
  {
    label: "My DashBoard",
    message:
      "Landing Page for all of your information with us. Takes you to your personal profile.",
    state: 0,
  },
  {
    label: "My Production",
    message: "Current Production from the most recent years available.",
    state: 2,
  },
  {
    label: "Contracts / RTS",
    message:
      "Contracts and Ready To Sell data from the most recent years available.",
    state: 3,
  },
];

const images = [
  {
    src: NMA,
    alt: "NMA",
    link: "https://berwickinsurance.com/medicarecenterinfo",
  },
  {
    src: Schedule,
    alt: "Schedule",
    link: "https://berwickinsurance.com/scheduleameeting",
  },
];

const Home = observer((props) => {
  useAuth();
  let history = useHistory();
  const { authStore } = useContext(RootStoreContext);
  const [agentStore] = useState(() => new AgentStore());
  const [notificationsStore] = useState(() => new NotificationsStore());
  const [contactStore] = useState(() => new ContactStore());
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      agentStore.disposePersist();
      notificationsStore.disposePersist();
      contactStore.disposePersist();
    };
  }, []);

  const classes = useStyles();

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const openDialog = () => {
    setNotificationsOpen(true);
  };

  const closeDialog = () => {
    setNotificationsOpen(false);
  };

  const tabChange = (e, nv) => {
    setTabIndex(nv);
  };
  const handleChangeIndex = (index) => {
    setTabIndex(index);
  };
  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;

  const handleLogout = () => {
    authStore.logout();
  };

  //load/subscribe to notifications
  useEffect(() => {
    if (agentStore.agent && agentStore.agent.id) {
      notificationsStore.load(agentStore.agent.id);
      contactStore.loadContacts(agentStore.agent.id);
    }
  }, [agentStore.agent]);

  const navigate = (state) => {
    history.push({
      pathname: "/Agentprofile",
      state: {
        pageFromHome: state,
      },
    });
  };
  const toggleSeen = (data, status) => {
    notificationsStore.changeNotificationSeen(data, status);
  };
  const changeNotificationStatus = async (notificationsId, status) => {
    notificationsStore.changeNotificationStatus(notificationsId, status);
  };

  const renderNotificationStuff = () => (
    <div style={{ width: 40, marginRight: 10, paddingTop: 10 }}>
      <NotificationButton
        openDialog={openDialog}
        badgeCount={notificationsStore.badgeCount}
      />
      <IconButton
        onClick={() => handleLogout()}
        style={{ color: "#eee" }}
        edge="end"
        size="small"
      >
        <ExitToApp className={classes.notificationIcon} />
      </IconButton>
    </div>
  );

  useEffect(() => {
    setTimeout(() => {
      if (agentStore.agent === null) {
        handleLogout();
      }
    }, 2000);
  }, [agentStore.agent]);

  const resetDemoAgent = (agentId) => {
    authStore.logout();
  };

  if (agentStore.agent === null) {
    return (
      <UtilityLoading loadingState={agentStore.loading}>
        Loading...
      </UtilityLoading>
    );
  }

  return (
    <div className="homePage" style={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar disableGutters>
          <img
            src={SplashLogo}
            alt="SplashLogo"
            height={mQuery ? "50" : "75"}
            width={mQuery ? "50" : "75"}
            photo="true"
            style={{ marginLeft: 10 }}
          />
          <Typography
            display="block"
            justifyContent="true"
            variant={mQuery ? "h5" : "h4"}
            style={{ color: "whitesmoke", width: "100%", textAlign: "center" }}
          >
            {`Hello ${agentStore.agent.displayName}!`}
          </Typography>
          {sessionStorage.getItem("isStaging") === "true" &&
          agentStore.agent.id === "20868930" ? (
            <Button
              size="small"
              onClick={() => resetDemoAgent("20868930")}
              variant="contained"
            >
              Reset Agent
            </Button>
          ) : null}
          {renderNotificationStuff()}
        </Toolbar>
      </AppBar>
      <SwipeableViews
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
        style={{
          paddingTop: mQuery ? 20 : 40,
        }}
        animateHeight
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={7}
            lg={5}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            {mainResources.map(({ label, message, state }, i) => (
              <LandingPageButtons
                key={label}
                navigate={navigate}
                state={state}
                classes={classes}
                label={label}
                setHoveredIndex={setHoveredIndex}
                i={i}
                mQuery={mQuery}
                hoveredIndex={hoveredIndex}
                message={message}
              />
            ))}
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={7}
            lg={5}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            {quoting.map(({ label, path, icon, message }, i) => {
              return (
                <LandingPageButtons
                  key={label}
                  navigate={navigate}
                  classes={classes}
                  label={label}
                  setHoveredIndex={setHoveredIndex}
                  i={i}
                  mQuery={mQuery}
                  hoveredIndex={hoveredIndex}
                  message={message}
                  path={path}
                  icon={icon}
                  openModal={handleClickOpen}
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <Grid container item xs={12} sm={9} md={7} lg={5}>
            {additionalResources.map(
              ({ label, path, message, icon, smallLabel }, i) => {
                return (
                  <LandingPageButtons
                    key={label}
                    navigate={navigate}
                    classes={classes}
                    label={label}
                    setHoveredIndex={setHoveredIndex}
                    i={i}
                    mQuery={mQuery}
                    hoveredIndex={hoveredIndex}
                    message={message}
                    path={path}
                    icon={icon}
                    smallLabel={smallLabel}
                  />
                );
              }
            )}
          </Grid>
        </Grid>
      </SwipeableViews>
      <Grid
        container
        justifyContent="center"
        item
        xs={12}
        style={{
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 70,
        }}
      >
        {images.map(({ src, alt, link }) => (
          <Grid
            key={src}
            item
            xs={mQuery && 6}
            style={{ padding: 5 }}
            align="center"
          >
            <MuiLink target="_blank" rel="noreferrer noopener" href={link}>
              <img
                style={{
                  maxWidth: mQuery ? "100%" : 205,
                  borderRadius: 5,
                  backgroundColor:
                    alt === "Square Cert Incentive" ? "white" : "",
                }}
                width="205"
                src={src}
                alt={alt}
              />
            </MuiLink>
          </Grid>
        ))}
      </Grid>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters>
          <Grid container>
            <Grid item xs={12}>
              <Tabs
                onChange={tabChange}
                value={tabIndex}
                className={classes.tabIndicator}
                variant="fullWidth"
              >
                <Tab
                  label={<Typography variant="caption">Main</Typography>}
                  icon={<Info />}
                  style={{ padding: 0 }}
                />
                <Tab
                  label={<Typography variant="caption">Quoting</Typography>}
                  icon={<ContentPasteSearch />}
                  style={{ padding: 0 }}
                />
                <Tab
                  label={
                    <Typography variant="caption">
                      {mQuery ? "Resources" : "Additional Resources"}
                    </Typography>
                  }
                  icon={<AccountTreeSharp />}
                  style={{ padding: 0 }}
                />
              </Tabs>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <NotificationDialog
        notificationsOpen={notificationsOpen}
        closeDialog={closeDialog}
        mQuery={mQuery}
        notifications={notificationsStore.notifications}
        toggleSeen={toggleSeen}
        changeNotificationStatus={changeNotificationStatus}
      />
      <CalcForm
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </div>
  );
});

export default Home;
