import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  InputLabel,
  FormControl,
  Select,
  CardContent,
  Typography,
  Grid,
  MenuItem,
} from "@mui/material";
import HierarchyView from "./HierarchyView.jsx";
import CarrierHierarchyDetails from "./CarrierHierarchyDetails.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
  },
  formControl: {
    width: "100%",
    marginBottom: "1.5em",
    borderRadius: "5px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "50vw",
      marginBottom: 0,
    },
    width: "100%",
    height: "auto",
    marginBottom: "3em",
  },
  typo: {
    fontWeight: 700,
  },
  typoMargin: {
    marginTop: ".5em",
  },
  expLabel: {
    border: "none",
  },
  cardContent: {
    padding: "5px",
    borderRadius: "5px",
  },
  oaStyle: {
    marginRight: 2,
    fontWeight: 700,
  },
  hierCardStyles: {
    padding: 10,
    margin: 4,
    color: "black !important",
    background: "whitesmoke !important",
    textAlign: "center",
  },
  childHierCard: {
    color: "black",
    textAlign: "center",
  },
  scrollBar: {
    "&::-webkit-scrollbar": { width: "12px" },
    "&::-webkit-scrollbar-track": {
      background: "#073661",
      borderRadius: 30,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "whitesmoke",
      borderRadius: 20,
      border: "2px solid #073661",
    },
  },
  carrierInfoText: {
    fontWeight: 300,
    color: "black",
  },
}));

export default function CarrierHierarchies({ clickedAgent }) {
  const classes = useStyles();
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [filteredCarriers, setFilteredCarriers] = useState([]);

  const carriers =
    clickedAgent.carrierHierarchiesExternal &&
    clickedAgent.carrierHierarchiesExternal.data.sort((a, b) =>
      a.carrierName.localeCompare(b.carrierName)
    );

  useEffect(() => {
    if (selectedCarrier.length > 0) {
      const filteredItems = carriers.filter(
        (data) => data.id === selectedCarrier
      );
      setFilteredCarriers(filteredItems);
    }
    return () => {
      setFilteredCarriers([]);
    };
  }, [selectedCarrier]);

  const handleSelection = (e) => {
    setSelectedCarrier(e.target.value);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography
          className={classes.text}
          variant="h5"
          gutterBottom
          align="center"
          style={{ marginTop: "1em" }}
        >
          <b>Carrier Hierarchies</b>
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="carrier-select">Select a Value</InputLabel>
          <Select
            label="Select a Carrier"
            value={selectedCarrier}
            onChange={handleSelection}
            inputProps={{
              name: "carriers",
              id: "carrier-select",
            }}
          >
            <MenuItem aria-label="None" value="">
              None
            </MenuItem>
            {carriers.map((carrier) => (
              <MenuItem
                style={{ fontSize: "1em" }}
                key={carrier.id}
                name={carrier.carrierName}
                value={carrier.id}
              >
                {carrier.carrierName} - {carrier.agentLevel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        {filteredCarriers &&
          filteredCarriers.map((item) => {
            return (
              <div key={item.id} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <CarrierHierarchyDetails item={item} classes={classes} />
                  <HierarchyView
                    clickedAgent={clickedAgent}
                    item={item}
                    classes={classes}
                  />
                </CardContent>
              </div>
            );
          })}
      </Grid>
    </Grid>
  );
}
