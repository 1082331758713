import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { observer } from "mobx-react";
import { validEmail } from "../../../services/utils";
import StepComplete from "./StepComplete";
import InnerLoading from "../../../common/InnerLoading";

const SignUpStep = observer((props) => {
  const { registerUser, theme, registrationId, authStore } = props;
  const demoAgentCheck =
    sessionStorage.getItem("isStaging") === "true" &&
    registrationId === "20868930";
  const [showPassword, setShowPassword] = useState(false);

  const preventMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const showPasswordClick = (e) => {
    setShowPassword(!showPassword);
  };
  const pass = authStore.options.password;
  const email = authStore.options.username;
  const checkPass = pass.length >= 8;
  const checkEmail = email !== "" && !validEmail(email);
  const verifyPassword = () => {
    return (
      checkPass &&
      /[!@#$%^&*(){}[\]|/\\:;"'<>,.?~`]/.test(pass) &&
      /[a-z]/.test(pass) &&
      /[0-9]/.test(pass) &&
      /[A-Z]/.test(pass)
    );
  };

  const handleEmailChange = (username) => {
    authStore.setOptions({ username });
  };

  const handlePasswordChange = (password) => {
    authStore.setOptions({ password });
  };

  useEffect(() => {
    if (demoAgentCheck) {
      handleEmailChange("demoTest@email.com");
      handlePasswordChange("Berwick123!");
    }
  }, [demoAgentCheck]);

  const buttonDisable =
    pass === "" || email === "" || !verifyPassword() || checkEmail;

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      justifyContent="center"
      style={{ padding: 10 }}
    >
      {authStore.isLoggedIn ? (
        <StepComplete completeMessage="We signed you up! Click the right arrow below to move on." />
      ) : authStore.loading ? (
        <InnerLoading>{"Code incoming! Check your phone!"}</InnerLoading>
      ) : (
        <Grid item xs={12}>
          <form
            onSubmit={() =>
              buttonDisable ? void 0 : registerUser(demoAgentCheck)
            }
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12} align="center" style={{ marginBottom: 20 }}>
                <Typography variant="h6">
                  Enter your email and password to sign up
                </Typography>
                <Typography style={{ color: "#D4AF37" }} variant="caption">
                  *Note: Passwords must be a length of 8 or greater and have at
                  least one of EACH of the following: Capital letter | Lowercase
                  Letter | Number | Special Character
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                style={{ marginBottom: 20 }}
              >
                <Grid item xs={10} sm={3} align="center">
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="email"
                    value={authStore.options.username}
                    autoComplete="email"
                    onChange={(e) => handleEmailChange(e.target.value)}
                    label="Enter Your Email"
                    error={checkEmail}
                    helperText={
                      email === "" ? (
                        ""
                      ) : !checkEmail ? (
                        <Typography
                          variant="subtitle2"
                          component="span"
                          style={{ color: theme.palette.success.main }}
                        >
                          Valid Email
                        </Typography>
                      ) : (
                        "Invalid Email"
                      )
                    }
                    disabled={demoAgentCheck}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                style={{ marginBottom: 20 }}
              >
                <Grid item xs={10} sm={3} align="center">
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={pass}
                    autoComplete="current-password"
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={showPasswordClick}
                            onMouseDown={(e) => preventMouseDownPassword(e)}
                            edge="end"
                            size="small"
                            style={{ color: "inherit" }}
                            disabled={demoAgentCheck}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Enter Password"
                    error={checkPass && !verifyPassword()}
                    helperText={
                      checkPass ? (
                        verifyPassword() ? (
                          <Typography
                            variant="subtitle2"
                            component="span"
                            style={{ color: theme.palette.success.main }}
                          >
                            Valid Password
                          </Typography>
                        ) : (
                          "Invalid Password"
                        )
                      ) : (
                        ""
                      )
                    }
                    disabled={demoAgentCheck}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={buttonDisable}
                >
                  Sign up!
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </Grid>
  );
});

export default SignUpStep;
