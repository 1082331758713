import { CheckCircle } from "@mui/icons-material";
import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const StepComplete = (props) => {
  const { completeMessage } = props;
  const theme = useTheme();
  return (
    <Grid item xs={12} align="center">
      <Typography style={{ marginBottom: 20 }} variant="h4">
        Step Complete
      </Typography>
      <CheckCircle
        fontSize="large"
        style={{ color: theme.palette.success.main, marginBottom: 20 }}
      />
      <Typography variant="subtitle1">{`${completeMessage}`}</Typography>
    </Grid>
  );
};

export default StepComplete;
