import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Grid,
  MobileStepper,
  Typography,
  useMediaQuery,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import {
  getCarrierNameListGql,
  getStates,
  insertOcr,
} from "../../../services/BDSWebService";
import InitialStep from "./InitialStep";
import PreSubmit from "./PreSubmit";
import CommonDialogTitle from "../../../common/CommonDialogTitle";
import Swal from "sweetalert2";

export type Carrier = {
  name: string;
  id: string;
};

const defaultCarrier = {
        name: "",
        id: "",
      };

export type Corp = {
  isAsCorp: boolean;
  corpTin: string;
  corpName: string;
  corpNpn: string;
  isAsIndividual: boolean;
};

const defaultCorp = {
  isAsCorp: false,
  corpTin: "",
  corpName: "",
  corpNpn: "",
  isAsIndividual: false,
};

export default function ContractRequestStepper(props: any) {
  const { open, setOpen, user, setContractRequests } = props;

  const mobile = useMediaQuery("(max-width:600px)");
  const [carrierOptionsArray, setCarrierOptionsArray] = useState<Carrier[]>([]);
  const [selectedCarrier, setSelectedCarrier] = useState<Carrier>(defaultCarrier);
  const [activeStep, setActiveStep] = useState(0);
  const [states, setStates] = useState<Array<object> | any>([]);
  const [licenseNumber, setLicenseNumber] = useState<string>("");
  const [statesRequested, setStatesRequested] = useState<Array<object>>([]);
  const [corp, setCorp] = useState<Corp>(defaultCorp);
  const [notes, setNotes] = useState<string>("");
  const [unlistedCarrier, setUnlistedCarrier] = useState(false);

  useEffect(() => {
    getCarrierNameListGql().then((res) => {
      setCarrierOptionsArray(res.data.getCarrierNameList);           // carrierOptionsArray is the carrier drop list
    });
    getStates().then((res) => setStates(res.data.getStates));       // states is the states drop list
  }, []);

  const currentCarrier =  selectedCarrier;

  const isScan = currentCarrier?.name.includes("SCAN");
  const isAnthem = currentCarrier?.name.includes("Anthem");
  const isWelbe = currentCarrier?.name.includes("WelBe");
  const isFloridBlue = currentCarrier?.name.includes("Florida Blue");

  const otherCarriersWithExtraValidation = isAnthem || isWelbe || isFloridBlue;

  const missingData = isAnthem
    ? !user?.ssn
    : isWelbe
    ? !user?.birthDate
    : isFloridBlue
    ? !user?.gender || !user?.birthDate || !user?.ssn
    : false;

  const additionalFields = {
    ssn: isAnthem || isFloridBlue ? user?.ssn : "",
    dob: isFloridBlue || isWelbe ? user?.birthDate : "",
    gender: isFloridBlue ? user?.gender : "",
    license: isScan ? licenseNumber : "",
    notes: notes,
  };

  const steps = [
    <InitialStep
      setSelectedCarrier={setSelectedCarrier}
      carrierOptionsArray={carrierOptionsArray}
      selectedCarrier={selectedCarrier}
      statesRequested={statesRequested}
      setStatesRequested={setStatesRequested}
      states={states}
      setLicenseNumber={setLicenseNumber}
      licenseNumber={licenseNumber}
      isScan={isScan}
      corp={corp}
      setCorp={setCorp}
      isAnthem={isAnthem}
      mobile={mobile}
      notes={notes}
      setNotes={setNotes}
      setUnlistedCarrier={setUnlistedCarrier}
      unlistedCarrier={unlistedCarrier}
      defaultCarrier={defaultCarrier}
    />,
    <PreSubmit
      additionalFields={additionalFields}
      currentCarrier={currentCarrier}
      statesRequested={statesRequested}
      corp={corp}
    />,
  ];

  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const resetProcess = () => {
    setSelectedCarrier(defaultCarrier);
    setStatesRequested([]);
    setCorp(defaultCorp);
    setLicenseNumber("");
    setActiveStep(0);
    setUnlistedCarrier(false);
    setNotes("");
  };

  const submitRequest = async () => {
    const _statesRequested = `${statesRequested
      .map((row: any) => row.code)
      .join(", ")}`;
    await insertOcr({
      statesRequested: _statesRequested,   //_statesRequested is the array of states joined using the comma delimiter
      // licenseNumber, onboardId, recruiterId are from Onboarding splash page
      // carrierName, carrierId, corp and notes are from InitialStep
      licenseNumber,
      onboardId: user?.onboardId,
      carriernameId: currentCarrier?.id,
      carrierName: currentCarrier?.name,
      recruiterId: user?.recruiterId,
      notes: notes,
      ...corp,
    });
    setContractRequests((cr: any) => [
      {
        carrierName: currentCarrier?.name,
        statesRequested: _statesRequested,
        createDate: new Date(Date.now()).toISOString().split("T")[0],
        status: "Needs Attention",
      },
      ...cr,
    ]);
    setOpen(false);
    resetProcess();
    Swal.fire({
      icon: "success",
      text: "Your contract Request was submitted to your recruiter. Please contact them if you have any questions.",
    });
  };

  const attributes = isAnthem
    ? "SSN"
    : isWelbe
    ? "Birth Date"
    : "Birth Date, Gender, SSN";

  const corpDisableLogic = isAnthem
    ? corp.corpTin !== "" && corp.corpName !== "" && corp.corpNpn !== ""
    : corp.corpName !== "" && corp.corpNpn !== "";

  const  basicallyComplete = 
        selectedCarrier !== undefined &&
        selectedCarrier.name !== "" &&
        statesRequested.length !== 0;

const stepOneComplete = isScan
? licenseNumber !== "" && basicallyComplete
: otherCarriersWithExtraValidation ? !missingData && basicallyComplete
: basicallyComplete;

  return (
    <Dialog open={open} fullWidth fullScreen={mobile ? true : false}>
      <CommonDialogTitle
        title={
          activeStep === 0
            ? "Provide Information Below"
            : "Verify Info and Submit"
        }
        variant="subtitle1"
        close={() => setOpen(!open)}
      />
      <Grid container justifyContent="flex-start" sx={{ p: 1, pb: "0px" }}>
        <Grid item xs={4}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={resetProcess}
          >
            Reset
          </Button>
        </Grid>
        {activeStep === 0 && (
          <Grid item xs={8}>
            <Typography variant="caption" style={{ color: "#D4AF37" }}>
              * Represents Required Fields
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          minHeight: 300,
          flexGrow: 1,
          p: 3,
          pb: "40px",
          pt: "40px",
        }}
        component={Grid}
        container
        justifyContent="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          {steps.map((step: any, i: number) => (
            <div
              style={{ display: i === activeStep ? "block" : "none" }}
              key={i}
            >
              {step}
            </div>
          ))}
          {activeStep === 0 && missingData && (
            <div
              style={{
                fontSize: 14,
                padding: "5px 15px",
                boxShadow: "inset 1px 1px 5px #121212",
                backgroundColor: "#eee",
                color: "#444242",
              }}
            >
              <p>{currentCarrier?.name} Requires the following attributes:</p>
              <p style={{ fontWeight: 900 }}>{attributes}</p>
              <p>
                Please exit this screen and update the missing attributes with
                the Edit button.
              </p>
            </div>
          )}
        </Grid>
      </Box>
      <DialogActions sx={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <MobileStepper
            sx={{ background: "transparent" }}
            steps={maxSteps}
            position={mobile ? "bottom" : "static"}
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                variant="text"
                onClick={() =>
                  activeStep === 1 ? submitRequest() : handleNext()
                }
                disabled={
                  corp.isAsCorp
                    ? activeStep === 0 &&
                      (!stepOneComplete || !corpDisableLogic)
                    : activeStep === 0 && !stepOneComplete
                }
              >
                {activeStep === 1 ? "Submit" : "Next"}
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                variant="text"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
