import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { RootStoreContext } from "../../../store";
import StepTitle from "./StepTitle";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

const OnboardStepFour = observer((props) => {
  const { email, theme, checkPass, verifyPassword } = props;
  const { authStore } = useContext(RootStoreContext);
  const [showPassword, setShowPassword] = useState(false);
  const [stepFourComplete, setStepFourComplete] = useState(false);
  let history = useHistory();

  useEffect(() => {
    return () => {
      setShowPassword(false);
      setStepFourComplete(false);
    };
  }, []);

  useEffect(() => {
    if (email) {
      authStore.setOptions({ username: email.trim() });
    }
  }, [authStore, email]);

  useEffect(() => {
    if (authStore.isLoggedIn) {
      setStepFourComplete(true);
      Swal.fire({
        title: "Onboard Registration Complete",
        icon: "success",
        text: "Onboard Registration is now complete! Please click the button below to enter Agent Way.",
        showCancelButton: false,
        confirmButtonText: "Let's Go!",
      }).then(() => {
        history.push({
          pathname: "/Dashboard",
        });
      });
    }
  }, [authStore, authStore.isLoggedIn, history]);

  const preventMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const passwordChange = (e) => {
    const v = e.target.value.trim();
    authStore.setOptions({ password: v });
  };

  const showPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        <StepTitle
          title="Please set a password for your account"
          variant="h6"
        />
        <StepTitle
          title="*Note: The email and password you provide will be used to log back into your AgentWay account at agentway.io."
          variant="caption"
          style={{ color: "#D4AF37" }}
        />
        <StepTitle
          title="*Note: Passwords must be a length of 8 or greater and have at least
          one of EACH of the following: Capital letter | Lowercase Letter |
          Number | Special Character"
          variant="caption"
          style={{ color: "#D4AF37" }}
        />
      </Grid>
      <Grid container justifyContent="center">
        <form style={{ minWidth: 300 }}>
          <Stack direction="column" spacing={2} align="center">
            <TextField disabled value={email} label="Username" />
            <TextField
              autoFocus
              variant="outlined"
              id="password"
              type={showPassword ? "text" : "password"}
              value={authStore.options.password}
              autoComplete="current-password"
              onChange={(e) => passwordChange(e)}
              color={checkPass && verifyPassword() ? "success" : "primary"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={showPasswordClick}
                      onMouseDown={preventMouseDownPassword}
                      edge="end"
                      size="small"
                      style={{ color: "inherit" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Password"
              error={checkPass && !verifyPassword()}
              helperText={
                checkPass ? (
                  verifyPassword() ? (
                    <Typography
                      variant="subtitle2"
                      component="span"
                      style={{ color: theme.palette.success.main }}
                    >
                      Valid Password
                    </Typography>
                  ) : (
                    "Invalid Password"
                  )
                ) : (
                  "Please enter at least 8 characters"
                )
              }
              disabled={stepFourComplete}
            />
          </Stack>
        </form>
      </Grid>
    </>
  );
});

export default OnboardStepFour;
