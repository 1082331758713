import { makeAutoObservable, runInAction } from "mobx";
import { Agent, AgentToh, EditAgentInfoVariables, RequestedChanges } from ".";
import { AgentRepository, IAgentRepository } from "./repository";
import { makePersistable, stopPersisting } from "mobx-persist-store";

export class AgentStore {
  agent: Agent | null = null;
  agentToh: AgentToh | null = null;
  loading: boolean = false;
  error: Error | null = null;
  _repository: IAgentRepository = AgentRepository();
  clearError(): void {
    this.error = null;
  }
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: "AgentStore",
      properties: ["agent", "agentToh"],
      storage: window.localStorage,
    });
  }

  disposePersist(): void {
    stopPersisting(this);
  }
  async load(id: string): Promise<boolean> {
    this.loading = true;
    this.agent = null;
    try {
      const agent: any = await this._repository.load({
        agentId: id,
      });

      const toh = await this._repository.agentToh(id);
      if (agent) {
        runInAction(() => {
          this.agent = agent;
          this.agentToh = toh;
          this.loading = false;
        });
        return true;
      }
    } catch (error) {
      this.error = error as Error;
      return false;
    }
    this.loading = false;
    return false;
  }
  async updateAgent(info: EditAgentInfoVariables) {
    this.loading = true;
    try {
      const result = await this._repository.updateAgent(info);
      if (this.agent !== null) {
        this.agent = result;
      }
    } catch (error) {
      this.error = error as Error;
    }
    this.loading = false;
  }
  async agentChangeRequest(
    requestedChanges: Partial<RequestedChanges>
  ): Promise<boolean> {
    this.loading = true;
    try {
      const result = await this._repository.agentChangeRequest(
        requestedChanges
      );
      if (result) {
        this.loading = false;
        return result;
      }
    } catch (error) {
      this.error = error as Error;
    }
    this.loading = false;
    return false;
  }
}
