import { AppBar, Avatar, Grid, Toolbar, Typography } from "@mui/material";
import React from "react";
import SplashLogo from "../img/splashLogo.png";
const RegOnbAppBar = (props) => {
  const {
    appBarTitle,
    recruiterName,
    recruiterFontSize,
    children,
    recruiterEmail,
    recruiterPhone,
  } = props;
  return (
    <AppBar>
      <Toolbar disableGutters>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={2} style={{ paddingLeft: 5 }}>
            <Avatar alt="AW" src={SplashLogo} />
          </Grid>
          <Grid item align="center">
            <Typography variant="h4">{appBarTitle}</Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={2}>
            {children}
          </Grid>
          {recruiterName && (
            <Grid item xs={12} align="center">
              <Typography variant="caption" fontSize={recruiterFontSize}>
                <i>With</i> {recruiterName}
              </Typography>
            </Grid>
          )}
          {(recruiterEmail || recruiterPhone) && (
            <Grid item xs={12} justifyContent="center" container>
              <Typography style={{ padding: 4 }} variant="caption" fontSize={recruiterFontSize}>
                {recruiterEmail}
              </Typography>
              <Typography style={{ padding: 4 }} variant="caption" fontSize={recruiterFontSize}>
                {recruiterPhone}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default RegOnbAppBar;
