import React from "react";
import { Typography, Grid, ButtonBase } from "@mui/material";
import { ArrowRightAlt, CallMade, Language } from "@mui/icons-material";
let noImageArray = ["IRMAA", "License and CE"];
const LandingPageButtons = (props) => {
  const {
    navigate,
    state,
    classes,
    label,
    setHoveredIndex,
    i,
    mQuery,
    hoveredIndex,
    message,
    path,
    icon,
    smallLabel,
    openModal,
  } = props;
  return (
    <ButtonBase
      onClick={() => {
        label.includes("Calculator")
          ? openModal()
          : state !== undefined
          ? navigate(state)
          : void 0;
      }}
      href={path ? path : undefined}
      target="_blank"
      rel="noreferrer noopener"
      className={classes.mainButtons}
      focusRipple
      key={label}
      onMouseEnter={() => setHoveredIndex(i)}
      onMouseLeave={() => setHoveredIndex("")}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent={mQuery ? "center" : "flex-start"}
      >
        <Grid
          item
          xs={12}
          style={{
            padding: 15,
            background:
              "linear-gradient(90deg, rgba(0,148,255,.55) 10%, rgba(41,41,70,.55) 100%)",
          }}
          container
          justifyContent="space-between"
        >
          <Grid xs={10} item container align="center" alignItems="center">
            {icon && (
              <Grid
                container
                style={{
                  height: 44,
                  width: 44,
                  borderRadius: "50%",
                  background: "#eee",
                  marginRight: 5,
                }}
                alignItems="center"
                justifyContent="center"
              >
                {!noImageArray.includes(label) ? (
                  <img
                    src={icon}
                    alt="AW"
                    style={{ height: 34, width: 34, borderRadius: 10 }}
                  />
                ) : (
                  <Typography style={{ color: "#186faf" }}>{icon}</Typography>
                )}
              </Grid>
            )}
            <Typography
              variant={mQuery ? "body2" : "h6"}
              style={{ fontSize: mQuery && "1.25em" }}
            >
              {mQuery ? smallLabel ?? label : label}
            </Typography>
          </Grid>
          {icon && !label.includes("Calculator") ? (
            <Grid
              item
              xs={2}
              container
              alignItems="center"
              justifyContent="flex-end"
            >
              <Language
                style={{
                  opacity: mQuery || hoveredIndex === i ? 1 : 0,
                  transition: "opacity .5s",
                  fontSize: "1.75em !important",
                  color: "#eee",
                }}
              />
              <CallMade
                style={{
                  opacity: mQuery || hoveredIndex === i ? 1 : 0,
                  transition: "opacity .5s",
                  fontSize: "1.75em !important",
                  color: "#eee",
                }}
              />
            </Grid>
          ) : (
            <ArrowRightAlt
              style={{
                opacity: mQuery || hoveredIndex === i ? 1 : 0,
                transition: "opacity .5s",
                fontSize: "2em !important",
                color: "#eee",
              }}
            />
          )}
        </Grid>
        <Typography style={{ padding: 30 }} variant="body2">
          {message}
        </Typography>
      </Grid>
    </ButtonBase>
  );
};
export default LandingPageButtons;
