import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import clsx from "clsx";
import { ObSubmitTextField } from "./ObSubmitTextField";
import StepTitle from "./StepTitle";
const OnboardStepOne = (props) => {
  const {
    submitLoading,
    setSubmitLoading,
    status,
    setStatus,
    classes,
    findRecruiter,
    recruiterCode,
    setRecruiterCode,
    setStepThreeValues,
    stepOneComplete,
    setStepOneComplete,
    setRecruiterInfo,
  } = props;
  const successStatus = status === "Success";
  const successClass = clsx({
    [classes.successNotification]: successStatus,
  });

  const recruiterCodeChange = (e) => {
    setRecruiterCode(e.target.value);
  };

  const submitCode = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    findRecruiter(recruiterCode).then((data) => {
      if (data?.data?.findCode) {
        setRecruiterInfo(data?.data?.findCode);
        setStatus("Success");
        setStepOneComplete(true);
        setStepThreeValues((rest) => {
          return { ...rest, recruiterId: data.data.findCode.id };
        });
      } else {
        setStatus("Failed");
      }
      setSubmitLoading(false);
    });
  };

  const helperText =
    status === "Failed"
      ? "Invalid Code, try again"
      : successStatus
      ? "Valid Code, click next below"
      : "";

  useEffect(() => {
    if (recruiterCode === "") {
      setStatus("");
    }
  }, [recruiterCode, setStatus]);

  return (
    <>
      <Grid item xs={12} align="center">
        <StepTitle
          title="Please enter your Onboarding Code below"
          variant="h6"
        />
      </Grid>
      <Grid item xs={12} align="center">
        <ObSubmitTextField
          submitFunc={submitCode}
          value={recruiterCode}
          changeFunc={recruiterCodeChange}
          successStatus={successStatus}
          successClass={successClass}
          submitLoading={submitLoading}
          status={status}
          helperText={helperText}
          disabled={stepOneComplete}
        />
      </Grid>
    </>
  );
};
export default OnboardStepOne;
