import { FormEventHandler } from "react";

export function inputHandler(operation: (value: any) => void) {
  const handler: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    e.preventDefault();
    operation(e.target.value);
  };
  return handler;
}

export function dropDownHandler<T>(operation: (value: T | null) => void) {
  const handler = (event: React.ChangeEvent<{}>, value: T | null) => {
    event.preventDefault();
    operation(value);
  };
  return handler;
}

export function clickHandler(operation: () => void) {
  const handler: FormEventHandler<any> = (event) => {
    event.preventDefault();
    operation();
  };
  return handler;
}

export function radioHandler<T>(operation: (value: T) => void) {
  const handler: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: T
  ) => void = (event, value) => {
    event.preventDefault();
    operation(value);
  };
  return handler;
}
