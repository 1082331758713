import React from "react";
import { Grid, IconButton, Badge } from "@mui/material";
import { NotificationImportant, NotificationsNone } from "@mui/icons-material";
import useStyles from "./useStyles.js";
import { observer } from "mobx-react";

const NotificationButton = observer((props) => {
  const classes = useStyles();
  const { openDialog, badgeCount } = props;
  return (
    <IconButton
      onClick={openDialog}
      style={{ color: "#eee" }}
      edge="end"
      size="small"
    >
      <Badge
        badgeContent={
          badgeCount > 0 && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                background: "#0094ff",
                color: "whitesmoke",
                width: 20,
                height: 20,
                borderRadius: "50%",
              }}
            >
              {badgeCount}
            </Grid>
          )
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {badgeCount > 0 ? (
          <NotificationImportant className={classes.notificationIcon} />
        ) : (
          <NotificationsNone className={classes.notificationIcon} />
        )}
      </Badge>
    </IconButton>
  );
});
export default NotificationButton;
