import React from "react";
import { CheckCircle } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";

const SubmitButton = (props) => {
  const { submitLoading, status, saved, displayText, ...rest } = props;
  return (
    <Button {...rest}>
      {submitLoading ? (
        <CircularProgress
          size={24}
          style={{
            color: "inherit",
          }}
        />
      ) : status === "Success" ? (
        <>
          {saved && <span style={{ marginRight: 5 }}>Saved</span>}
          <CheckCircle />
        </>
      ) : (
        displayText
      )}
    </Button>
  );
};
export default SubmitButton;
