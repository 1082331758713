import { Grid, Typography } from "@mui/material";
import React from "react";

const StepTitle = (props) => {
  const { title, variant, style } = props;
  return (
    <Grid
      item
      style={{
        padding: 10,
        textAlign: "center",
        maxWidth: 700,
      }}
    >
      <Typography variant={variant} style={style}>
        {title}
      </Typography>
    </Grid>
  );
};
export default StepTitle;
