import React from "react";
import AgentProfileToolbar from "./toolbars/AgentProfileToolbar";
import useStyles from "../common/useStyles";
import { observer } from "mobx-react";
import { AppBar } from "@mui/material";

export const SearchAppBar = observer((props) => {
  const classes = useStyles();

  return (
    <div className={classes.searchAppbarRoot}>
      <AppBar position="fixed">
        <AgentProfileToolbar classes={classes} profileProps={props} />
      </AppBar>
    </div>
  );
});

export default SearchAppBar;
