import React, { useEffect, useState } from "react";
import HtmlTooltip from "../components/ui-components/HtmlTooltip";
import {
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  ButtonGroup,
  useTheme,
  Switch,
  Button,
  ListItemText,
  ListItemIcon,
  Zoom,
  Menu,
  MenuItem,
  Grow,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  Archive,
  ExpandLess,
  ExpandMore,
  Unarchive,
  Visibility,
  VisibilityOff,
  HelpOutline,
} from "@mui/icons-material";
import { observer } from "mobx-react";
import { AgentStore } from "../store";
import useStyles from "./useStyles";
import CommonDialogTitle from "./CommonDialogTitle";
import { DateTime } from "luxon";
import { styled } from "@mui/material/styles";
const StatusSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: theme.palette.primary.main,
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.secondary.main,
  },
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NotificationDialog = observer((props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    notificationsOpen,
    closeDialog,
    mQuery,
    notifications,
    toggleSeen,
    changeNotificationStatus,
  } = props;
  const [agentStore] = useState(() => new AgentStore());
  const [currentIndex, setCurrentIndex] = useState(null);
  const [page, setPage] = useState(0);
  const [archiveAlert, setArchiveAlert] = useState(false);
  const [toggleArchived, setToggleArchived] = useState(false);
  const [notificationType, setNotificationType] = useState(false);
  const [dataMap, setDataMap] = useState([]);
  const [messageForType, setMessageForType] = useState("");

  useEffect(() => {
    if (notifications) {
      const notificationsByType = !notificationType
        ? notifications.filter((data) => {
            const agentIds = data.agentIds.find(
              ({ agentId, status }) =>
                agentId === agentStore.agent?.id && status === false
            );
            return agentIds?.status === false;
          })
        : notifications.filter((data) => {
            const agentIds = data.agentIds.find(
              ({ agentId, status }) =>
                agentId === agentStore.agent.id && status === true
            );
            return agentIds?.status === true;
          });
      setDataMap(
        notificationsByType.sort(
          (a, b) => new Date(b.createDate) - new Date(a.createDate)
        )
      );
      setCurrentIndex(null);
    }
  }, [notifications, notificationType]);
  const rowCount = mQuery ? 3 : 5;
  const slicedMap = dataMap?.slice(page * rowCount, page * rowCount + rowCount);
  const totalPages = Math.ceil(dataMap?.length / rowCount);
  const _changeNotificationStatus = async (notificationsId, status) => {
    await changeNotificationStatus(notificationsId, status);

    const message = !notificationType
      ? "Notification Archived"
      : "Notification Restored";
    setMessageForType(message);
    setToggleArchived(true);
    setArchiveAlert(true);
  };

  const archivedAlertClose = () => {
    setArchiveAlert(false);
  };

  const notificationTypeChange = (e) => {
    setNotificationType(e.target.checked);
  };

  useEffect(() => {
    if (archiveAlert) {
      setTimeout(() => {
        setArchiveAlert(false);
      }, 5000);
    }
  }, [archiveAlert]);

  const button = (status, title) => {
    return (
      <Button
        style={{
          borderRadius: 30,
          color: "#eee",
        }}
        fullWidth
        size="small"
        onClick={() => setNotificationType(status)}
      >
        {title}
      </Button>
    );
  };

  const [anchorHelpMenuEl, setAnchorHelpMenuEl] = useState(null);

  const handleHelpMenuClick = (event) => {
    setAnchorHelpMenuEl(event.currentTarget);
  };

  const handleHelpMenuClose = () => {
    setAnchorHelpMenuEl(null);
  };

  const clearStates = () => {
    setNotificationType(false);
    setToggleArchived(false);
    setCurrentIndex(null);
    setMessageForType("");
    setPage(0);
  };

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, []);

  useEffect(() => {
    if (slicedMap.length === 0) {
      setTimeout(() => {
        setPage(0);
      }, 500);
    }
  }, [slicedMap]);

  useEffect(() => {
    if (notificationsOpen && notifications) {
      const foundUnseenIds = notifications
        .filter((data) => {
          const agentIds = data.agentIds.find(
            ({ agentId }) => agentId === agentStore.agent.id
          );
          return agentIds?.status === false && agentIds?.seen === false;
        })
        .map(({ id }) => id);
      if (foundUnseenIds.length > 0) {
        toggleSeen(foundUnseenIds, true);
      }
    }
  }, [notificationsOpen]);

  useEffect(() => {
    setMessageForType("");
    setArchiveAlert(false);
    setPage(0);
  }, [notificationType]);

  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={mQuery}
      open={notificationsOpen}
      onClose={(e, reason) => reason !== "backdropClick" && closeDialog()}
    >
      <CommonDialogTitle title="Notifications" close={closeDialog} />
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        style={{ padding: 10, flexBasis: "unset", position: "relative" }}
      >
        <Grid
          item
          style={{
            background: `linear-gradient(to right, rgba(216,216,216, .10) 50%, ${primaryColor} 50%)`,
            backgroundSize: " 200% 100%",
            backgroundPosition: `${
              !notificationType ? "right bottom" : "left bottom"
            }`,
            transition: "all .25s ease-in-out",
            width: 100,
            textAlign: "center",
            borderRadius: 30,
          }}
        >
          {button(false, "Inbox")}
        </Grid>
        <StatusSwitch
          checked={notificationType}
          onChange={notificationTypeChange}
        />
        <Grid
          item
          style={{
            width: 100,
            borderRadius: 30,
            textAlign: "center",
            background: `linear-gradient(to left, rgba(216,216,216, .10) 50%, ${secondaryColor} 50%)`,
            backgroundSize: " 200% 100%",
            backgroundPosition: `${
              notificationType ? "left bottom" : "right bottom"
            }`,
            transition: "all .25s ease-in-out",
          }}
        >
          {button(true, "Archived")}
          <div style={{ position: "absolute", top: 5, right: 5 }}>
            <IconButton
              variant="contained"
              onClick={handleHelpMenuClick}
              edge={mQuery ? "end" : null}
            >
              <HelpOutline />
            </IconButton>
            <Menu
              anchorEl={anchorHelpMenuEl}
              keepMounted
              open={Boolean(anchorHelpMenuEl)}
              onClose={handleHelpMenuClose}
              TransitionComponent={Grow}
            >
              <MenuItem style={{ pointerEvents: "none" }}>
                <ListItemIcon style={{ color: "#eee" }}>
                  <Visibility fontSize="small" />/
                  <VisibilityOff fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Seen / Unseen" />
              </MenuItem>
              <MenuItem style={{ pointerEvents: "none" }}>
                <ListItemIcon style={{ color: "#eee" }}>
                  <Archive className={classes.secondaryNotification} />/
                  <Unarchive className={classes.successNotification} />
                </ListItemIcon>
                <ListItemText primary="Archive / Unarchive" />
              </MenuItem>
              <MenuItem style={{ pointerEvents: "none" }}>
                <ListItemIcon
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color: `${theme.palette.primary.main} !important`,
                    },
                  }}
                  style={{ color: "#eee" }}
                >
                  <ExpandMore color="primary" />/<ExpandLess color="primary" />
                </ListItemIcon>
                <ListItemText primary="Expand / Collapse" />
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>
      <DialogContent
        style={{ padding: 0, minHeight: 400, position: "relative" }}
        className={classes.scrollBar}
      >
        <Collapse
          in={archiveAlert}
          style={{
            width: "100%",
            background: toggleArchived
              ? theme.palette.success.main
              : secondaryColor,
            bottom: 0,
            position: "absolute",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={1} />
            <Grid item xs={10} align="center">
              {messageForType}
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={archivedAlertClose}>
                <Close size="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Collapse>
        <Grid
          style={{
            padding: !mQuery ? "40px 40px 40px" : 0,
            height: mQuery ? "100%" : "600px",
          }}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={1}
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            <IconButton
              disabled={page === 0}
              onClick={() => {
                setCurrentIndex(null);
                setPage(page - 1);
              }}
              color="primary"
            >
              <ArrowBackIos />
            </IconButton>
          </Grid>
          <Grid item xs={9}>
            {slicedMap?.map(
              ({ id, subject, body, agencyInfo, createDate, agentIds }, i) => {
                const seen = agentIds[0].seen;
                const zone = DateTime.now().zoneName;
                const convertDate = new Date(createDate);
                return (
                  <Card
                    key={id}
                    style={{
                      marginBottom: 10,
                      marginTop: 10,
                      border: "solid .25px grey",
                      borderLeft: `solid 6px ${
                        !notificationType ? primaryColor : secondaryColor
                      }`,
                      backgroundColor: "#242323",
                    }}
                    elevation={0}
                  >
                    <CardHeader
                      title={
                        <Grid container direction="column">
                          <Typography variant="body1">
                            {agencyInfo?.agencyName}
                          </Typography>
                          <Typography variant="caption">
                            {convertDate.toLocaleString("en-US", {
                              timeZone: zone,
                            })}
                          </Typography>
                        </Grid>
                      }
                      action={
                        mQuery ? (
                          <div>
                            <ButtonGroup size="small" orientation="vertical">
                              {!notificationType && (
                                <HtmlTooltip
                                  title={seen ? "Seen" : "Unseen"}
                                  enterDelay={750}
                                  TransitionComponent={Zoom}
                                >
                                  <Button
                                    variant="text"
                                    onClick={() => toggleSeen([id], !seen)}
                                    style={{ color: "#eee" }}
                                  >
                                    {seen ? <Visibility /> : <VisibilityOff />}
                                  </Button>
                                </HtmlTooltip>
                              )}
                              <HtmlTooltip
                                title={
                                  notificationType ? "Unarchive" : "Archive"
                                }
                                TransitionComponent={Zoom}
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    _changeNotificationStatus(
                                      id,
                                      notificationType ? false : true
                                    )
                                  }
                                  className={
                                    notificationType
                                      ? classes.successNotification
                                      : classes.secondaryNotification
                                  }
                                >
                                  {notificationType ? (
                                    <Unarchive />
                                  ) : (
                                    <Archive />
                                  )}
                                </Button>
                              </HtmlTooltip>
                              <HtmlTooltip
                                title={
                                  currentIndex === id ? "Collapse" : "Expand"
                                }
                                enterDelay={750}
                                TransitionComponent={Zoom}
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    currentIndex === id
                                      ? setCurrentIndex(null)
                                      : setCurrentIndex(id)
                                  }
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      color: `${theme.palette.primary.main} !important`,
                                    },
                                  }}
                                >
                                  {currentIndex === id ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </Button>
                              </HtmlTooltip>
                            </ButtonGroup>
                          </div>
                        ) : (
                          <div className={classes.sectionDesktop}>
                            <ButtonGroup size="small">
                              {!notificationType && (
                                <HtmlTooltip
                                  title={seen ? "Seen" : "Unseen"}
                                  enterDelay={750}
                                  TransitionComponent={Zoom}
                                >
                                  <Button
                                    variant="text"
                                    onClick={() => toggleSeen([id], !seen)}
                                    style={{ color: "#eee" }}
                                  >
                                    {seen ? <Visibility /> : <VisibilityOff />}
                                  </Button>
                                </HtmlTooltip>
                              )}
                              <HtmlTooltip
                                title={
                                  notificationType ? "Unarchive" : "Archive"
                                }
                                enterDelay={750}
                                TransitionComponent={Zoom}
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    _changeNotificationStatus(
                                      id,
                                      notificationType ? false : true
                                    )
                                  }
                                  className={
                                    notificationType
                                      ? classes.successNotification
                                      : classes.secondaryNotification
                                  }
                                >
                                  {notificationType ? (
                                    <Unarchive />
                                  ) : (
                                    <Archive />
                                  )}
                                </Button>
                              </HtmlTooltip>
                              <HtmlTooltip
                                title={
                                  currentIndex === id ? "Collapse" : "Expand"
                                }
                                enterDelay={750}
                                TransitionComponent={Zoom}
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    currentIndex === id
                                      ? setCurrentIndex(null)
                                      : setCurrentIndex(id)
                                  }
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      color: `${theme.palette.primary.main} !important`,
                                    },
                                  }}
                                >
                                  {currentIndex === id ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </Button>
                              </HtmlTooltip>
                            </ButtonGroup>
                          </div>
                        )
                      }
                      subheader={
                        <Typography
                          variant="subtitle2"
                          className={classes.textOverflow}
                          style={{
                            color: "lightgrey",
                          }}
                        >
                          {subject}
                        </Typography>
                      }
                      style={{ padding: 10 }}
                    />
                    <Collapse in={currentIndex === id}>
                      <CardContent
                        sx={{
                          background: "whitesmoke",
                          color: "black",
                          textAlign: "center",
                        }}
                        style={{ padding: 0 }}
                      >
                        <Typography
                          sx={{
                            color: "#333",
                            fontWeight: 600,
                            background: "#ddd",
                          }}
                          style={{ padding: 5 }}
                          variant="subtitle1"
                        >
                          {subject}
                        </Typography>
                        <Typography
                          style={{ padding: 5, color: "grey" }}
                          variant="subtitle2"
                        >
                          {body}
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                );
              }
            )}
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              disabled={page === totalPages - 1}
              onClick={() => {
                setCurrentIndex(null);
                setPage(page + 1);
              }}
              color="primary"
              align="end"
            >
              <ArrowForwardIos />
            </IconButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});
export default NotificationDialog;
