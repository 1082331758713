import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Dialog,
} from "@mui/material";
import useStyles from "../../../../common/useStyles";
import CommonDialogTitle from "../../../../common/CommonDialogTitle";
import ChangeRequestContent from "./ChangeRequestContent";
import Banner from "../../../../common/Banner";

const ChangeRequest = (props) => {
  const {
    submitChangeRequest,
    formChanges,
    disabled,
    closeDialog,
    loading,
    changeRequestFormValues,
    mQuery,
    changeRequestOpen,
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={(e, reason) => reason !== "backdropClick" && closeDialog("cr")}
      fullScreen={mQuery}
      open={changeRequestOpen}
    >
      <CommonDialogTitle
        title="Change Request"
        close={closeDialog}
        variable="cr"
      />
      <div>
        <Banner content="Information is updated with Berwick and is NOT sent to carriers" />
      </div>
      <DialogContent>
        <ChangeRequestContent
          submitChangeRequest={submitChangeRequest}
          formChanges={formChanges}
          changeRequestFormValues={changeRequestFormValues}
          classes={classes}
        />
      </DialogContent>
      <DialogActions style={{ width: "100%" }}>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => closeDialog("cr")}
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={submitChangeRequest}
              color="primary"
              size="small"
              disabled={disabled}
            >
              {!loading ? "Submit" : "...Loading"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeRequest;
