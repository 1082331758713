import { Amplify, Auth } from "aws-amplify";
import Swal from "sweetalert2";
import { adminConfig, defaultAmpConfig } from "../../api.config";

Amplify.configure(defaultAmpConfig);

const swapConfigOptions = (options) => {
  Auth.configure(options);
};

export const userAuthExists = async () => {
  const authorized = await Auth.currentAuthenticatedUser()
    .then(() => true)
    .catch(() => false);
  return authorized;
};

export const authInfo = async () => {
  const { attributes } = await Auth.currentAuthenticatedUser();
  return attributes;
};

export const currentSession = async () => {
  return await Auth.currentSession().then((data) => data);
};

export const currentAuthenticatedUser = async () => {
  return await Auth.currentAuthenticatedUser().then((data) => data);
};

export const currentJwtToken = async () => {
  return await Auth.currentSession()
    .then((data) => {
      return data.getAccessToken().getJwtToken();
    })
    .catch((err) => {
      if (err === "No current user") {
        return "";
      }
      throw err;
    });
};

export const signUp = async (username, password, phone, id, signUpType) => {
  await Auth.signUp({
    username,
    password,
    attributes: { email: username, phone_number: phone },
    clientMetadata: { id, signUpType },
  });
};

export const signIn = async (username, password) => {
  try {
    return await Auth.signIn(username, password);
  } catch (error) {
    swapConfigOptions({ ...defaultAmpConfig });
    return error;
  }
};

export const signOut = () => {
  swapConfigOptions({ ...defaultAmpConfig });
  return Auth.signOut();
};

export const confirmSignIn = async (results, code) => {
  await Auth.confirmSignIn(results, code);
};

export const ampForgotPassword = async (username) => {
  await Auth.forgotPassword(username);
};

export const forgotPasswordSubmit = async (options) => {
  const { username, code, password } = options;
  await Auth.forgotPasswordSubmit(username, code, password);
};

export const deleteUser = () => {
  Auth.deleteUser();
};

export const updateUserAttributes = async (attributes) => {
  const user = await currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, attributes);
};

const mfaChallenge = async (results, defaultFlow, username, password) => {
  let newResults = results;
  return await new Promise((resolve, reject) => {
    Swal.fire({
      title: "Code Entry",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      html: `
        <div id="codeContainer">
        <span style="color: white;">Please enter the code we sent to your number</span>
        <br /> <br />
        <span style="font-size: 12px; color: rgb(212, 175, 55);">If you do not receive your MFA code within one minute, click Resend to receive a new code</span>
        <br /> <br />
        <input id="code" class="swalHtmlInput"/>
        <br />
        <span id="invalidCode" style="font-size: 13px; color: #fc5b5b;"></span>
        <br /> <br />
        <button type="button" id="resend" class="resendCodeButton">Resend</button>
        <button style="cursor: pointer;" type="button" id="confirm" class="swal2-styled swal2-confirm" disabled="true">Submit</button> 
        </div>`,
      didOpen: () => {
        const confirm = document.getElementById("confirm");
        const resend = document.getElementById("resend");
        const input = document.getElementById("code");

        confirm.addEventListener("click", async () => {
          const input = document.getElementById("code");
          const inputVal = input.value;
          confirm.innerHTML = `
              <div id="innerButtonLoader" style="width: 100%; display: flex; justify-content: center;">
              <div class="htmlCodeInnerButtonLoader" />
              </div>`;
          try {
            swapConfigOptions({
              authenticationFlowType: newResults.challengeName,
            });
            await confirmSignIn(newResults, inputVal);
            confirm.innerHTML = "Submit";
            Swal.close();
            resolve(true);
          } catch (error) {
            confirm.innerHTML = "Submit";
            const invalidCode = document.getElementById("invalidCode");
            const err = Error(error);
            invalidCode.textContent = err.message;
          }
        });

        resend.addEventListener("click", async () => {
          try {
            swapConfigOptions({ authenticationFlowType: defaultFlow });
            resend.style.backgroundColor = "#054d80";
            resend.innerHTML = `
              <div id="innerButtonLoader" style="width: 100%; display: flex; justify-content: center;">
              <div class="htmlCodeInnerButtonLoader" />
              </div>`;
            const res = await signIn(username, password);
            newResults = res;
            resend.style.backgroundColor = "green";
            resend.innerHTML = `Send Again?`;
          } catch (error) {
            resend.innerHTML = "Resend";
            const invalidCode = document.getElementById("invalidCode");
            const err = Error(error);
            invalidCode.textContent = err.message;
          }
        });

        input.addEventListener("input", (e) => {
          const confirm = document.getElementById("confirm");
          const invalidCode = document.getElementById("invalidCode");
          const isDisabled = e.target.value.length > 0;
          invalidCode.textContent = "";
          if (!isDisabled) {
            confirm.setAttribute("disabled", "true");
          } else {
            confirm.removeAttribute("disabled");
          }
        });
      },
    });
  });
};

export const signInFlow = async (username, password, isBerwick) => {
  const defaultFlow = defaultAmpConfig.authenticationFlowType;

  //Admin - bds pool
  if (isBerwick) {
    swapConfigOptions({ ...adminConfig });
    const results = await signIn(username, password);
    // Sign in failed show error message
    if (results instanceof Error) {
      throw new Error(results.message);
    }

    return {
      id: results.attributes["custom:adminId"],
      loginType: "admin",
    };
  }

  // Sign in and migrate
  const results = await signIn(username, password);

  // Sign in failed show error message
  if (results instanceof Error) {
    throw new Error(results.message);
  }

  // If device not remembered - mfa flow
  if (results.challengeName === "SMS_MFA") {
    const codeResult = await mfaChallenge(
      results,
      defaultFlow,
      username,
      password
    );
    if (codeResult instanceof Error) {
      throw new Error(codeResult.message);
    }
  }
  // Reset flow and return user attributes
  swapConfigOptions({ authenticationFlowType: defaultFlow });
  const session = await currentSession();
  const _authInfo = await authInfo();
  const loginType = Object.keys(session.getAccessToken().payload).includes(
    "cognito:groups"
  )
    ? session.getAccessToken().payload["cognito:groups"][0].toLowerCase()
    : "agents";
  return {
    id: _authInfo["custom:Id"] ?? _authInfo["custom:agentId"],
    loginType,
  };
};

export const newUserSignInFlow = async (
  username,
  password,
  id,
  signUpType,
  phone
) => {
  const defaultFlow = defaultAmpConfig.authenticationFlowType;

  // Get phone and create user
  const signUpResult = await new Promise((resolve, reject) =>
    Swal.fire({
      title: "Multi-Factor-Authentication",
      text: `In order to proceed, you must agree to receive SMS text messages for the purposes of Multi-Factor-Authentication. 
        You will periodically receive an SMS message on login attempt.`,
      showCancelButton: true,
      confirmButtonText: "I agree",
      allowOutsideClick: false,
      reverseButtons: true,
      input: "tel",
      inputPlaceholder: "Example 5201112222",
      inputValue: phone,
      inputValidator: (value) => {
        const isNumbers = Number(value);
        if (isNaN(isNumbers)) {
          return "Invalid characters. You must only enter numbers.";
        }
        if (value.length !== 10) {
          return "Invalid length. Your phone number must be 10 digits long.";
        }
      },
    }).then(async (result) => {
      if (result.isDismissed) {
        reject(
          new Error(
            signUpType === "agents"
              ? "You must enter a number to proceed"
              : "Please contact your recruiter with any questions."
          )
        );
      } else if (result.isConfirmed) {
        try {
          await signUp(username, password, `+1${result.value}`, id, signUpType);
          resolve(true);
        } catch (error) {
          swapConfigOptions({ authenticationFlowType: defaultFlow });
          reject(error);
        }
      } else {
        swapConfigOptions({ authenticationFlowType: defaultFlow });
        reject(new Error("Phone entry process failed - please try again"));
      }
    })
  );
  if (signUpResult instanceof Error) {
    throw new Error(signUpResult.message);
  }

  // Sign in and migrate
  const results = await signIn(username, password);

  // Sign in failed show error message
  if (results instanceof Error) {
    throw new Error(results.message);
  }

  // If device not remembered - mfa flow
  if (results.challengeName === "SMS_MFA") {
    const codeResult = await mfaChallenge(
      results,
      defaultFlow,
      username,
      password
    );
    if (codeResult instanceof Error) {
      throw new Error(codeResult.message);
    }
  }
  // Reset flow and return user attributes
  swapConfigOptions({ authenticationFlowType: defaultFlow });
  const session = await currentSession();
  const _authInfo = await authInfo();
  const loginType = session
    .getAccessToken()
    .payload["cognito:groups"][0].toLowerCase();
  return {
    id: _authInfo["custom:Id"],
    loginType,
  };
};
