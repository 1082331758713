import React, { useState } from "react";
import Table from "@mui/material/Table";
import {
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Done, Clear } from "@mui/icons-material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import Banner from "../../../../common/Banner";

const RtsCertsDataTable = (props) => {
  const { flipClick, rows, classes, page, setPage } = props;
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;

  const Attention = () => (
    <Typography
      style={{
        color: "#D4AF37",
      }}
      variant="caption"
    >
      Click rows to expand
    </Typography>
  );

  const CertsToolBar = () => {
    return (
      <Grid container alignItems="center">
        <Banner content="Contact carrier for the most up to date Cert and RTS information" />
        <Grid align="center" item xs={12} style={{ padding: 2 }}>
          <Attention />
        </Grid>
      </Grid>
    );
  };

  const RtsHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: "bolder" }} align="left">
            Carrier
          </TableCell>
          <TableCell style={{ fontWeight: "bolder" }}>Corp</TableCell>
          <TableCell style={{ fontWeight: "bolder" }}>Is Cert</TableCell>
          <TableCell style={{ fontWeight: "bolder" }}>RTS</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const RtsBody = () => {
    return (
      <TableBody>
        {rows &&
          rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, i) => (
              <TableRow
                key={`${i}${data.id}`}
                onClick={() => flipClick(data.id, data.Carrier)}
                hover
                style={{ cursor: "pointer" }}
              >
                <TableCell
                  style={{ padding: mQuery && 8 }}
                  component="th"
                  scope="row"
                >
                  {data.Carrier}
                </TableCell>
                <TableCell style={{ padding: mQuery && 8 }}>
                  {data.IsCorp ? <Done /> : <Clear />}
                </TableCell>
                <TableCell style={{ padding: mQuery && 8 }}>
                  {data["Is Certified"] ? <Done /> : <Clear />}
                </TableCell>
                <TableCell style={{ padding: mQuery && 8 }}>
                  {data.RTS ? <Done /> : <Clear />}
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
    );
  };

  return (
    <Grid
      style={{
        paddingBottom: 42,
      }}
    >
      <Paper style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <Table>
          {RtsHeader()}
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan="12">
                {CertsToolBar()}
              </TableCell>
            </TableRow>
          </TableBody>
          {RtsBody()}
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.muiTablePaginationToolbar}
                rowsPerPageOptions={[5, 10, 25]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Grid>
  );
};

export default RtsCertsDataTable;
