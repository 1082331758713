import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
const CustomDatePicker = (props) => {
  const { value, onChange, name, label, variant, customClass, error } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={(date) => onChange(null, date, name)}
        inputProps={{
          placeholder: !variant && !value ? `No ${label} On File` : "",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            variant={variant}
            className={customClass}
            format="MM/DD/YYYY"
            style={{ margin: 0 }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
export default CustomDatePicker;
