import { AgentOutput, AwayNotifications } from ".";
import client from "../../services/client";
import {
  awayNotifications,
  changeNotificationStatus,
  notificationAdded,
  changeNotificationSeen,
} from "../../services/GraphQl/queries-new";
import { toast } from "react-toastify";
import { SubscribeOptions } from "../agent";
import { ApolloQueryResult, ObservableQuery } from "@apollo/client";
export interface INotificationsRepository {
  load(options: LoadNotificationsOptions): Promise<ObservableQuery<any, any>>;
  changeNotificationStatus(options: {
    notificationsId: number;
    status: boolean;
  }): Promise<ApolloQueryResult<AwayNotifications>>;
  changeNotificationSeen(options: {
    notificationsId: number[];
    status: boolean;
  }): Promise<ApolloQueryResult<AwayNotifications[]>>;
  subscribe(
    options: SubscribeOptions,
    callback: NotificationsSubscriptionCallback
  ): NotificationsCancelSubscriptionCall;
  unsubscribe: () => void;
}
const defaultPagingOptions = {
  first: 10000,
  orderBy: {
    field: "lastModifiedDate",
    sort: "desc",
  },
};
let subscription: any = undefined;
export function NotificationsRepository(): INotificationsRepository {
  return {
    load: async (options) => {
      return client.watchQuery({
        query: awayNotifications,
        variables: {
          filters: options.filters,
          paging: options.paging ?? defaultPagingOptions,
        },
      });
    },
    changeNotificationStatus: async (options) => {
      const result = await client.query({
        query: changeNotificationStatus,
        variables: {
          notificationsId: options.notificationsId,
          status: options.status,
        },
      });
      return result.data.changeNotificationStatus;
    },
    changeNotificationSeen: async (options) => {
      const results = await client.query({
        query: changeNotificationSeen,
        variables: {
          notificationsId: options.notificationsId,
          status: options.status,
        },
      });
      return results.data.changeNotificationSeen;
    },
    subscribe: (options, callback) => {
      if (subscription) {
        subscription.unsubscribe();
      }
      subscription = client
        .subscribe({
          query: notificationAdded,
          variables: { agentId: options.agentId },
        })
        .subscribe({
          next: ({ data }: any) => {
            if (
              data?.notificationAdded?.agentIds?.some(
                (data: AgentOutput) => data.agentId === options.agentId
              )
            ) {
              callback(data.notificationAdded);
              toast.success(data.notificationAdded.subject);
            }
          },
        });
      return () => {};
    },
    unsubscribe: () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    },
  };
}

export interface LoadNotificationsOptions {
  filters: FilterInput[];
  paging?: PagingInput;
}

export type FilterInput = {
  field: string;
  comparison: string;
  value: string;
};

export type PagingInput = {
  first: number;
  page: number;
  orderBy: OrderByInput;
};

export type OrderByInput = {
  field: string;
  sort: string;
};

export type NotificationsSubscriptionCallback = (
  notifications: AwayNotifications
) => void;
export type NotificationsCancelSubscriptionCall = () => void;
