import { CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";

const InnerLoading = (props) => {
  const { children } = props;
  return (
    <Grid
      style={{ height: "65vh" }}
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress style={{ marginRight: 10 }} />
      <Grid item>
        <Typography>{children}</Typography>
      </Grid>
    </Grid>
  );
};
export default InnerLoading;
