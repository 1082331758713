import React from "react";
import { Grid, DialogTitle, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const CommonDialogTitle = (props) => {
  const { title, close, variable, variant } = props;

  return (
    <DialogTitle style={{ background: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={2} />
        <Grid item xs={8} align="center">
          <Typography variant={variant ?? "h5"} style={{ color: "black" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2} align="end">
          <IconButton onClick={() => close(variable)}>
            <Close style={{ color: "black" }} />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default CommonDialogTitle;
