import React from "react";
import {
  Button,
  Grid,
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

const AlertModal = (props) => {
  const { openAlert, closeAlert, alertMessage } = props;
  return (
    <Dialog
      disableEscapeKeyDown
      open={openAlert}
      onClose={(e, reason) => reason !== "backdropClick" && closeAlert()}
      fullWidth
    >
      <Grid
        style={{ padding: 20 }}
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReportProblemOutlinedIcon
          style={{
            fontSize: "7rem",
            color: "#f7c357",
          }}
        />
        <DialogTitle>
          <Typography variant="h6">Form Contains Errors</Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: 30 }}>
          <Grid container direction="column" alignItems="center">
            <Grid item>{`${alertMessage}`}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            margin: 10,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={closeAlert}
          >
            OK
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default AlertModal;
