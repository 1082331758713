import React from "react";
import Table from "@mui/material/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";

const Attention = () => (
  <Typography
    variant="caption"
    style={{
      textAlign: "center",
      color: "#D4AF37",
    }}
  >
    Click rows to expand
  </Typography>
);

const ProductionDataTable = (props) => {
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const {
    agentProduction,
    selectCarrier,
    selectedCarrier,
    maTotal,
    msTotal,
    pdpTotal,
  } = props;
  return (
    <TableContainer
      style={{
        transform: "rotateY(180deg)",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
      component={Paper}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bolder" }}>Carriers</TableCell>
            <TableCell style={{ fontWeight: "bolder" }} align="right">
              MA
            </TableCell>
            <TableCell style={{ fontWeight: "bolder" }} align="right">
              Med Supp
            </TableCell>
            <TableCell style={{ fontWeight: "bolder" }} align="right">
              PDP
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" colSpan={12} style={{ padding: "0.5em" }}>
              <Attention />
            </TableCell>
          </TableRow>

          {agentProduction.length === 0 ? (
            <TableRow>
              <TableCell component="th" scope="row">
                No Data
              </TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow>
          ) : (
            agentProduction.map((data, i) => {
              const dataCheck = data.ma > 0 || data.ms > 0 || data.pdp > 0;
              return (
                <TableRow
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      mobileScreen && dataCheck ? "#555555" : null,
                    pointerEvents: dataCheck ? "all" : "none",
                  }}
                  hover
                  onClick={() => selectCarrier(data)}
                  selected={selectedCarrier === data.carrier}
                  key={`${i}${data.carrier}`}
                >
                  <TableCell component="th" scope="row">
                    {data.carrier}
                  </TableCell>
                  <TableCell
                    style={data.ma > 0 ? { color: "#D4AF37" } : null}
                    align="right"
                  >
                    {data.ma}
                  </TableCell>
                  <TableCell
                    style={data.ms > 0 ? { color: "#D4AF37" } : null}
                    align="right"
                  >
                    {data.ms}
                  </TableCell>
                  <TableCell
                    style={data.pdp > 0 ? { color: "#D4AF37" } : null}
                    align="right"
                  >
                    {data.pdp}
                  </TableCell>
                </TableRow>
              );
            })
          )}
          <TableRow>
            <TableCell
              style={{ fontWeight: "bolder" }}
              component="th"
              scope="row"
            >
              Totals
            </TableCell>
            <TableCell style={{ fontWeight: "bolder" }} align="right">
              {maTotal}
            </TableCell>
            <TableCell style={{ fontWeight: "bolder" }} align="right">
              {msTotal}
            </TableCell>
            <TableCell style={{ fontWeight: "bolder" }} align="right">
              {pdpTotal}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductionDataTable;
