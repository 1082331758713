import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    //! Leads & Agents Styles
    languageOptions: {
      "&:hover": {
        backgroundColor: "rgb(239 239 239)",
      },
    },
    muiTablePaginationToolbar: {
      "& .MuiTablePagination-toolbar": {
        paddingLeft: 8,
      },
    },
    text: {
      padding: theme.spacing(2, 2, 0),
      fontWeight: "bolder",
    },
    navButtonToolbarLeft: {
      transform: "rotate(180deg)",
      padding: 4,
      color: "white",
      margin: 4,
    },
    navButtonToolbarRest: {
      padding: 4,
      color: "white",
      margin: 4,
    },
    paper: {
      paddingTop: 64,
      paddingBottom: 75,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 56,
      },
      poasition: "relative",
    },
    avatarSmall: {
      width: theme.spacing(4),
      height: theme.spacing(4.5),
    },
    list: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "74.5ch !important",
      },
      marginBottom: "3em !important",
    },
    onbordingInfoList: {
      marginBottom: "3em !important",
    },
    onboardingInfoContainer: {
      [theme.breakpoints.up("md")]: {
        margin: "auto",
        width: "74.5ch !important",
      },
    },
    contactCardList: { width: "100%" },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      top: "auto !important",
      bottom: "0px !important",
    },
    grow: {
      flexGrow: 1,
    },
    listItemText: {
      "& .MuiListItemText-primary": {
        fontWeight: 300,
      },
      fontWeight: 900,
    },
    tabIndicator: {
      "& .MuiTabs-indicator": { backgroundColor: "#eee" },
      marginBottom: 2,
      color: "#eee",
    },
    card: {
      margin: 5,
    },
    boldHeader: {
      fontWeight: 900,
    },
    subheaderTime: {
      color: "#1d80c2",
    },
    noteCard: {
      margin: 5,
      padding: 5,
    },
    contractR: {
      fontWeight: "normal",
    },
    contractSelect: {
      minWidth: 400,
      margin: "0 auto",
    },
    underlineHeader: {
      fontWeight: 900,
      textDecoration: "underline",
    },
    states: {
      color: "#1d80c2",
      fontStyle: "italic",
    },
    contactStatusText: {
      color: "#eee",
      fontSize: "0.8em",
      padding: "0.2em 0.5em",
      borderRadius: "5px",
      textAlign: "center",
      fontWeight: 500,
    },
    expansionGrid: {
      marginTop: 5,
      padding: 10,
      paddingBottom: 50,
    },
    reportsReturns: {
      padding: 10,
    },
    contractCard: {
      padding: "1em",
      marginTop: 20,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "40vw",
      },
    },
    li: {
      display: "flex",
    },
    label: {
      fontWeight: "800",
      paddingRight: "0.5em",
      width: "15%",
    },
    value: {
      paddingLeft: "0.5em",
      width: "10%",
      textAlign: "right",
    },
    input: {
      border: "none",
      outline: "none",
      backgroundColor: "#EEE",
    },
    inputLabel: {
      fontSize: "1.1em",
    },
    btn: {
      transform: "scale(50%)",
      border: "none",
      background: "none",
      margin: "0",
      padding: "0",
    },
    description: {
      width: "100%",
    },
    listItemText1: {
      fontWeight: 900,
    },
    contractInput: {
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "400px",
      },
    },
    //! Report Styles
    reportsBackButton: {
      marginRight: theme.spacing(2),
    },
    reportsAdminMenuButton: {
      backgroundColor: "#596ac8",
    },
    reportsHeading: {
      fontWeight: 900,
      fontSize: "1.4em",
      textAlign: "center",
      marginTop: "4em",
    },
    reportCard: {
      width: "90%",
      maxWidth: 600,
      margin: "1em 0 2em",
    },
    reportRoot: {
      padding: 10,
      "& > *": {
        margin: "0.25em 0",
      },
    },
    resultCard: {
      width: "90%",
      maxWidth: 600,
      margin: "1em auto 3em",
      padding: "1em",
    },
    table: {
      "&.MuiTable-root": {
        width: "100%",
      },
    },
    tableHead: {
      backgroundColor: "#3f51b5",
    },
    tableSecondaryHead: {
      backgroundColor: "#99c1e8",
      wordWrap: "break-word",
    },
    tableHeadCell: {
      color: "#eee !important",
    },
    cellBorders: {
      borderRight: "1px solid #333",
    },
    formControl: {
      //margin: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    csvLink: {
      textDecoration: "none",
      color: "#eee",
      padding: "0.5em 1em",
      backgroundColor: "#3F51B5",
      borderRadius: "5px",
      fontSize: "0.8em",
    },
    reportDialogFormControl: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(1),
      minWidth: 120,
    },
    reportDialogSelectEmpty: {
      marginTop: theme.spacing(2),
    },
    //! SearchAppbar Styles
    searchAppbarRoot: {
      flexGrow: 1,
    },
    menuButton: {
      color: "#eee",
    },
    endButtons: {
      color: "#eee",
    },
    searchAppBarTitle: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    leadProfileTitle: {
      flexGrow: 1,
      display: "block",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha("#d3d3d3", 0.85),
      "&:hover": {
        backgroundColor: alpha("#d3d3d3", 1),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#575757",
    },
    inputRoot: {
      fontWeight: 300,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      borderRadius: 5,
      [theme.breakpoints.up("sm")]: {
        width: 300,
        "&:focus": {
          width: 375,
        },
      },
    },
    buttonDisplay: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
    buttonDisplaySmall: {
      display: "none !important",
      [theme.breakpoints.down("sm")]: {
        display: "block !important",
      },
    },
    emailAll: {
      margin: theme.spacing(1),
    },
    removeChecked: {
      margin: theme.spacing(1),
    },
    addNewLead: {
      margin: theme.spacing(1),
      backgroundColor: "#4caf50",
      color: "#eee",
    },
    addNewLead1: {
      margin: theme.spacing(1),
      backgroundColor: "#4caf50",
      color: "#eee",
    },
    clearSearchButton: {
      color: "#eee",
      backgroundColor: "#f50057",
    },
    expansionPanelSummary: {
      color: "#eee",
      backgroundColor: "#3f51b5",
      borderRadius: "5px",
    },
    DialogAppBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    dialogInput: {
      margin: "0 0 1em 0",
    },
    textField: {
      backgroundColor: "#fefefe",
    },
    notesContainer: {
      padding: "1em",
      backgroundColor: "#eeeeee",
      borderRadius: "5px",
    },
    contractRoot: {
      width: "100%",
    },
    contractHeading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    contactRowTitle: {
      fontWeight: 300,
      color: "black",
    },
    contactDialogTitle: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: "#3f51b5",
      color: "#eee",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#eee",
    },
    contactDialogForm: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    contactFormControl: {
      margin: theme.spacing(1),
      minWidth: "25ch",
    },
    contractRequestButton: {
      marginTop: 4,
      width: 200,
      borderColor: "#f1df3a",
      color: "#f1df3a",
      backgroundColor: "#3f51b5",
      "&:hover": {
        color: "#3f51b5",
        backgroundColor: "#f1df3a",
      },
    },
    fileDownload: {
      "&:hover": {
        color: "#3f51b5",
      },
    },
    downloadReports: {
      backgroundColor: "#4caf50",
      color: "#eee",
      "&:hover": {
        backgroundColor: "#549456",
      },
    },
    // Light / Dark Mode
    dark: {
      backgroundColor: "#333",
    },
    light: {
      backgroundColor: "#eee",
    },
    bg: {
      height: "100vh",
    },
    darkBg: {
      backgroundColor: "#333",
    },
    // End Light / Dark Mode
    // menu styles
    buttonContainer: {
      display: "flex",
      // backgroundColor: theme.palette.type,
      zIndex: 10,

      [theme.breakpoints.between("xs", "md")]: {
        justifyContent: "start",
        width: "100%",
        position: "fixed",
        bottom: "0px",
        height: "50px",
      },
      [theme.breakpoints.only("lg")]: {
        juatifyContent: "center",
        width: "100%",
        top: "0px",

        "&:nth-child(1)": {
          width: "60%",
        },
      },
      [theme.breakpoints.between("lg", "xl")]: {
        justifyContent: "center",
        width: "100%",
        top: "0px",

        "&:nth-child(1)": {
          width: "60%",
          margin: "auto",
        },
      },
    },
    NavButton: {
      color: "#eee",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    // end of menu styles
    landingPaper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      // margin: "0 auto",
    },
    homeContainer: {
      minHeight: "100vh",
      width: "100%",
      padding: 5,
    },
    advertImgs: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    notificationIcon: {
      fontSize: 30,
    },
    mainButtons: {
      width: "100%",
      background: "rgb(245, 245, 245, .15) !important",
      borderRadius: 5,
      transition: "all .5s",
      marginBottom: "15px !important",
      "&:hover": {
        opacity: 1,
        background: "rgb(245, 245, 245, .35)",
      },
    },
    secondaryLandingButtons: {
      background: "whitesmoke",
      marginBottom: 10,
      padding: 20,
      color: "black",
      opacity: 0.85,
      "&:hover": {
        opacity: 1,
        background: "whitesmoke",
      },
    },
    cardLink: {
      textDecoration: "none",
    },
    backgroundImage: {
      backgroundImage: "url('./img/background.png')",
      minHeight: "100vh",
      width: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    changeRequestDob: {
      width: 205,
      minWidth: 205,
      borderRadius: 5,
      "& .MuiFormLabel-root": {
        color: "#eee",
      },
      "& .MuiFilledInput-input": {
        padding: "19px 12px 10px",
        color: "#eee",
      },
      "& .MuiSvgIcon-root": { fontSize: ".85em", color: "#eee" },
    },
    customDivider: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    successNotification: {
      color: `${theme.palette.success.main} !important`,
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.success.main} !important`,
      },
      "&:hover": { backgroundColor: "rgba(76, 175, 80, .10) !important" },
    },
    secondaryNotification: {
      color: `${theme.palette.secondary.main} !important`,
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.secondary.main} !important`,
      },
      "&:hover": { backgroundColor: "rgba(237, 37, 24, .10) !important" },
    },
    stepTwoSubmitButton: {
      [theme.breakpoints.down("xs")]: {
        position: "fixed",
        bottom: theme.spacing(7.2),
        right: theme.spacing(0.5),
      },
    },
    textOverflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "200px",
    },
    scrollBar: {
      "&::-webkit-scrollbar": { width: "12px" },
      "&::-webkit-scrollbar-track": {
        background: "#073661",
        borderRadius: 30,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "whitesmoke",
        borderRadius: 20,
        border: "2px solid #073661",
      },
    },
    infoDatePicker: {
      width: "100%",
    },
  };
});

export default useStyles;
