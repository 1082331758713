import { UserLoginInfo } from "./types";
import client from "../../services/client";
import {
  getUserInfo,
  updateAgentPassword,
} from "../../services/GraphQl/queries-new";

export interface IAuthRepository {
  getUserInfo(options: UserInfoOptions): Promise<UserLoginInfo>;
  updateAgentPassword(options: any): Promise<any>;
}

export function AuthRepository() {
  const repository: IAuthRepository = {
    getUserInfo: async (options) => {
      const results = await client.query({
        query: getUserInfo,
        variables: options,
      });
      return results.data.getUserInfo;
    },
    updateAgentPassword: async (options) => {
      const results = await client.mutate({
        mutation: updateAgentPassword,
        variables: { options },
      });
      return results.data.updateAgentPassword;
    },
  };
  return repository;
}

export interface UserInfoOptions {
  id: string;
  loginType: string;
}

export interface UpdateAgentPasswordOptions {
  id: string;
  login: string;
  pass: string;
}
