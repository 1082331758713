import React from "react";
import "./Home.css";
import SplashLogo from "../../img/SplashLogo.png";
export default () => (
  <div className="homePage" style={{ placeItems: "center", display: "grid" }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={SplashLogo}
        alt="SplashLogo"
        height="75"
        width="75"
        photo="true"
        style={{ marginRight: 10 }}
      />
      <h1 style={{ margin: 0 }}>Sorry, page not found!</h1>
    </div>
  </div>
);
