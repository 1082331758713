import { makeAutoObservable, runInAction } from "mobx";
import { OnboardUserRepository } from "./repository";
import { makePersistable, stopPersisting } from "mobx-persist-store";
import { IOnboardUserRepository } from "./repository";

export class OnboardUserStore {
  isAgentNow: boolean = false;
  isOnboardRegistered: boolean = false;
  _repository: IOnboardUserRepository = OnboardUserRepository();

  setIsOnboardRegistered() {
    runInAction(() => {
      this.isOnboardRegistered = true;
    });
  }

  async subscribe(onboardId: string): Promise<boolean> {
    return this._repository.subscribe({ onboardId }, (callback: any) => {
      if (callback) {
        runInAction(() => {
          this.isAgentNow = true;
        });
      }
    });
  }

  subUnsubscribe() {
    this._repository.unsubscribe();
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: "OnboardUserStore",
      properties: ["isAgentNow", "isOnboardRegistered"],
      storage: window.localStorage,
    });
  }

  disposePersist(): void {
    stopPersisting(this);
  }
}
