import client from "../../services/client";
import { OnboardUserSubscriptionCallback, SubscribeOptions } from "./types";
import { isAgentNow } from "../../services/GraphQl/queries-new";

export interface IOnboardUserRepository {
  subscribe(
    options: SubscribeOptions,
    callback: OnboardUserSubscriptionCallback
  ): any;
  unsubscribe: () => void;
}
let subscription: any = undefined;

export function OnboardUserRepository(): IOnboardUserRepository {
  return {
    subscribe: (options, callback) => {
      if (subscription) {
        subscription.unsubscribe();
      }
      subscription = client
        .subscribe({
          query: isAgentNow,
          variables: { onboardId: options.onboardId },
        })
        .subscribe({
          next: (result: any) => {
            if (result) {
              callback(result);
            }
          },
        });
      return () => {};
    },
    unsubscribe: () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    },
  };
}
