import {
    ApolloLink,
    FetchResult,
    NextLink,
    Observable,
    Operation,
  } from "apollo-link";
  import { Client, ClientOptions, createClient } from "graphql-ws";
  import { print } from "graphql";
  
  export class GQLWebSocketLink extends ApolloLink {
    private client: Client;
  
    constructor(options: ClientOptions) {
      super();
      this.client = createClient(options);
    }
  
    request(
      operation: Operation,
      forward?: NextLink
    ): Observable<
      FetchResult<
        { [key: string]: any },
        Record<string, any>,
        Record<string, any>
      >
    > {
      const { query, variables, operationName } = operation;
  
      return new Observable((sink) => {
        const unsubscribe = this.client.subscribe(
          { query: print(query), variables, operationName },
          sink
        );
        return () => {
          unsubscribe()
        }
      });
    }
  }  