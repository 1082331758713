import React from "react";
import { Typography, Grid, Backdrop, CircularProgress } from "@mui/material";

const UtilityLoading = (props) => {
  const { loadingState, children } = props;
  return (
    <Backdrop
      style={{ zIndex: 1301, backgroundColor: "#202020" }}
      open={loadingState}
    >
      <Grid container direction="column" alignItems="center" spacing={2}>
        <CircularProgress style={{ color: "white", marginRight: 10 }} />
        <Grid item>
          <Typography style={{ color: "white" }}>{children}</Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
};
export default UtilityLoading;
