import React from "react";
import { IAuthStore, AuthStore } from "../auth";
/* 
  Root store that holds a reference to all other stores.
  This is the top level context that any component can tap into.
*/
export interface IRootStore {
  authStore: IAuthStore;
}

export function RootStore() {
  const lazyStore = <S>(factory: (root: IRootStore) => S) => {
    let _store: S | null = null;
    return () => {
      if (_store === null) {
        _store = factory(store);
      }
      return _store;
    };
  };

  const _authStore = lazyStore(AuthStore);

  const store: IRootStore = {
    get authStore() {
      return _authStore();
    },
  };
  return store;
}

export const RootStoreContext = React.createContext(RootStore());
