import React, { useState } from "react";
import Table from "@mui/material/Table";
import {
  Grid,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { KeyboardBackspace, Done, Clear } from "@mui/icons-material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import Banner from "../../../../common/Banner";

const RtsProductsDataTable = (props) => {
  const { selectedCarrier, flipClick, rows, classes } = props;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;

  const Attention = () => (
    <Typography
      style={{
        color: "#D4AF37",
      }}
      variant="caption"
    >
      {selectedCarrier} Product/State
      {rows.length > 0 ? " as of " + rows[0].AsOf : null}
    </Typography>
  );

  const ProductsToolbar = () => {
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Banner content="Contact carrier for the most up to date Cert and RTS information" />{" "}
        <Grid item>
          <IconButton size="small" onClick={() => flipClick("", "")}>
            <KeyboardBackspace />
          </IconButton>
        </Grid>
        <Grid item>{selectedCarrier ? <Attention /> : null}</Grid>
        <Grid item />
      </Grid>
    );
  };

  const ProductsHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: "bolder", borderRight: "2, black" }}>
            Corp
          </TableCell>
          <TableCell
            style={{ fontWeight: "bolder", borderRight: "2" }}
            align="left"
          >
            Is Cert
          </TableCell>
          <TableCell
            style={{ fontWeight: "bolder", borderRight: "2" }}
            align="left"
          >
            Product
          </TableCell>
          <TableCell
            style={{ fontWeight: "bolder", borderRight: "2" }}
            align="left"
          >
            Product Note
          </TableCell>
          <TableCell
            style={{ fontWeight: "bolder", borderRight: "2" }}
            align="left"
          >
            State
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const ProductsBody = () => {
    return (
      <TableBody>
        {rows &&
          rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, i) => (
              <TableRow key={`${i}${data.id}`}>
                <TableCell
                  style={{ padding: mQuery && 8 }}
                  component="th"
                  scope="row"
                >
                  {data.IsCorp ? <Done /> : <Clear />}
                </TableCell>
                <TableCell style={{ padding: mQuery && 8 }}>
                  {data.Certified === "true" ? <Done /> : <Clear />}
                </TableCell>
                <TableCell style={{ padding: mQuery && 8 }}>
                  {data.Product}
                </TableCell>
                <TableCell style={{ padding: mQuery && 8 }}>
                  {data.CarrierNote}
                </TableCell>
                <TableCell style={{ padding: mQuery && 8 }}>
                  {data.State}
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
    );
  };

  return (
    <Grid
      style={{
        paddingBottom: 42,
      }}
    >
      <Paper style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <Table>
          {ProductsHeader()}
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan="12">
                {ProductsToolbar()}
              </TableCell>
            </TableRow>
          </TableBody>
          {ProductsBody()}
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.muiTablePaginationToolbar}
                rowsPerPageOptions={[5, 10, 25]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Grid>
  );
};

export default RtsProductsDataTable;
