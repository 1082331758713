import { UserLoginInfo } from "../store";
export function useAuth() {
  //Check persisted user and for staging
  const isStaging = sessionStorage.getItem("isStaging") === "true";
  const user: UserLoginInfo = JSON.parse(
    sessionStorage.getItem("user") as string
  );


  // Is user logged in?
  if (!user) {
    window.location.pathname = "/";
  }

  // Protect paths - only onboard users on Dashboard path
  // and dont allow onboard users on other paths except ["/Dashboard", "/"]
  if (
    (window.location.pathname === "/Dashboard" &&
      user?.loginType !== "onboard") ||
    (user?.loginType === "onboard" &&
      !["/Dashboard", "/"].includes(window.location.pathname))
  ) {
    window.location.pathname = "/";
  }
  //TODO: Remove once ready for full release
  if (!isStaging && window.location.pathname.includes("/Onboard")) {
    window.location.pathname = "/";
  }
}
