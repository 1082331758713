import React from "react";
import { Grid, Divider } from "@mui/material";

const CustomDivider = (props) => {
  const { children } = props;
  return (
    <Grid item xs={12} container alignItems="center" justifyContent="center">
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={5} />
        <Divider
          orientation="vertical"
          style={{ width: 1, height: 20, background: "whitesmoke" }}
        />
        <Grid item xs={5} />
      </Grid>
      <Grid item xs={5}>
        <Divider />
      </Grid>
      <Grid item xs={2} align="center">
        {children}
      </Grid>
      <Grid item xs={5}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default CustomDivider;
