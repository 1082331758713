import { runInAction, makeAutoObservable } from "mobx";
import { ContactRepository } from "./repository";
import { ContactInfo } from "./types";
import { makePersistable, stopPersisting } from "mobx-persist-store";
import { IContactRepository } from ".";

export class ContactStore {
  contacts: ContactInfo | null = null;
  loading: boolean = false;
  error: Error | null = null;
  repository: IContactRepository = ContactRepository();
  async loadContacts(agentId: string): Promise<void> {
    this.loading = true;
    this.contacts = null;
    try {
      const contactInfo: any = await this.repository.agentContactInfo({
        agentId,
      });
      if (contactInfo) {
        runInAction(() => {
          this.contacts = contactInfo;
        });
      }
    } catch (error) {
      this.error = error as Error;
    }
    this.loading = false;
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: "ContactStore",
      properties: ["contacts"],
      storage: window.localStorage,
    });
  }

  disposePersist(): void {
    stopPersisting(this);
  }
}
