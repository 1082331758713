import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import React from "react";
import Banner from "../../../common/Banner";
import ChangeRequestContent from "../agents/agentsComponents/ChangeRequestContent";
import StepComplete from "./StepComplete";

const UpdateAgentStep = (props) => {
  const {
    classes,
    changeRequestFormValues,
    formChanges,
    submitChangeRequest,
    disabled,
    stepTwoSuccess,
    theme,
  } = props;

  return (
    <Grid
      item
      xs={12}
      sm={5}
      container
      style={{ padding: "80px 5px 80px" }}
      justifyContent="center"
    >
      {stepTwoSuccess !== "" ? (
        <StepComplete completeMessage="Changes Submitted! Click the finish below to move on." />
      ) : (
        <>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Banner content="Information is updated with Berwick and is NOT sent to carriers." />
            <ChangeRequestContent
              changeRequestFormValues={changeRequestFormValues}
              submitChangeRequest={submitChangeRequest}
              formChanges={formChanges}
              classes={classes}
              message={
                <Grid container item xs={12} justifyContent="center">
                  <Grid item>
                    <Typography variant="h5">
                      Current Information On File
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Click Finish below to skip this step
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ color: "#D4AF37" }} variant="caption">
                      *Note: If you would like, please correct any errors you
                      may see or add information that might be missing.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ color: "#D4AF37" }} variant="caption">
                      *Note: Changes can take up to 72 hours to be reflected.
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item className={classes.stepTwoSubmitButton}>
            <ButtonGroup size="small" variant="contained">
              <Button
                onClick={submitChangeRequest}
                disabled={disabled}
                style={{
                  background: theme.palette.primary.main,
                  color: "white",
                }}
              >
                {stepTwoSuccess !== "" ? stepTwoSuccess : `Submit`}
              </Button>
            </ButtonGroup>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UpdateAgentStep;
