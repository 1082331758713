import React, { useContext, useEffect, useState } from "react";
import "./Info.css";

import { Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import { Logout } from "@mui/icons-material";

import { RootStoreContext, UserLoginInfo } from "../../../store";
import ContractRequestStepper from "./ContractRequestStepper";
import { ocrByOnboardId } from "../../../services/BDSWebService";
import { useAuth } from "../../useAuth";
import Info from "./Info";
import { updateOnboardLead } from "../../../services/BDSWebService";
import { deleteKeys } from "../../../services/utils";
import useStyles from "../../../common/useStyles";
import RegOnbAppBar from "../register/RegOnbAppBar";
import BottomProfileBar from "./BottomInfoBar";
import SwipeableViews from "react-swipeable-views";
import ContractrequestTable from "./ContractRequestTable";
import { OnboardUserStore } from "../../../store/onboard-user/store";
import { observer } from "mobx-react";
import Swal from "sweetalert2";
import { updateUserAttributes } from "../../../services/amplify/ampClient";

const defaultFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  npn: "",
  gender: "",
  residentState: "",
  birthDate: new Date(),
  ssn: "",
};

const Onboarding = observer(() => {
  useAuth();
  const [onboardUserStore] = useState(() => new OnboardUserStore());
  const mobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();

  const { authStore } = useContext(RootStoreContext);
  const [open, setOpen] = useState<boolean>(false);
  const [contractRequests, setContractRequests] = useState<Array<object>>([]);
  const [editActive, setEditActive] = useState<boolean>(false);
  const [toggleHide, setToggleHide] = useState<boolean>(false);
  const [user, setUser] = useState<UserLoginInfo | null>(null);
  const [formState, setFormState] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    npn: "",
    gender: "",
    residentState: "",
    birthDate: new Date(),
    ssn: "",
  });
  const [pageValue, setPageValue] = useState(0);

  useEffect(() => {
    const userInfo: UserLoginInfo = JSON.parse(
      sessionStorage.getItem("user") as string
    );
    setUser(userInfo);
    ocrByOnboardId(`${userInfo.onboardId}`).then((res: any) =>
      setContractRequests(res.data.ocrByOnboardId)
    );
    onboardUserStore.subscribe(`${userInfo.onboardId}`);
    return () => {
      sessionStorage.removeItem("user");
      onboardUserStore.subUnsubscribe();
      onboardUserStore.disposePersist();
      localStorage.removeItem("OnboardUserStore");
    };
  }, []);

  useEffect(() => {
    if (editActive) {
      setFormState({ ...user });
    } else {
      setFormState(defaultFormState);
    }
  }, [editActive]);

  const handleEdit = (e: any) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleAgentDataChange = (e: any, date: any, name: any) => {
    setFormState({ ...formState, [name]: date });
  };
  const cleanedData: any = () => {
    deleteKeys(
      [
        "onboardId",
        "firstName",
        "lastName",
        "email",
        "phone",
        "recruiterId",
        "displayName",
        "npn",
        "gender",
        "residentState",
        "birthDate",
        "ssn",
      ],
      formState,
      "keysbytype"
    );
  };

  const proceedWithSave = async () => {
    const userInput = { ...user, ...formState };
    sessionStorage.setItem("user", JSON.stringify(userInput));
    setUser(userInput);
    cleanedData();
    await updateOnboardLead({
      ...formState,
    });
    setFormState(defaultFormState);
    setEditActive(!editActive);
  };

  const saveUserInfo = () => {
    const emailChanged = user?.email !== formState.email;
    if (emailChanged) {
      Swal.fire({
        icon: "info",
        titleText: "Email Changed",
        text: "Please be aware, changing your email here also changes your login email. This will be effective immediately after this window closes.",
        confirmButtonText: "Got it, thanks!",
      }).then(async () => {
        await proceedWithSave();
        // Email changed: update with aws
        updateUserAttributes({ email: formState.email });
      });
    } else {
      proceedWithSave();
    }
  };

  const handleChangeIndex = (index: any) => {
    setPageValue(index);
  };

  const handlePageChange = (e: any, newValue: number) => {
    setPageValue(newValue);
  };

  const toSaveOrNot = (val: string) => {
    if (val !== "Save") {
      setEditActive(!editActive);
    }
    if (val === "Save") {
      saveUserInfo();
    }
  };

  const handleCancel = () => {
    setEditActive(!editActive);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const logout = async () => {
    sessionStorage.removeItem("user");
    onboardUserStore.subUnsubscribe();
    onboardUserStore.disposePersist();
    localStorage.removeItem("OnboardUserStore");
    authStore.logout();
  };

  useEffect(() => {
    if (onboardUserStore.isAgentNow) {
      Swal.fire({
        title: "Good News!",
        text: "Your Upline has processed your submitted contract requests! Please log out and back in to unlock the full potential of Agent Way!",
        icon: "success",
        confirmButtonText: "Log me out!",
      }).then(() => logout());
    }
  }, [onboardUserStore.isAgentNow]);

  return (
    <div className="onboardingBackground">
      <RegOnbAppBar
        appBarTitle="Onboarding"
        recruiterName={user?.recruiterName}
      >
        <IconButton sx={{ mr: 1 }} onClick={logout}>
          <Logout />
        </IconButton>
      </RegOnbAppBar>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          pt: mobile ? "100px" : 10,
        }}
        component={Grid}
        container
        justifyContent="center"
        alignContent="center"
        spacing={2}
      >
        <Grid item xs={12} sx={{ pb: 8 }}>
          <SwipeableViews index={pageValue} onChangeIndex={handleChangeIndex}>
            <Info
              user={user && user}
              toSaveOrNot={toSaveOrNot}
              handleCancel={handleCancel}
              editActive={editActive}
              handleEdit={handleEdit}
              formState={{ ...formState }}
              handleAgentDataChange={handleAgentDataChange}
              toggleHide={toggleHide}
              setToggleHide={setToggleHide}
              classes={classes}
              mobile={mobile}
            />
            <ContractrequestTable contractRequests={contractRequests} />
          </SwipeableViews>
        </Grid>
        <Grid item xs={12}>
          <BottomProfileBar
        // AW-371 4/13/2023: Leaving this commented out code for now, in case they change their mind 
        // about limiting the number of requests allowed in one session.
        //    disabled={
        //      contractRequests.filter(
        //        (row: any) => row.status === "Needs Attention"
        //      ).length === 5
        //    }
            classes={classes}
            pageValue={pageValue}
            setPageValue={setPageValue}
            handlePageChange={handlePageChange}
            handleOpen={handleOpen}
          />
        </Grid>
        {user && (
          <ContractRequestStepper
            setOpen={setOpen}
            open={open}
            user={user}
            setContractRequests={setContractRequests}
          />
        )}
      </Box>
    </div>
  );
});

export default Onboarding;
