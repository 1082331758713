import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Divider,
  ListItem,
} from "@mui/material";
import { ExpandMore, Info } from "@mui/icons-material";
import { reduceDate } from "../../../../services/utils";
import HtmlTooltip from "../../../ui-components/HtmlTooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const agentParseTooltip = (contractStatus, writingNum) => {
  let text;
  switch (contractStatus) {
    case "AGENT_CERT":
      text = "Agent in certification stage";
      break;
    case "APPROVED":
    case "APPROVED_NOT_SELLING":
      text = "Has been issued writing # " + writingNum;
      break;
    case "APPROVED_INTENT_TO_MOVE_AWAY_SUBMITTED":
      text = "Agent has initiated intent to move away process";
      break;
    case "APPROVED_RELEASING":
      //displays as "Approved - Released"
      text =
        "Agent has been approved for immediate self-release, OR agent has initiated carrier self-release process";
      break;
    case "INTENT_MOVE_BIG":
      text =
        "Contracting process can begin once agent is eligible to move under Berwick";
      break;
    case "PENDING_WRITING":
      text =
        "Final stages, waiting for carrier to issue writing # " + writingNum;
      break;
    case "RECEIVED":
      text = "Received contract with errors, waiting on agent to correct";
      break;
    case "SENT_TO_AGENT":
      text = "Waiting for contract to be completed by agent ";
      break;
    case "SUBMITTED":
      text = "Contract has been submitted to carrier";
      break;
    case "SUBMITTED_MISSING_DOC":
      text =
        "Received a missing document notice from carrier, agent has been informed";
      break;
    case "SUBMITTED_NO_CERT":
      text =
        "Contract has been submitted to carrier, and there are no certifications required";
      break;
    case "WITHDRAWN":
      text =
        "Contract never completed due to either agent inaction or carrier request";
      break;
    case "REJECTED":
      text = "Failed background";
      break;
    case "TERMINATED":
      text = "Contract has been terminated";
      break;

    default:
      text = "not found";
  }
  return text;
};

const ContractList = (props) => {
  const { classes, contracts } = props;
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const sortedContracts = contracts.data.sort((a, b) =>
    a.carrierName.localeCompare(b.carrierName)
  );
  const closeToolTip = () => {
    setToolTipOpen(false);
    setCurrentIndex(null);
  };

  return (
    <Grid item xs={12} sm={6}>
      {sortedContracts.map((contract, i) => (
        <Accordion key={`${contract.id} - ${i}`}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container>
              {contract.carrierName} {contract.carrierLabel} - {contract.state}
              <Grid item xs={12}>
                <span
                  style={{
                    color:
                      contract.contractStatus === "APPROVED"
                        ? "limegreen"
                        : contract.contractStatus === "TERMINATED" ||
                          contract.contractStatus === "WITHDRAWN"
                        ? "#ed2518"
                        : "#e2772c",
                    fontSize: ".75em",
                    fontWeight: 600,
                  }}
                >
                  {contract.contractStatus.replace(/_/g, " ")}
                  <ClickAwayListener
                    onClickAway={() => i === currentIndex && closeToolTip()}
                  >
                    <HtmlTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={i === currentIndex && toolTipOpen}
                      onClose={() => i === currentIndex && closeToolTip()}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={agentParseTooltip(
                        contract.contractStatus,
                        contract.writingNumber
                      )}
                    >
                      <Info
                        onClick={(e) => {
                          e.stopPropagation();
                          setToolTipOpen(!toolTipOpen);
                          setCurrentIndex(i);
                        }}
                      />
                    </HtmlTooltip>
                  </ClickAwayListener>
                </span>{" "}
                {contract.isCorp ? (
                  <span
                    style={{
                      color: "#0094ff",
                      marginLeft: 10,
                      fontSize: ".75em",
                      fontWeight: 600,
                    }}
                  >
                    CORP
                  </span>
                ) : null}
              </Grid>
            </Grid>
          </AccordionSummary>
          <Divider />
          <AccordionDetails style={{ background: "whitesmoke" }}>
            <Grid container>
              <ListItem className={classes.customDivider}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                      align="left"
                      gutterBottom
                    >
                      Product/Writing Number:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.contactRowTitle}
                      variant="subtitle2"
                    >
                      {" "}
                      {contract.productName} {contract.writingNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.customDivider}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                      align="left"
                      gutterBottom
                    >
                      Corp:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                    >
                      {" "}
                      {contract.isCorp ? "Yes" : "No"}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.customDivider}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                      align="left"
                      gutterBottom
                    >
                      Contract Level:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                    >
                      {" "}
                      {contract.carrierLabel}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.customDivider}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                      align="left"
                      gutterBottom
                    >
                      Status:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                    >
                      {" "}
                      {contract.contractStatus.replace(/_/g, " ")}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.customDivider}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                      align="left"
                      gutterBottom
                    >
                      Status Date:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                    >
                      {" "}
                      {reduceDate(contract.statusDate)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                      align="left"
                    >
                      State:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactRowTitle}
                    >
                      {" "}
                      {contract.state}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};
export default ContractList;
