import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Card,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  CardContent,
  CircularProgress,
  TextField,
  CardActions,
  Link,
  useMediaQuery,
} from "@mui/material";
import { SystemUpdate, Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from "sweetalert2";
import { observer } from "mobx-react";
import { AgentStore, RootStoreContext } from "../../store";
import { inputHandler, clickHandler } from "../handlers";
import "./AgentWayLogin.css";
import "animate.css";
import { useSpring, animated as a } from "react-spring";
import UtilityLoading from "../../common/UtilityLoading";
import PasswordReset from "./register/PasswordReset";
import { makeStyles } from "@mui/styles";
import { useReactPWAInstall } from "@elilondon/react-pwa-install";
import SplashLogo from "./img/splashLogo.png";
import { updateLastLogin } from "../../services/BDSWebService";
import * as Sentry from "@sentry/react";
import HtmlTooltip from "../ui-components/HtmlTooltip";
const useStyles = makeStyles((theme) => ({
  agentWayLoginButton: {
    background: "#003660 !important",
    color: "whitesmoke !important",
    "&:hover": {
      background: "#003660",
      color: "white",
      opacity: 0.8,
    },
    fontFamily: "'pathway_gothic_oneregular', sans-serif  !important",
    padding: "15px  !important",
    fontSize: "2.25em !important",
  },
  agentWayLoginTextField: {
    background: "#e8f0fe !important",
    borderRadius: 5,
    "& .MuiFilledInput-underline:after": {
      borderBottom: "6px solid #003660 !important",
    },
    "& .MuiFilledInput-input": {
      color: "#003660",
    },
  },
}));

const AgentWayLogin = observer((props) => {
  const { authStore } = useContext(RootStoreContext);
  const [agentStore] = useState(() => new AgentStore());
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  useEffect(() => {
    return () => agentStore.disposePersist();
  }, [agentStore]);
  let history = useHistory();
  const classes = useStyles();

  const [flipped, setFlipped] = useState(false);
  const [openPasswordReset, setOpenPasswordReset] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(1200px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  useEffect(() => {
    let timer1 = setTimeout(() => setFlipped(true), 2000);
    return () => {
      clearTimeout(timer1);
    };
  });

  const mQuery = useMediaQuery("(max-width:641px)") ? true : false;

  useEffect(() => {
    if (authStore.isLoggedIn) {
      if (authStore.user && authStore.user.loginType === "admin") {
        Swal.fire({
          text: "Enter An Agent ID:",
          input: "text",
          showCancelButton: true,
        })
          .then(({ value }) => {
            if (value) {
              agentStore
                .load(value)
                .then((check) =>
                  check ? history.push("/Home") : authStore.logout()
                );
            } else {
              authStore.logout();
            }
          })
          .catch((err) => console.log(err));
      } else {
        try {
          authStore.user.loginType === "onboard" // Onboard only to this path
            ? history.push("/Dashboard")
            : agentStore.load(authStore.user.id).then((check) => {
                // Regular login path
                if (check) {
                  updateLastLogin(authStore.user.id);
                  history.push("/Home");
                } else {
                  authStore.logout();
                }
              });
        } catch (error) {
          Swal.fire("Error", error.message, "error");
          Sentry.captureMessage(
            `Error: ${error.message}  - id: ${
              authStore.user.id ?? authStore.user.onboardId
            } / login type: ${authStore.user.loginType}`
          );
        }
      }
    }
  }, [authStore.isLoggedIn, authStore, agentStore, history]);

  const isStaging = sessionStorage.getItem("isStaging");
  useEffect(() => {
    !isStaging &&
      sessionStorage.setItem(
        "isStaging",
        !window.location.href.includes("://agentway.io")
      );
  }, [isStaging]);

  const validateForm = () => {
    return (
      authStore.options.username.length > 0 &&
      authStore.options.password.length > 0
    );
  };

  const handleEmailChange = inputHandler((username) => {
    authStore.setOptions({ username });
  });

  const handlePasswordChange = inputHandler((password) => {
    authStore.setOptions({ password });
  });

  const handleClickShowPassword = clickHandler(() =>
    authStore.setShowPassword(!authStore.showPassword)
  );

  const handleSubmit = clickHandler(() => {
    validateForm() && authStore.userLogin();
  });

  const registerAgent = () => {
    Swal.fire({
      titleText: `Register with Agent Way`,
      text: `We are so glad that you are joining Agent Way! Please click below to start the registration process.`,
      icon: "info",
      reverseButtons: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Register Now!",
    }).then((value) => {
      if (value.isConfirmed) {
        history.push("/Registration");
      }
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const openPwResetModal = () => {
    setOpenPasswordReset(true);
  };

  const closePwResetModal = () => {
    setOpenPasswordReset(false);
  };

  const promptDownload = () => {
    isInstalled()
      ? Swal.fire("Installed", "This app is already installed, enjoy!", "info")
      : pwaInstall({
          title: "Install AWAY",
          logo: SplashLogo,
          descritpionTitle: "Instructions",
          description:
            "This will cause a prompt for you to download our app. Please click install on the next prompt.",
          instructionActionInstall: "Proceed",
        });
  };

  if (agentStore.loading) {
    return (
      <UtilityLoading loadingState={agentStore.loading}>
        Loading Your Dashboard
      </UtilityLoading>
    );
  }

  const NewToAgentWayBanner = (
    <Grid container justifyContent="center" margin="auto">
      <Grid
        item
        xs={12}
        sm={8}
        style={{ height: 60, paddingTop: mQuery ? 20 : 5 }}
      >
        <Typography
          sx={{
            color: "!important",
            fontFamily: "'pathway_gothic_oneregular', sans-serif",
            fontSize: "1.25em",
            textAlign: "center",
          }}
        >
          New to AgentWay?
          <br /> Click ‘Register’ below to get started
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <div className="loginPage">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <a.div
          style={{
            willChange: "transform, opacity",
            opacity: opacity.interpolate((o) => 1 - o),
            transform,
            position: "absolute",
            height: mQuery ? "max(100%, 100vh)" : 600,
            minHeight: mQuery ? "100vh" : 600,
            minWidth: mQuery ? "100vw" : 600,
            maxWidth: "100%",
          }}
        >
          <Grid
            style={{
              height: "100%",
              width: "100%",
            }}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Card
              className="animate__animated animate__fadeIn"
              style={{
                width: "100%",
                height: "100%",
                "--animate-duration": "3s",
                background: "#8b8076",
                borderRadius: mQuery ? 0 : "2%",
              }}
            >
              <CardContent
                className="agentWayBoxImg"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: mQuery ? 0 : "2%",
                }}
              ></CardContent>
            </Card>
          </Grid>
        </a.div>
        <a.div
          style={{
            willChange: "transform, opacity",
            opacity,
            transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
            pointerEvents: flipped ? "all" : "none",
            position: flipped ? "" : "absolute",
            height: mQuery ? "max(100%, 100vh)" : 600,
            minHeight: mQuery ? "100vh" : 600,
            minWidth: mQuery ? "100%" : 600,
            maxWidth: "100%",
          }}
        >
          <Card
            style={{
              width: mQuery ? "100%" : 600,
              height: mQuery ? "max(100%, 100vh)" : 600,
              minHeight: mQuery ? "100vh" : 600,
              minWidth: mQuery ? "100%" : 600,
              backgroundColor: "rgba(255, 255, 255, .15)",
              backdropFilter: "blur(2px)",
              borderRadius: mQuery ? 0 : "2%",
              padding: 10,
            }}
            raised
          >
            <div className="agentWayIconImg"></div>
            <Grid container justifyContent={mQuery ? "flex-end" : "center"}>
              <Grid
                item
                xs={12}
                sm={10}
                align={mQuery ? "end" : "center"}
                style={{ paddingRight: mQuery && 15 }}
              >
                <div
                  className="agentWaytitle"
                  style={{
                    fontSize: "5.2rem",
                    fontFamily: "'pathway_gothic_oneregular', sans-serif",
                    textShadow: "0px 3px 7px rgba(0,0,0,0.6)",
                  }}
                >
                  LOGIN
                </div>
              </Grid>
            </Grid>
            <div className="agentWayLoginForm">
              {!mQuery && NewToAgentWayBanner}
              <form onSubmit={handleSubmit}>
                {mQuery && NewToAgentWayBanner}
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={8} style={{ padding: 20 }}>
                    <TextField
                      id="email"
                      autoFocus
                      value={authStore.options.username}
                      autoComplete="username"
                      onChange={handleEmailChange}
                      fullWidth
                      variant="filled"
                      className={classes.agentWayLoginTextField}
                      label={
                        <Typography
                          sx={{
                            color: "#003660 !important",
                            fontFamily:
                              "'pathway_gothic_oneregular', sans-serif",
                            fontSize: "1.25em",
                          }}
                        >
                          Username
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} style={{ padding: 20 }}>
                    <TextField
                      fullWidth
                      variant="filled"
                      className={classes.agentWayLoginTextField}
                      id="password"
                      type={authStore.showPassword ? "text" : "password"}
                      value={authStore.options.password}
                      autoComplete="current-password"
                      onChange={handlePasswordChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                            >
                              {authStore.showPassword ? (
                                <Visibility
                                  sx={{ color: "#003660 !important" }}
                                />
                              ) : (
                                <VisibilityOff
                                  sx={{ color: "#003660 !important" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label={
                        <Typography
                          sx={{
                            color: "#003660 !important",
                            fontFamily:
                              "'pathway_gothic_oneregular', sans-serif",
                            fontSize: "1.25em",
                          }}
                        >
                          Password
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} style={{ padding: 20 }}>
                    <Button
                      className={classes.agentWayLoginButton}
                      fullWidth
                      id="login"
                      type="submit"
                    >
                      {!authStore.loading ? (
                        "SUBMIT"
                      ) : (
                        <div style={{ color: "whitesmoke" }}>
                          <CircularProgress
                            size={32}
                            style={{
                              marginRight: 10,
                              color: "whitesmoke",
                            }}
                          />
                          ...Loading
                        </div>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
            <CardActions
              style={{
                display: "flex",
                justifyContent:
                  !isInstalled() && supported() ? "space-between" : "flex-end",
                color: "#eee",
              }}
            >
              {!isInstalled() && supported() && (
                <HtmlTooltip title="Download this app">
                  <IconButton color="inherit" onClick={() => promptDownload()}>
                    <SystemUpdate />
                  </IconButton>
                </HtmlTooltip>
              )}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  textAlign: "center",
                }}
              >
                <Link
                  href="#"
                  onClick={() => registerAgent()}
                  color="inherit"
                  underline="hover"
                >
                  Register
                </Link>
                <Link
                  href="#"
                  onClick={() => openPwResetModal()}
                  color="inherit"
                  underline="hover"
                >
                  Forgot Password
                </Link>
              </div>
            </CardActions>
          </Card>
        </a.div>
      </Grid>
      <PasswordReset
        openPasswordReset={openPasswordReset}
        closePwResetModal={closePwResetModal}
        mQuery={mQuery}
        history={history}
      />
    </div>
  );
});

export default AgentWayLogin;
