import { Grid, TextField, Card, CardMedia } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../useAuth";
import TopSecret from "../img/topsecret.png";
import TopSecret2 from "../img/topsecret2.jpg";
import tumbleweed from "../img/tumbleweed.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Card: {
    background: "#EFDECD",
    padding: 12,
    border: "1 solid #ccc",
    boxShadow: "5px 5px 5px #999",
    margin: "auto",
  },
  Media: {
    height: 550,
    width: "100%",
  },
  RockingMina: {
    height: 550,
    width: "100%",
    animation: `$rockingMina 3000ms`,
  },
  "@keyframes rockingMina": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "20%": {
      transform: "rotate(-30deg)",
    },
    "25%": {
      transform: "rotate(0deg) translate(1px, 0)",
    },
    "45%": {
      transform: "rotate(-30deg) translate(2px, 0)",
    },
    "50%": {
      transform: "rotate(0deg) translate(0, 0)",
    },
    "70%": {
      transform: "rotate(-40deg) translate(-30px, 0)",
    },
    "75%": {
      transform: "rotate(0deg) translate(0, 0)",
    },
    "80%": {
      transform: "rotate(0deg) translate(0, 0)",
    },
    "100%": {
      transform: "rotate(0)  translate(0, 0)",
    },
  },
  Tumbleweed: {
    position: "absolute",
    left: "-20%",
    top: "50%",
    width: "10rem",
    height: "10rem",
    animation: `$jumping 1.5s infinite, $rolling 10s linear infinite, $rotating 2s linear infinite`,
    animationDelay: "8s",
  },
  "@keyframes rolling": {
    from: {
      left: "-20%",
    },
    to: {
      left: "120%",
    },
  },
  "@keyframes rotating": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes jumping": {
    "0%": {
      top: "0rem",
      animationTimingFunction: "ease-in",
    },
    "25%": {
      top: "2rem",
      animationTimingFunction: "ease-out",
    },
    "50%": {
      top: "0rem",
      animationTimingFunction: "ease-in",
    },
    "75%": {
      top: "2rem",
      animationTimingFunction: "ease-out",
    },
    "100%": {
      top: "0rem",
      animationTimingFunction: "ease-in",
    },
  },
}));

const TopSecretComponent = () => {
  useAuth();
  const [password, setPassword] = useState("");
  const correctPassword = "[F'#ss>aY+S,X8S";
  const classes = useStyles();
  if (password !== correctPassword)
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <Grid item>Enter the correct password to continue</Grid>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
    );
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100%",
        height: "100vh",
        padding: "0px 20px",
        overflow: "hidden",
      }}
    >
      <Grid item xs={12} align="center">
        <h2>
          This page is dedicated to Mina whose contributions made this
          application and many others possible!
        </h2>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.Card}>
          <CardMedia
            className={classes.Media}
            image={TopSecret}
            title="Classroom with Mina"
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.Card}>
          <CardMedia
            className={classes.RockingMina}
            image={TopSecret2}
            title="Rockstar Mina"
          />
        </Card>
      </Grid>
      <div style={{ width: "100vw", height: "15rem", position: "relative" }}>
        <img
          src={tumbleweed}
          className={classes.Tumbleweed}
          alt="RockStar Mina!"
        />
      </div>
    </Grid>
  );
};

export default TopSecretComponent;
