import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import client from "./services/client";
import { RootStoreContext, RootStore } from "./store";
import { ApolloProvider } from "@apollo/react-hooks";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://27720262027e44cd887d6bb33fbcf716@o281205.ingest.sentry.io/6520344",
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 0,
});

ReactDOM.render(
  <RootStoreContext.Provider value={RootStore()}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </RootStoreContext.Provider>,
  document.getElementById("root")
);
serviceWorker.register();
