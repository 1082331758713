import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    textAlign: "center",
  },
});

const UtilityCard = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6">{children}</Typography>
      </CardContent>
    </Card>
  );
};
export default UtilityCard;
