import {
  Cancel,
  Edit,
  Save,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  Typography,
  styled,
  TextField,
  ButtonGroup,
  MenuItem,
  InputAdornment,
  IconButton,
  List,
  ListItem,
} from "@mui/material";

import React from "react";
import CustomDatePicker from "../../../common/CustomDatePicker";
import { genders, numbersOnlyInput, validEmail } from "../../../services/utils";

const CustomTextField = styled(TextField)({
  "& .MuiInput-underline.Mui-disabled:after": {
    border: 0,
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      border: 0,
    },
    "&:hover fieldset": {
      border: 0,
    },
    "&.Mui-focused fieldset": {
      border: 0,
    },
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    WebkitTextFillColor: "white",
  },
  "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":
    {
      boxShadow: "0 0 0px 1000px transparent inset",
      WebkitBoxShadow: "0 0 0px 1000px transparent inset",
      transition: "background-color 5000s ease-in-out 0s",
    },
});

export default function Info({
  user,
  toSaveOrNot,
  handleCancel,
  editActive,
  handleEdit,
  formState,
  handleAgentDataChange,
  toggleHide,
  setToggleHide,
  classes,
  mobile,
}: any) {
  const bDay = user?.birthDate
    ? new Date(user?.birthDate).toISOString().split("T")[0]
    : null;
  const emailError = formState.email.length > 4 && !validEmail(formState.email);
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classes.onboardingInfoContainer}
    >
      <Grid item xs={2} />
      <Grid item xs={8}>
        <Typography align="center" variant="h6" sx={{ m: 0 }}>
          Info On File
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <ButtonGroup sx={{ float: "right", mb: 2 }}>
          <Button
            disabled={!editActive ? false : emailError}
            size="small"
            variant="contained"
            sx={{ color: "white" }}
            color={!editActive ? "primary" : "success"}
            onClick={() => toSaveOrNot(!editActive ? "Edit" : "Save")}
          >
            {!editActive ? (
              <Edit fontSize="small" style={{ marginRight: 3 }} />
            ) : (
              <Save fontSize="small" style={{ marginRight: 3 }} />
            )}
            {!mobile && (!editActive ? "Edit" : "Save")}
          </Button>
          {editActive && (
            <Button
              size="small"
              variant="contained"
              onClick={handleCancel}
              sx={{ color: "white" }}
              color="error"
            >
              {<Cancel fontSize="small" style={{ marginRight: 3 }} />}
              {!mobile && "Cancel"}
            </Button>
          )}
        </ButtonGroup>
      </Grid>
      <Grid item container justifyContent="center" xs={12}>
        <Typography
          align="center"
          variant="caption"
          sx={{ color: "#D4AF37", mb: 2 }}
        >
          Please ensure the accuracy of the information below before submitting
          a contract request.
        </Typography>
      </Grid>

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <List className={classes.onbordingInfoList}>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>
                First Name
              </Typography>
              {editActive ? (
                <CustomTextField
                  variant="standard"
                  onChange={handleEdit}
                  autoComplete="firstName"
                  name="firstName"
                  sx={{ width: "50%" }}
                  value={formState.firstName}
                />
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                  }}
                >
                  {user?.firstName}
                </Typography>
              )}
            </ListItem>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>
                Last Name
              </Typography>
              {editActive ? (
                <CustomTextField
                  variant="standard"
                  autoComplete="lastName"
                  onChange={handleEdit}
                  name="lastName"
                  sx={{ width: "50%" }}
                  value={formState.lastName}
                />
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                  }}
                >
                  {user?.lastName}
                </Typography>
              )}
            </ListItem>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>
                Email{" "}
              </Typography>
              {editActive ? (
                <CustomTextField
                  variant="standard"
                  onChange={handleEdit}
                  error={emailError}
                  name="email"
                  helperText={emailError && "Invalid Email"}
                  value={formState.email}
                  sx={{
                    width: "50%",
                    "& .MuiOutlinedInput-root": {
                      WebkitBoxShadow: "green",
                    },
                  }}
                />
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                    wordWrap: "break-word",
                  }}
                >
                  {user?.email}
                </Typography>
              )}
            </ListItem>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>Phone</Typography>
              {editActive ? (
                <CustomTextField
                  variant="standard"
                  value={formState.phone}
                  onChange={handleEdit}
                  onInput={(e) => numbersOnlyInput(e, 10)}
                  name="phone"
                  sx={{ width: "50%" }}
                />
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                  }}
                >
                  {user?.phone}
                </Typography>
              )}
            </ListItem>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>NPN </Typography>
              {editActive ? (
                <CustomTextField
                  variant="standard"
                  onChange={handleEdit}
                  name="npn"
                  sx={{ width: "50%" }}
                  value={formState.npn}
                />
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                  }}
                >
                  {user?.npn}
                </Typography>
              )}
            </ListItem>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>
                Gender
              </Typography>
              {editActive ? (
                <CustomTextField
                  variant="standard"
                  select
                  autoComplete="gender"
                  onChange={handleEdit}
                  name="gender"
                  value={formState.gender}
                  sx={{ width: "50%" }}
                >
                  {genders.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomTextField>
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                  }}
                >
                  {user?.gender}
                </Typography>
              )}
            </ListItem>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>
                Resident State
              </Typography>
              {editActive ? (
                <CustomTextField
                  variant="standard"
                  autoComplete="residentState"
                  onChange={handleEdit}
                  value={formState.residentState}
                  name="residentState"
                  sx={{ width: "50%" }}
                />
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                  }}
                >
                  {user?.residentState}
                </Typography>
              )}
            </ListItem>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>
                Birth Date
              </Typography>
              {editActive ? (
                <div style={{ width: "50%" }}>
                  <CustomDatePicker
                    variant="standard"
                    onChange={handleAgentDataChange}
                    value={formState.birthDate}
                    name="birthDate"
                    label=""
                    customClass={classes.infoDatePicker}
                  />
                </div>
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                  }}
                >
                  {bDay}
                </Typography>
              )}
            </ListItem>
            <ListItem divider>
              <Typography sx={{ width: "50%", pb: 1, pt: 1 }}>SSN </Typography>
              {editActive ? (
                <CustomTextField
                  variant="standard"
                  onChange={handleEdit}
                  name="ssn"
                  sx={{ width: "50%" }}
                  type={toggleHide ? "text" : "password"}
                  value={formState.ssn}
                  onInput={(e) => numbersOnlyInput(e, 10)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setToggleHide(!toggleHide)}
                          edge="end"
                          size="small"
                          style={{ color: "inherit", marginRight: 10 }}
                        >
                          {toggleHide ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <Typography
                  align="right"
                  sx={{
                    width: "50%",
                    fontSize: 17,
                    padding: "8px 14px",
                    paddingInlineStart: 2,
                    m: 0,
                  }}
                >
                  {user?.ssn && user?.ssn.substring(user?.ssn.length - 4)}
                </Typography>
              )}
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Grid>
  );
}
