import React from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import useCalcStyles from "./useStyles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { Close } from "@mui/icons-material";

const YearMonthPicker = (props) => {
  const {
    selectedEligibleDate,
    handleEligibleDateChange,
    selectedEnrollDate,
    handleEnrollDateChange,
    penalty,
  } = props;
  const classes = useCalcStyles();
  const isError =
    selectedEligibleDate &&
    new Date(selectedEligibleDate) < new Date("01/01/2006");
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container className={classes.datePicker}>
        <Grid
          item
          xs={12}
          sx={{
            margin: "10px",
          }}
        >
          <DatePicker
            className={classes.date}
            views={["month", "day", "year"]}
            minDate={new Date("01/01/2006")}
            onChange={handleEligibleDateChange}
            value={selectedEligibleDate}
            inputProps={{
              style: { paddingLeft: 4 },
            }}
            InputProps={{
              style: { paddingLeft: 2.15 },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    color="secondary"
                    disabled={!selectedEligibleDate}
                    onClick={() => handleEligibleDateChange(null)}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                fullWidth
                label="Eligible date"
                error={isError}
                helperText={
                  isError ? "Minimum Date of 01/01/2006 Required" : ""
                }
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            margin: "10px",
          }}
        >
          <DatePicker
            className={classes.date}
            views={["month", "day", "year"]}
            disabled={isError || !selectedEligibleDate}
            onChange={(e) => handleEnrollDateChange(e)}
            value={selectedEnrollDate}
            inputProps={{
              style: { paddingLeft: 4 },
            }}
            InputProps={{
              style: { paddingLeft: 2.15 },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    color="secondary"
                    disabled={!selectedEnrollDate}
                    onClick={() => handleEnrollDateChange(null)}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            renderInput={(props) => (
              <TextField {...props} fullWidth label="Enroll date" />
            )}
          />
        </Grid>
        <Grid item xs={12} className={classes.result}>
          <Typography
            variant="subtitle2"
            style={{ color: "#d03232" }}
            gutterBottom
          >
            This is only an estimate. Your actual penalty cost may vary.
          </Typography>
          <Typography variant="body1">Penalty: {penalty}</Typography>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default YearMonthPicker;
