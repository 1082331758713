import React, { useState, useEffect } from "react";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { numbersOnlyInput } from "../../../../services/utils";
import CustomDatePicker from "../../../../common/CustomDatePicker";
import { getStates } from "../../../../services/BDSWebService";

const ChangeRequestContent = (props) => {
  const {
    submitChangeRequest,
    formChanges,
    changeRequestFormValues,
    classes,
    message,
  } = props;

  const birthDateError = !changeRequestFormValues.birthDate;
  const emailError = !changeRequestFormValues.email;
  const phoneError =
    !changeRequestFormValues.phone &&
    !changeRequestFormValues.mobilePhone &&
    !changeRequestFormValues.workPhone;
  const genderError = !changeRequestFormValues.sex;
  const resStateError = !changeRequestFormValues.residentLicenseState;
  const resLicenseError = !changeRequestFormValues.residentLicenseNumber;
  const [unitedStates, setUnitedStates] = useState([]);

  useEffect(() => {
    getStates().then((res) => setUnitedStates(res.data.getStates));
  }, []);

  // }
  return (
    <form onSubmit={submitChangeRequest}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={message && 12} align="center">
          {message ?? (
            <Typography style={{ color: "#D4AF37" }} variant="caption">
              Please make changes where you need. Please know requested changes
              will not be immediate, thanks for your patience!
            </Typography>
          )}
        </Grid>
        <Grid item>
          <TextField
            name="suffix"
            label="Suffix"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.suffix}
          />
        </Grid>
        <Grid item>
          <CustomDatePicker
            value={changeRequestFormValues.birthDate}
            onChange={formChanges}
            name="birthDate"
            label="DOB"
            variant="filled"
            customClass={classes.changeRequestDob}
            error={birthDateError}
          />
        </Grid>
        <Grid item>
          <TextField
            error={emailError}
            name="email"
            label="Email"
            type="email"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.email}
          />
        </Grid>
        <Grid item>
          <TextField
            error={phoneError}
            name="phone"
            label="Phone"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            onInput={(e) => numbersOnlyInput(e, 10)}
            value={changeRequestFormValues.phone}
          />
        </Grid>
        <Grid item>
          <TextField
            error={phoneError}
            name="mobilePhone"
            label="Mobile Phone"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            onInput={(e) => numbersOnlyInput(e, 10)}
            value={changeRequestFormValues.mobilePhone}
          />
        </Grid>
        <Grid item>
          <TextField
            error={phoneError}
            name="workPhone"
            label="Work Phone"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            onInput={(e) => numbersOnlyInput(e, 10)}
            value={changeRequestFormValues.workPhone}
          />
        </Grid>
        <Grid item>
          <TextField
            name="faxNumber"
            label="Fax Number"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            onInput={(e) => numbersOnlyInput(e, 10)}
            value={changeRequestFormValues.faxNumber}
          />
        </Grid>
        <Grid item>
          <TextField
            name="address1"
            label="Address Line 1"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.address1}
          />
        </Grid>
        <Grid item>
          <TextField
            name="address2"
            label="Address Line 2"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.address2}
          />
        </Grid>
        <Grid item>
          <TextField
            name="address3"
            label="Address Line 3"
            variant="filled"
            size="small"
            onChange={formChanges}
            value={changeRequestFormValues.address3}
          />
        </Grid>
        <Grid item>
          <TextField
            name="city"
            label="City"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.city}
          />
        </Grid>
        <Grid item>
          <TextField
            select
            name="state"
            label="State"
            variant="filled"
            size="small"
            onChange={formChanges}
            value={changeRequestFormValues.state}
            style={{ width: 205, minWidth: 205 }}
          >
            {unitedStates?.map((state) => (
              <MenuItem key={state.name} value={state.code}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            name="postalCode"
            label="Postal Code"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.postalCode}
          />
        </Grid>
        <Grid item>
          <TextField
            name="nickName"
            label="NickName"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.nickName}
          />
        </Grid>
        <Grid item>
          <TextField
            error={genderError}
            name="sex"
            label="Gender"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.sex}
            select
            style={{ width: 205, minWidth: 205 }}
          >
            <MenuItem aria-label="None" value="">
              None
            </MenuItem>
            <MenuItem value="MALE">MALE</MenuItem>
            <MenuItem value="FEMALE">FEMALE</MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            name="npn"
            label="NPN"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.npn}
          />
        </Grid>
        <Grid item>
          <TextField
            error={resStateError}
            select
            name="residentLicenseState"
            label="Resident License State"
            variant="filled"
            size="small"
            color="primary"
            style={{ width: 205, minWidth: 205 }}
            onChange={formChanges}
            value={changeRequestFormValues.residentLicenseState}
          >
            {unitedStates?.map((state) => (
              <MenuItem key={state.name} value={state.code}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            error={resLicenseError}
            name="residentLicenseNumber"
            label="Resident Lic #"
            variant="filled"
            size="small"
            color="primary"
            onChange={formChanges}
            value={changeRequestFormValues.residentLicenseNumber}
          />
        </Grid>
      </Grid>
    </form>
  );
};
export default ChangeRequestContent;
