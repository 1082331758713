import React from "react";
import { Typography, Grid, List, ListItem, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { observer } from "mobx-react";
import { reduceDate } from "../../../../services/utils";
import { useTheme } from "@mui/styles";

const AgentInfoPage = observer((props) => {
  const { classes, clickedAgent, openDialog, contact } = props;
  const theme = useTheme();
  const {
    email,
    phone,
    mobilePhone,
    workPhone,
    address1,
    address2,
    address3,
    city,
    state,
    postalCode,
  } = contact;

  const updateRequired =
    !clickedAgent.firstName ||
    !clickedAgent.lastName ||
    !clickedAgent.birthDate ||
    !email ||
    (!mobilePhone && !workPhone && !phone) ||
    !clickedAgent.sex ||
    !clickedAgent.ssn ||
    !clickedAgent.residentLicenseState ||
    !clickedAgent.residentLicenseNumber;
  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        className={classes.list}
        style={{
          marginBottom: "0px !important",
          position: "relative",
        }}
      >
        <Typography
          className={classes.text}
          variant="h5"
          gutterBottom
          align="center"
        >
          Agent Info
        </Typography>
        <IconButton
          style={{
            position: "absolute",
            top: "50%",
            right: 2,
            transform: "translate(-50%, -50%)",
          }}
          onClick={() => openDialog("cr")}
        >
          <Edit
            style={{
              color: updateRequired ? theme.palette.secondary.main : "#eee",
            }}
          />
        </IconButton>
      </Grid>
      <List className={classes.list}>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>
                First Name
              </Typography>
            </Grid>
            <Grid item>
              <Typography color={!clickedAgent.firstName ? "secondary" : ""}>
                {clickedAgent.firstName ? clickedAgent.firstName : "UPDATE"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>
                Middle Name
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{clickedAgent?.middleName}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>
                Last Name
              </Typography>
            </Grid>
            <Grid item>
              <Typography color={!clickedAgent.lastName ? "secondary" : ""}>
                {clickedAgent.lastName ? clickedAgent.lastName : "UPDATE"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>Suffix</Typography>
            </Grid>
            <Grid item>
              <Typography>{clickedAgent.Suffix}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>DOB</Typography>
            </Grid>
            <Grid item>
              <Typography color={!clickedAgent.birthDate ? "secondary" : ""}>
                {reduceDate(
                  clickedAgent.birthDate ? clickedAgent.birthDate : "UPDATE"
                )}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>Email</Typography>
            </Grid>
            <Grid item>
              <Typography color={clickedAgent.email ? "secondary" : ""}>
                {email ? email : "UPDATE"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>Phone</Typography>
            </Grid>
            <Grid item>
              <Typography
                color={mobilePhone || workPhone || phone ? "" : "secondary"}
              >
                {mobilePhone || workPhone || phone || "UPDATE"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>Address</Typography>
            </Grid>
            <Grid item>
              <Typography
                color={!address1 && !address2 && !address3 ? "secondary" : ""}
              >
                {address1} {address2} {address3} {city ? city + ", " : null}{" "}
                {state} {postalCode}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>NickName</Typography>
            </Grid>
            <Grid item>
              <Typography>{clickedAgent.nickName}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>Gender</Typography>
            </Grid>
            <Grid item>
              <Typography color={!clickedAgent.sex ? "secondary" : ""}>
                {clickedAgent.sex ? clickedAgent.sex : "UPDATE"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>SSN</Typography>
            </Grid>
            <Grid item>
              <Typography color={!clickedAgent.ssn ? "secondary" : ""}>
                {clickedAgent.ssn
                  ? clickedAgent.ssn?.substring(clickedAgent.ssn.length - 4)
                  : "Not available"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>NPN</Typography>
            </Grid>
            <Grid item>
              <Typography color={!clickedAgent.npn ? "secondary" : ""}>
                {clickedAgent.npn ? clickedAgent.npn : "UPDATE"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>
                Resident License State
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color={!clickedAgent.residentLicenseState ? "secondary" : ""}
              >
                {clickedAgent.residentLicenseState
                  ? clickedAgent.residentLicenseState
                  : "UPDATE"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem divider>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography className={classes.listItemText}>
                Resident Lic #
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color={!clickedAgent.residentLicenseNumber ? "secondary" : ""}
              >
                {clickedAgent.residentLicenseNumber
                  ? clickedAgent.residentLicenseNumber
                  : "UPDATE"}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Grid>
  );
});

export default AgentInfoPage;
