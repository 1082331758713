import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";

export default function PreSubmit(props: any) {
  const { currentCarrier, statesRequested, additionalFields, corp } = props;
  const { ssn, dob, gender, license, notes } = additionalFields;
  const { isAsCorp, corpName, corpNpn, corpTin } = corp;
  return (
    <Box
      sx={{ width: "100%" }}
      component={Grid}
      container
      justifyContent="space-between"
      alignContent="center"
    >
      <Card
        sx={{
          height: "100%",
          width: "100%",
          background: "transparent",
          p: 0,
          m: 0,
          boxShadow: "none",
        }}
      >
        <CardHeader
          title={
            <Typography
              sx={{ fontFamily: "sans-serif", fontSize: 18 }}
              gutterBottom
              align="center"
            >
              If your information is correct, please click submit to process.
              Otherwise, click back to modify your request.
            </Typography>
          }
        // AW-371 4/13/2023: Leaving this commented out code for now, in case they change their mind 
        // about limiting the number of requests allowed in one session.
        //  subheader={
        //    <Typography sx={{ fontSize: 12, color: "#D4AF37" }} align="center">
        //      You can submit up to a total of 5 contract requests individually
        //      during this onboarding process.
        //    </Typography>
        //  }
        />
        <CardContent
          component={Grid}
          container
          justifyContent="space-between"
          alignContent="center"
          sx={{ p: 0, m: 0 }}
        >
          <Grid item xs={3}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontSize: 18 }}>
              Carrier:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              align="right"
              variant="subtitle1"
              gutterBottom
              sx={{ fontSize: 18, wordBreak: "break-word" }}
            >
              {currentCarrier?.name}
            </Typography>
          </Grid>
          {dob !== "" && (
            <>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="left"
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontSize: 18 }}
                >
                  DOB:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="right"
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontSize: 18 }}
                >
                  {new Date(dob).toISOString().split("T")[0]}
                </Typography>
              </Grid>
            </>
          )}
          {ssn !== "" && (
            <>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="left"
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontSize: 18 }}
                >
                  SSN:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="right"
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontSize: 18 }}
                >
                  {ssn && ssn.substring(ssn.length - 4)}
                </Typography>
              </Grid>
            </>
          )}
          {license !== "" && (
            <>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="left"
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontSize: 18 }}
                >
                  License Number:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="right"
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontSize: 18 }}
                >
                  {license}
                </Typography>
              </Grid>
            </>
          )}
          {gender !== "" && (
            <>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="left"
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontSize: 18 }}
                >
                  Gender:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="right"
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontSize: 18 }}
                >
                  {gender}
                </Typography>
              </Grid>
            </>
          )}
          {isAsCorp && (
            <>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="left"
                  variant="subtitle1"
                  gutterBottom
                >
                  Corp Name:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="right"
                  variant="subtitle1"
                  gutterBottom
                >
                  {corpName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="left"
                  variant="subtitle1"
                  gutterBottom
                >
                  Corp NPN:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  align="right"
                  variant="subtitle1"
                  gutterBottom
                >
                  {corpNpn}
                </Typography>
              </Grid>
              {corpTin && (
                <>
                  <Grid item xs={6}>
                    <Typography
                      noWrap
                      align="left"
                      variant="subtitle1"
                      gutterBottom
                    >
                      Corp TIN:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      noWrap
                      align="right"
                      variant="subtitle1"
                      gutterBottom
                    >
                      {corpTin}
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item xs={6}>
            <Typography noWrap align="left" variant="subtitle1" gutterBottom>
              States Requested:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap align="right" variant="subtitle1" gutterBottom>
              {statesRequested.map((state: any) => state.code).join(", ")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap align="left" variant="subtitle1" gutterBottom>
              Notes:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap align="right" variant="subtitle1" gutterBottom>
              {notes}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
