import React from "react";
import SplashLogo from "../img/splashLogo.png";
const AWAYPageLoading = (props) => {
  const { flip, title } = props;
  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        placeContent: "center",
        placeItems: "center",
        textAlign: "center",
      }}
    >
      <img
        src={SplashLogo}
        alt="SplashLogo"
        height="150"
        width="150"
        className="animate__animated animate__bounceInDown"
      />
      <h1
        className={flip ? `animate__animated animate__flipInX` : ""}
        style={{ minHeight: 100 }}
      >
        {title}
      </h1>
    </div>
  );
};
export default AWAYPageLoading;
