import { makeAutoObservable } from "mobx";
import { AwayNotifications, FilterInput } from ".";
import {
  NotificationsRepository,
  INotificationsRepository,
} from "./repository";
import { makePersistable, stopPersisting } from "mobx-persist-store";
import { ObservableQuery } from "@apollo/client";
import { CancellablePromise } from "mobx/dist/internal";

export class NotificationsStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: "NotificationsStore",
      properties: ["notifications"],
      storage: window.localStorage,
    });
  }
  notifications: AwayNotifications[] | null = null;
  loading: boolean = false;
  error: Error | null = null;
  subscription: any | null = null;
  _repository: INotificationsRepository = NotificationsRepository();
  _agentId: string | null = null;
  _currentLoad: CancellablePromise<any> | null = null;
  get badgeCount(): number | null {
    if (this.notifications && this._agentId) {
      return this.notifications.filter((data) => {
        const agentIds = data.agentIds.find(
          (data) => data.agentId === this._agentId
        );
        return agentIds?.status === false && agentIds?.seen === false;
      }).length;
    }
    return null;
  }

  _cancelCurrentLoad() {
    if (this._currentLoad) {
      this._currentLoad.cancel();
      this._currentLoad = null;
    }
  }

  disposePersist(): void {
    stopPersisting(this);
  }
  clearError(): void {
    this.error = null;
  }

  async subscribe(id: string): Promise<any> {
    return this._repository.subscribe(
      { agentId: id },
      (callback: AwayNotifications) => {
        if (callback) {
          if (this.notifications !== null) {
            this.notifications = [
              ...this.notifications,
              callback,
            ] as AwayNotifications[];
          } else {
            this.notifications = [callback];
          }
        }
      }
    );
  }

  async unSubscribe(): Promise<void> {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  async _processLoad(filters: FilterInput[]): Promise<ObservableQuery<any>> {
    return await this._repository.load({ filters });
  }

  *changeNotificationStatus(notificationsId: number, status: boolean) {
    this.loading = true;
    try {
      const result: AwayNotifications =
        yield this._repository.changeNotificationStatus({
          notificationsId,
          status,
        });
      if (result && this.notifications) {
        this.notifications = this.notifications.map((data) => {
          if (data.id === result.id) {
            return result;
          }
          return data;
        });
      }
    } catch (error) {
      this.error = error as Error;
    }
    this.loading = false;
  }

  *changeNotificationSeen(notificationsId: number[], status: boolean) {
    this.loading = true;
    try {
      const results: AwayNotifications[] =
        yield this._repository.changeNotificationSeen({
          notificationsId,
          status,
        });
      if (results && this.notifications) {
        this.notifications = this.notifications.map((d1) => {
          const newResult = results.find((d2) => d2.id === d1.id);
          if (newResult) {
            return newResult;
          }
          return d1;
        });
      }
    } catch (error) {
      this.error = error as Error;
    }
    this.loading = false;
  }

  clearStates() {
    this.notifications = null;
    this._agentId = null;
    this.subscription = null;
  }

  *load(id: string) {
    this.loading = true;
    this.clearStates();
    const filters: FilterInput[] = [
      { field: "agentId", comparison: "==", value: id },
    ];
    this._agentId = id;
    try {
      this._processLoad(filters).then((result: ObservableQuery) => {
        this.subscription = result.subscribe({
          next: ({ data }) => {
            this.notifications = data.awayNotifications.data;
          },
          error: async (e) => {
            this.error = e;
          },
        });
      });
      this.subscribe(id);
    } catch (error) {
      this.error = error as Error;
    }
    this.loading = false;
  }
}
