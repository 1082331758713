import { TextField, Typography } from "@mui/material";
import React from "react";
import SubmitButton from "./SubmitButton";

export function ObSubmitTextField(props) {
  const {
    submitFunc,
    value,
    changeFunc,
    successStatus,
    successClass,
    submitLoading,
    status,
    helperText,
    disabled,
  } = props;
  return (
    <form onSubmit={(e) => (value !== "" ? submitFunc(e) : e.preventDefault())}>
      <TextField
        autoFocus
        variant="outlined"
        value={value}
        onChange={(e) => changeFunc(e)}
        InputProps={{
          endAdornment: (
            <SubmitButton
              type="button"
              size="small"
              variant="contained"
              color="primary"
              onClick={submitFunc}
              disabled={value === "" || successStatus || disabled}
              className={successClass}
              submitLoading={submitLoading}
              status={status}
              style={{ marginLeft: 14 }}
              displayText="Submit"
            />
          ),
        }}
        FormHelperTextProps={{ style: { maxWidth: "fit-content", color: "#f2fe01"  } }}
        helperText={
          <Typography
            component="span"
            className={successClass}
            variant="subtitle2"
          >
            {helperText}
          </Typography>
        }
        error={status === "Failed"}
        disabled={status === "Success" || disabled}
      />
    </form>
  );
}
