import { Button, Grid, TextField, Typography, Link } from "@mui/material";
import React from "react";
import StepComplete from "./StepComplete";

const IdCheckStep = (props) => {
  const {
    registrationId,
    registrationIdChange,
    registrationIdCheck,
    theme,
    checkRegistrationId,
  } = props;
  const isNanError = isNaN(Number(registrationId));

  const helperText = isNanError
    ? "Invalid characters. You must only enter numbers."
    : "";

  const error = isNanError;

  const complete = registrationIdCheck.status === "c";

  return (
    <Grid item xs={12} container alignItems="center" justifyContent="center">
      {complete ? (
        <StepComplete completeMessage="ID found! Click the right arrow below to move on." />
      ) : (
        <>
          <Grid item xs={12} align="center" style={{ marginBottom: 20 }}>
            <Typography variant="h6">
              Welcome! Please enter the ID you were provided
            </Typography>
            <Typography sx={{ fontStyle: "italic", padding: 2 }}>
              If you have already registered, <Link href="/">click here</Link>{" "}
              to sign in
            </Typography>
          </Grid>
          <Grid item xs={12} align="center" style={{ marginBottom: 20 }}>
            <TextField
              variant="outlined"
              label="Registration ID"
              value={registrationId}
              onChange={(e) => registrationIdChange(e)}
              error={error}
              helperText={helperText}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Button
              variant="contained"
              style={{
                background:
                  registrationId.length !== 8
                    ? "grey"
                    : registrationIdCheck.status === "nc"
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                color: "white",
              }}
              onClick={() => checkRegistrationId()}
              disabled={registrationId.length !== 8 || error}
            >
              {registrationId.length === 8
                ? registrationIdCheck.message
                : "Please Enter ID"}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default IdCheckStep;
