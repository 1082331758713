import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  useTheme,
} from "@mui/material";
import CommonDialogTitle from "../../../common/CommonDialogTitle";
import Swal from "sweetalert2";
import PasswordResetContents from "./PasswordResetContents";
import {
  ampForgotPassword,
  forgotPasswordSubmit,
} from "../../../services/amplify/ampClient";

const PasswordReset = (props) => {
  const { openPasswordReset, closePwResetModal, mQuery } = props;
  const theme = useTheme();
  const [verifyState, setVerifyState] = useState({
    username: "",
    code: "",
    password: "",
  });
  const [isVerified, setIsVerified] = useState(false);

  const setState = (v, k) => {
    setVerifyState({ ...verifyState, [k]: v });
  };

  const sendConfirmationCode = async () => {
    try {
      await ampForgotPassword(verifyState.username.trim());
      Swal.fire({
        title: "Code Sent",
        icon: "info",
        text: "Please click confim below to go to the next step",
        showCancelButton: false,
        confirmButtonText: "Confirm",
      }).then(() => {
        setIsVerified(true);
      });
    } catch (err) {
      Swal.fire({
        titleText: err.message,
        icon: "error",
        html: "There was an issue with your request, please try again or contact your Upline.",
      });
    }
  };

  const submitAndClose = async () => {
    try {
      await forgotPasswordSubmit({
        ...verifyState,
        username: verifyState.username.trim(),
      });
      Swal.fire({
        title: "Password Changed",
        icon: "success",
        text: "Now that you have reset your password, please login again with your new login credentials.",
        showCancelButton: false,
        confirmButtonText: "Let's Go!",
      }).then(() => {
        clearStatesAndClose();
      });
    } catch (err) {
      Swal.fire({
        title: "Oops!",
        icon: "error",
        text: "There was an error with your request. Please try again, or if the problem persists, contact Berwick Agent Support at 1-888-745-2320.",
      });
    }
  };

  useEffect(() => {
    return () => {
      clearStatesAndClose();
    };
  }, []);

  const { username, password, code } = verifyState;
  const checkPass = password.length >= 8;
  const verifyPassword = () => {
    return (
      checkPass &&
      /[!@#$%^&*(){}[\]|/\\:;"'<>,.?~`]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[A-Z]/.test(password)
    );
  };

  const clearStatesAndClose = () => {
    closePwResetModal();
    setVerifyState({
      username: "",
      code: "",
      password: "",
    });
    setIsVerified(false);
  };

  return (
    <Dialog
      open={openPasswordReset}
      onClose={clearStatesAndClose}
      maxWidth="sm"
      fullWidth
      fullScreen={mQuery}
    >
      <CommonDialogTitle title="Password Reset" close={clearStatesAndClose} />
      <DialogContent style={{ padding: "30px 20px" }}>
        <PasswordResetContents
          username={username}
          setState={setState}
          password={password}
          code={code}
          isVerified={isVerified}
          checkPass={checkPass}
          verifyPassword={verifyPassword}
          theme={theme}
          mQuery={mQuery}
        />
      </DialogContent>
      <DialogActions
        style={{
          margin: 10,
        }}
      >
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={
                isVerified ? !password || !code || !verifyPassword() : !username
              }
              onClick={() =>
                isVerified ? submitAndClose() : sendConfirmationCode()
              }
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordReset;
