import React from "react";
import "./App.css";
import { Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import AgentProfile from "./components/pages/agents/AgentProfile";
import AgentWayLogin from "./components/pages/AgentWayLogin";
import history from "./services/history";
import { createTheme, ThemeProvider } from "@mui/material";
import { observer } from "mobx-react";
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CalcForm from "./components/Calculator/CalcForm";
import Registration from "./components/pages/register/Registration";
import TopSecretComponent from "./components/pages/topsecret/TopSecretComponent";
import NotFound from "./components/pages/NotFound";
import ReactPWAInstallProvider from "@elilondon/react-pwa-install";
import OnBoard from "./components/pages/onboard/OnBoard";
import Onboarding from "./components/pages/onboarding/";

const App = observer(() => {
  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#0094ff",
      },
      secondary: {
        main: "#ed2518",
      },
      success: {
        main: "#4caf50",
      },
    },
    components: {
      MuiTableRow: {
        styleOverrides: {
          head: {
            backgroundColor: "#fff",
          },
          root: { backgroundColor: "#272727" },
        },
      },
      MuiTableCell: { styleOverrides: { head: { color: "#000" } } },
      MuiPaper: {
        styleOverrides: { root: { backgroundColor: "#141414", color: "#fff" } },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ReactPWAInstallProvider>
        <main style={{ height: "100vh" }}>
          <ToastContainer />
          <CssBaseline />
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={AgentWayLogin} />
              <Route exact path="/Home" component={Home} />
              <Route exact path="/AgentProfile" component={AgentProfile} />
              <Route exact path="/Calculator" component={CalcForm} />
              <Route exact path="/Registration" component={Registration} />
              <Route exact path="/Dashboard" component={Onboarding} />
              <Route exact path="/TopSecret" component={TopSecretComponent} />
              <Route exact path="/Onboard" component={OnBoard} />
              <Route exact path="/Onboard/:code" component={OnBoard} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </main>
      </ReactPWAInstallProvider>
    </ThemeProvider>
  );
});

export default App;
