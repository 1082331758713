import { blue } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useCalcStyles = makeStyles((theme) => {
  const fontFam = '"Open Sans", sans-serif !important';
  const inputBorder = "2px solid #f0f0f0 !important";
  return {
    app: {
      backgroundColor: "#f9fafb",
      fontFamily: fontFam,
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      margin: 0,
    },
    title: {
      textAlign: "center",
      backgroundColor: "#fff !important",
      padding: 7,
      color: "#333 !important",
      display: "block",
    },
    text: {
      color: "#D4AF37 !important",
      lineHeight: 2,
    },
    intructions: {
      border: "1px solid #D4AF37 !important",
      borderRadius: "5px",
      padding: "1em !important",
      marginBottom: "1.5em !important",
    },
    eligibleDateTitle: {
      textAlign: "center",
    },
    form: {
      padding: 40,
    },
    datePicker: {
      marginBottom: "20px !important",
      marginTop: "10px !important",
    },
    date: {
      "& input": {
        color: "#eee !important",
      },
      "& .MuiInputLabel-root": { color: blue[600] },
    },
    result: {
      border: inputBorder,
      borderRadius: 5,
      marginTop: "10px !important",
      padding: 20,
      backgroundColor: "#242323",
    },
  };
});

export default useCalcStyles;
