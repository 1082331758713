export const graphqlPath = "https://api.berwickinstech.com";
export const subscriptionsPath = "wss://api.berwickinstech.com";

const region = "us-east-2";
const userPoolId = "us-east-2_IO9iYsN2E";
const userPoolWebClientId = "7cdebamkd3rur8mv20ibddu4im";
const authenticationFlowType = "USER_SRP_AUTH";

export const defaultAmpConfig = {
  region,
  userPoolId,
  userPoolWebClientId,
  authenticationFlowType,
};

export const adminConfig = {
  userPoolId: "us-east-2_Rrbe3HxvA",
  userPoolWebClientId: "hso00nqiju4ugo0gvn7hfh795",
};
