import React, { useState, useEffect } from "react";
import { Dialog, Grid, Typography, useMediaQuery } from "@mui/material";
import useCalcStyles from "./useStyles";
import YearMonthPicker from "./YearMonthPicker";
import CommonDialogTitle from "../../common/CommonDialogTitle";
import { getCalc } from "../../services/BDSWebService";

const CalcForm = (props) => {
  const classes = useCalcStyles();
  const { open, handleClose } = props;
  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;

  const [selectedEligibleDate, handleEligibleDateChange] = useState(null);
  const [selectedEnrollDate, handleEnrollDateChange] = useState(null);

  const [penalty, setPenalty] = useState(0);

  //! Current as of May 2023
  /*
  From Director >> .327 x n months as of 06/06/2023
  */

  useEffect(() => {
    const goGetTheRate = async () => {
      const res = await getCalc(selectedEligibleDate, selectedEnrollDate);
      setPenalty(res.data.pdpCalc.toFixed(2));
    };
    if (selectedEligibleDate && selectedEnrollDate) {
      goGetTheRate();
    } else {
      setPenalty(0);
    }
  }, [selectedEligibleDate, selectedEnrollDate]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const closeAll = () => {
    handleEligibleDateChange(null);
    handleEnrollDateChange(null);
    setPenalty(0);
    handleClose();
  };

  return (
    <Dialog
      onClose={closeAll}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={mQuery}
    >
      <Grid>
        <CommonDialogTitle
          title="MEDICARE PART D PENALTY CALCULATOR"
          close={handleClose}
          variant="h6"
        />
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.intructions}>
            <Typography variant="subtitle2" className={classes.text}>
              * Please fill in the dates below to get an estimated penalty
              amount.
            </Typography>

            <Typography
              variant="subtitle2"
              className={classes.text}
              gutterBottom
            >
              * If your eligibility date is prior to January 2006 you will use
              January 2006 as the starting date
            </Typography>
          </div>
          <Typography variant={mQuery ? "h6" : "h5"} align="center">
            MEDICARE ELIGIBILITY DATE
          </Typography>
          <YearMonthPicker
            selectedEligibleDate={selectedEligibleDate}
            handleEligibleDateChange={handleEligibleDateChange}
            selectedEnrollDate={selectedEnrollDate}
            handleEnrollDateChange={handleEnrollDateChange}
            penalty={penalty}
            handleClose={handleClose}
          />
        </form>
      </Grid>
    </Dialog>
  );
};

export default CalcForm;
