import { IRootStore } from "..";
import { AuthRepository } from "./repository";
import { observable, action, flow, autorun } from "mobx";
import { CancellablePromise } from "mobx/dist/internal";
import { LoginOptions, UserLoginInfo } from "./types";
import {
  currentJwtToken,
  newUserSignInFlow,
  signInFlow,
  signOut,
} from "../../services/amplify/ampClient";
import Swal from "sweetalert2";

export interface IAuthStore {
  user: UserLoginInfo | null;
  isLoggedIn: boolean;
  loading: boolean;
  error: Error | null | unknown;
  options: LoginOptions;
  setOptions: (options: LoginOptions) => void;
  userLogin: () => void;
  newUserLogin: (id: string, signUpType: string, phone: string) => void;
  logout: () => void;
  clearError: () => void;
  showPassword: boolean;
  setShowPassword: (showPassword: boolean) => void;
}

const defaultOptions = {
  username: "",
  password: "",
};

export function AuthStore(_root: IRootStore) {
  const repository = AuthRepository();
  let _currentLoginCall: CancellablePromise<any> | null = null;
  let _currentRefreshTimer: NodeJS.Timeout | null;

  const _userLogin = flow(async function* () {
    store.loading = true;
    store.user = null;
    try {
      const username = store.options.username;
      const isBerwick = username
        .toString()
        .toLowerCase()
        .includes("@berwickinsurance.com");
      const result: {
        id: any;
        loginType: any;
      } = yield signInFlow(username, store.options.password, isBerwick);
      const user = yield repository.getUserInfo(result);
      store.user = { ...user, loginType: result.loginType };
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...user, loginType: result.loginType })
      );
      sessionStorage.setItem("timeOnLogin", JSON.stringify(Date.now()));
      store.setOptions(defaultOptions);
    } catch (error) {
      const err = error as Error;
      store.user = null;
      sessionStorage.clear();
      Swal.fire("Auth Error", err.message, "error").then(() => {
        store.clearError();
      });
    }
    store.loading = false;
  });

  const _newUserLogin = flow(async function* (
    _id: string,
    signUpType: string,
    phone: string
  ) {
    store.loading = true;
    store.user = null;
    try {
      const username = store.options.username;
      const result: {
        id: any;
        loginType: any;
      } = yield newUserSignInFlow(
        username,
        store.options.password,
        _id,
        signUpType,
        phone
      );

      const updatePasswordValues = {
        id: result.id,
        login: store.options.username,
        pass: store.options.password,
      };
      yield repository.updateAgentPassword(updatePasswordValues);
      const user = yield repository.getUserInfo(result);
      store.user = { ...user, loginType: result.loginType };
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...user, loginType: result.loginType })
      );
      sessionStorage.setItem("timeOnLogin", JSON.stringify(Date.now()));
      store.setOptions(defaultOptions);
    } catch (error) {
      const err = error as Error;
      store.user = null;
      sessionStorage.clear();
      Swal.fire("Auth Error", err.message, "error").then(() => {
        store.clearError();
      });
    }
    store.loading = false;
  });

  const _cancelCurrentLogin = () => {
    if (_currentLoginCall) {
      _currentLoginCall.catch(() => null);
      _currentLoginCall.cancel();
      _currentLoginCall = null;
    }
  };

  const _cancelCurrentRefreshTimer = () => {
    if (_currentRefreshTimer) {
      clearTimeout(_currentRefreshTimer);
      _currentRefreshTimer = null;
    }
  };

  const store: IAuthStore = observable<IAuthStore>({
    get isLoggedIn() {
      return store.user !== null && store.user !== undefined;
    },
    loading: false,
    error: null,
    options: { ...defaultOptions },
    user: null,
    setOptions: action<IAuthStore["setOptions"]>("auth.setOptions", (options) =>
      Object.assign(store.options, options)
    ),
    userLogin: action<IAuthStore["userLogin"]>("auth.userLogin", () => {
      _cancelCurrentLogin();
      _cancelCurrentRefreshTimer();
      _currentLoginCall = _userLogin();
    }),
    newUserLogin: action<IAuthStore["newUserLogin"]>(
      "auth.newUserLogin",
      (id, signUpType, phone) => {
        _cancelCurrentLogin();
        _cancelCurrentRefreshTimer();
        _currentLoginCall = _newUserLogin(id, signUpType, phone);
      }
    ),
    logout: action<IAuthStore["logout"]>("auth.logout", () => {
      _cancelCurrentLogin();
      _cancelCurrentRefreshTimer();
      store.user = null;
      store.loading = false;
      store.error = null;
      store.options = { ...defaultOptions };
      sessionStorage.clear();
      signOut();
      window.location.pathname = "/";
    }),
    clearError: action("auth.clearError()", () => {
      store.error = null;
    }),
    showPassword: false,
    setShowPassword: action<IAuthStore["setShowPassword"]>(
      "auth.setShowPassword",
      (showPassword) => {
        store.showPassword = showPassword;
      }
    ),
  });

  autorun(async () => {
    const token = await currentJwtToken();
    const user =
      store.user ?? JSON.parse(sessionStorage.getItem("user") as string);
    if (token && user) {
      const timeOnLogin = sessionStorage.getItem("timeOnLogin");
      if (timeOnLogin !== null) {
        if (Date.now() - parseInt(timeOnLogin) >= 86400000) {
          store.logout();
        }
      }
    }
  });

  return store;
}
