import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  TablePagination,
  Grid,
  useMediaQuery,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { CSVLink } from "react-csv";
import { reduceDate } from "../../../../services/utils";

const ProductionDataSales = (props) => {
  const { selectedCarrier, filteredData, classes, backToFront, year } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const csvData = filteredData.map((data) => {
    const { __typename, carrierName, ...rest } = data;
    return Object.values(rest);
  });

  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;
  const headers = [
    "State",
    "County",
    mQuery ? "Eff-Date" : "Effective Date",
    "MA",
    "MS",
    "PDP",
  ];

  const asOfDate = filteredData[0]
    ? new Date(filteredData[0].recordDate).getFullYear() > year
      ? "12/31/" + year
      : reduceDate(filteredData[0].recordDate)
    : "null";

  return (
    <Grid
      style={{
        transform: "rotateY(180deg)",
        paddingBottom: 42,
      }}
    >
      <Paper style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <IconButton size="small" onClick={() => backToFront()}>
              <KeyboardBackspace />
            </IconButton>
          </Grid>
          <Grid item align="center" xs>
            <Typography variant="body2" style={{ color: "#D4AF37" }}>
              {filteredData[0]?.carrierName === "Aetna US Health Care"
                ? "Aetna MA"
                : filteredData[0]?.carrierName}
              {filteredData[0] ? " as of " + asOfDate : null}
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        {filteredData.length === 0 && selectedCarrier ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            style={{ height: 375, width: "inherit" }}
          >
            <Grid item xs={5} align="center">
              <LinearProgress /> Loading!
            </Grid>
          </Grid>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((data, i) => (
                  <TableCell
                    key={`${i}${data}`}
                    style={{ fontWeight: "bolder", padding: mQuery && 8 }}
                  >
                    {data}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, i) => (
                  <TableRow key={`${i}${data.state}${data.county}`}>
                    <TableCell
                      style={{ padding: mQuery && 8 }}
                      component="th"
                      scope="row"
                    >
                      {data.state}
                    </TableCell>
                    <TableCell style={{ padding: mQuery && 8 }}>
                      {data.county}
                    </TableCell>
                    <TableCell style={{ padding: mQuery && 8 }}>
                      {data.effectivedate}
                    </TableCell>
                    <TableCell style={{ padding: mQuery && 8 }}>
                      {data.maProduction}
                    </TableCell>
                    <TableCell style={{ padding: mQuery && 8 }}>
                      {data.medsuppProduction}
                    </TableCell>
                    <TableCell style={{ padding: mQuery && 8 }}>
                      {data.pdpProduction}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.muiTablePaginationToolbar}
                  rowsPerPageOptions={[5, 10, 25]}
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
        <CSVLink
          data={csvData}
          headers={headers}
          filename={`${
            selectedCarrier === "Aetna US Health Care"
              ? "AetnaMA"
              : selectedCarrier
          } ${year}.csv`}
          target="_blank"
          id="CSVLink"
        />
      </Paper>
    </Grid>
  );
};

export default ProductionDataSales;
