import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Home } from "@mui/icons-material";
import VertMenu from "../ui-components/VertMenu";
import { observer } from "mobx-react";

const AgentProfileToolbar = observer((props) => {
  const { classes, profileProps } = props;
  const { clickedAgent, backToHome } = profileProps;
  return (
    <Toolbar>
      <IconButton
        edge="start"
        className={classes.menuButton}
        onClick={backToHome}
      >
        <Home />
      </IconButton>
      <Typography className={classes.leadProfileTitle} variant="h6" noWrap>
        {clickedAgent.firstName + " " + clickedAgent.lastName}
      </Typography>
      <VertMenu classes={classes} profileProps={profileProps} />
    </Toolbar>
  );
});

export default AgentProfileToolbar;
