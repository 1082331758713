import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  useMediaQuery,
  Grid,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import { sendAwayEmailAws } from "../services/BDSWebService";
import CommonDialogTitle from "./CommonDialogTitle";
import Swal from "sweetalert2";

const FeedbackModal = (props) => {
  const { openFeedback, closeFeedbackModal, agentId } = props;
  const [feedback, setFeedback] = useState("");
  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;
  const submitFeedBack = async () => {
    try {
      await sendAwayEmailAws({
        FromEmailAddress: "emailsubmit@agentway.io",
        Destination: {
          ToAddresses: ["feedback@agentway.io"],
          CcAddresses: [],
          BccAddresses: [],
        },
        Content: {
          Simple: {
            Subject: { Data: "Feedback from AWAY user" },
            Body: {
              Text: {
                Data: `${feedback} from agentId: ${
                  agentId ?? "Id not found or non agent"
                }`,
              },
            },
          },
        },
      });
      Swal.fire({
        title:
          "Your feedback was submitted successfully. Thank you for your time!",
        icon: "success",
      }).then(() => {
        setFeedback("");
        closeFeedbackModal();
      });
    } catch (err) {
      await sendAwayEmailAws({
        FromEmailAddress: "errorsubmit@agentway.io",
        Destination: {
          ToAddresses: ["error@agentway.io"],
          CcAddresses: [],
          BccAddresses: [],
        },
        Content: {
          Simple: {
            Subject: { Data: "Error Feedback Modal AWAY" },
            Body: { Text: { Data: `${err}-${err.message}` } },
          },
        },
      });
      Swal.fire({
        title: "Oops!",
        icon: "error",
        text: "There was an error with your submission, please try again.",
      });
    }
  };
  return (
    <Dialog
      open={openFeedback}
      onClose={closeFeedbackModal}
      maxWidth="sm"
      fullWidth
      fullScreen={mQuery}
    >
      <CommonDialogTitle title="Feedback" close={closeFeedbackModal} />
      <DialogContent style={{ padding: "30px 20px" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            align="center"
            style={{ color: "#D4AF37", margin: 20 }}
          >
            <span style={{ fontWeight: "bolder" }}>
              If you have questions about the information/data in AgentWay,
              please contact Agent Support at 1-888-745-2320
            </span>
            <br />
            <br />
            Provide any feedback, bugs, or issues you faced while using our
            product here.
            <br />
            <br />
            We thank you for your feedback!
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={6}
              label="Feedback goes here"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          margin: 10,
        }}
      >
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={!feedback}
              onClick={() => submitFeedBack()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackModal;
