import React, { useContext } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { ExitToApp, Feedback, MoreVert } from "@mui/icons-material";
import { RootStoreContext } from "../../store";
import { observer } from "mobx-react";
import NotificationButton from "../../common/NotificationButton";

const VertMenu = observer((props) => {
  const { classes, profileProps } = props;
  const {
    openDialog,
    openVertMenu,
    openVert,
    closeVertMenu,
    clickedAgent,
    badgeCount,
    openFeedbackModal,
  } = profileProps;
  const { authStore } = useContext(RootStoreContext);

  const handleLogout = () => {
    authStore.logout();
  };
  return (
    <div>
      <NotificationButton
        openDialog={openDialog}
        agentId={clickedAgent.id}
        badgeCount={badgeCount}
      />
      <IconButton
        onClick={openVertMenu}
        edge="end"
        aria-controls="vert-Menu"
        aria-haspopup="true"
        className={classes.endButtons}
        size="small"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="vert-Menu"
        anchorEl={openVert}
        open={Boolean(openVert)}
        onClose={closeVertMenu}
        keepMounted
      >
        <MenuItem onClick={() => handleLogout()} className={classes.endButtons}>
          <ListItemText primary="Logout" />
          <ListItemIcon style={{ justifyContent: "flex-end" }}>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          onClick={() => openFeedbackModal()}
          className={classes.endButtons}
        >
          <ListItemText primary="Feedback" />
          <ListItemIcon style={{ justifyContent: "flex-end" }}>
            <Feedback fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  );
});
export default VertMenu;
