export const reduceDate = (date) => {
  if (date !== null) {
    date = date.split(" ")[0];
    return date;
  }
};

export const contactIsExistingAndActive = () => {
  return (contact) => {
    return (
      contact.status === "ACTIVE" &&
      (contact.email ||
        contact.mobilePhone ||
        contact.workPhone ||
        contact.phone)
    );
  };
};

export const validPhone = (phone) => {
  const pattern = /\(?[0-9]{3}\)?\s?-?[0-9]{3}-?[0-9]{4}/;
  return pattern.test(phone);
};

export const validEmail = (email) => {
  const emailValidationReg =
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return emailValidationReg.test(email);
};

export const numbersOnlyInput = (e, fieldLength) => {
  let numericValue = e.target.value.replace(/[^0-9]+/g, "");
  e.target.value = numericValue.toString().slice(0, fieldLength);
};

export const deleteKeys = (keys, obj, type) => {
  switch (type) {
    case "keysbytype":
      Object.keys(obj).forEach(
        (_key) => !keys.includes(_key) && delete obj[_key]
      );
      break;
    case "keystoremove":
      keys.forEach((key) => delete obj[key]);
      break;
    default:
      break;
  }
};

export const genders = ["MALE", "FEMALE"];
