import {
  AppBar,
  Button,
  Grid,
  MobileStepper,
  Toolbar,
  useTheme,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import useStyles from "../../../common/useStyles";
import OnboardStepOne from "./OnboardStepOne";
import OnboardStepTwo from "./OnboardStepTwo";
import AWAYPageLoading from "./AWAYPageLoading";
import OnboardStepThree from "./OnboardStepThree";
import {
  findCode,
  findNpn,
  getStates,
  registerOnboardLead,
} from "../../../services/BDSWebService";
import Swal from "sweetalert2";
import OnboardStepFour from "./OnboardStepFour";
import RegOnbAppBar from "../register/RegOnbAppBar";
import { RootStoreContext } from "../../../store";
import { observer } from "mobx-react";
import { OnboardUserStore } from "../../../store/onboard-user/store";
import UtilityLoading from "../../../common/UtilityLoading";

const defaultSTVals = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  recruiterId: "",
  residentState: "",
};

const OnBoard = observer((props) => {
  const [onboardUserStore] = useState(() => new OnboardUserStore());
  const classes = useStyles();
  const theme = useTheme();
  const { match } = props;
  const { code } = match.params;
  const { authStore } = useContext(RootStoreContext);
  const [pageLoading, setPageLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [npnFound, setNpnFound] = useState(false);
  const [flip, setFlip] = useState(false);
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("Welcome To Agent Way Onboarding!");
  const [subTitle, setSubTitle] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [stepThreeValues, setStepThreeValues] = useState(defaultSTVals);
  const [recruiterCode, setRecruiterCode] = useState("");
  const [stepOneComplete, setStepOneComplete] = useState(false);
  const [stepTwoComplete, setStepTwoComplete] = useState(false);
  const [stepThreeComplete, setStepThreeComplete] = useState(false);
  const [npn, setNpn] = useState("");
  const [recruiterInfo, setRecruiterInfo] = useState({});
  const [states, setStates] = useState([]);
  const findRecruiter = async (enteredCode) => {
    return await findCode(enteredCode);
  };
  const findAgent = async (npn) => {
    return await findNpn(npn);
  };

  useEffect(() => {
    if (code) {
      findCode(code).then((data) => {
        if (data.data?.findCode?.id) {
          setRecruiterInfo(data.data?.findCode);
          setStepThreeValues((rest) => {
            return { ...rest, recruiterId: data.data.findCode.id };
          });
          setActiveStep(1);
          setStepOneComplete(true);
        }
      });
    }
  }, [code]);

  useEffect(() => {
    getStates().then((res) => setStates(res.data.getStates));
    return () => {
      setStates([]);
    };
  }, []);

  useEffect(() => {
    setPageLoading(true);
    let timer1 = setTimeout(() => {
      setTitle("Let's Get Started..");
      setFlip(true);
    }, 2000);
    let timer2 = setTimeout(() => {
      setPageLoading(false);
      setTitle("");
    }, 4000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      setPageLoading(false);
      setSubmitLoading(false);
      setNpnFound(false);
      setFlip(false);
      setStatus("");
      setTitle("Welcome To Agent Way Onboarding!");
      setSubTitle("");
      setActiveStep(0);
      setStepThreeValues(defaultSTVals);
      setRecruiterCode("");
      setStepOneComplete(false);
      setStepTwoComplete(false);
      setStepThreeComplete(false);
      setRecruiterInfo({});
    };
  }, []);

  const completeRegistration = (e) => {
    e.preventDefault();
    const isOnboardRegistered = onboardUserStore.isOnboardRegistered;
    if (isOnboardRegistered) {
      authStore.newUserLogin("", "onboard", stepThreeValues.phone);
    } else {
      registerOnboardLead({
        ...stepThreeValues,
        npn,
      })
        .then(() => {
          onboardUserStore.setIsOnboardRegistered();
          authStore.newUserLogin("", "onboard", stepThreeValues.phone);
        })
        .catch((error) => {
          Swal.fire("Oops!", error.message, "error");
        });
    }
  };
  const password = authStore.options.password;
  const checkPass = password.length >= 8;
  const verifyPassword = () => {
    return (
      /[!@#$%^&*(){}[\]|/\\:;"'<>,.?~`]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[A-Z]/.test(password)
    );
  };
  const steps = [
    <OnboardStepOne
      submitLoading={submitLoading}
      setSubmitLoading={setSubmitLoading}
      status={status}
      setStatus={setStatus}
      classes={classes}
      findRecruiter={findRecruiter}
      recruiterCode={recruiterCode}
      setRecruiterCode={setRecruiterCode}
      setStepThreeValues={setStepThreeValues}
      stepOneComplete={stepOneComplete}
      setStepOneComplete={setStepOneComplete}
      setRecruiterInfo={setRecruiterInfo}
    />,
    <OnboardStepTwo
      submitLoading={submitLoading}
      setSubmitLoading={setSubmitLoading}
      status={status}
      setStatus={setStatus}
      classes={classes}
      setNpnFound={setNpnFound}
      npnFound={npnFound}
      setSubTitle={setSubTitle}
      setTitle={setTitle}
      findAgent={findAgent}
      setStepTwoComplete={setStepTwoComplete}
      stepTwoComplete={stepTwoComplete}
      npn={npn}
      setNpn={setNpn}
    />,
    <OnboardStepThree
      stepThreeValues={stepThreeValues}
      setStepThreeValues={setStepThreeValues}
      setStepThreeComplete={setStepThreeComplete}
      states={states}
    />,
    <OnboardStepFour
      email={stepThreeValues.email}
      theme={theme}
      checkPass={checkPass}
      verifyPassword={verifyPassword}
    />,
  ];

  const next = () => {
    setStatus("");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const previous = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (pageLoading) {
    return <AWAYPageLoading flip={flip} title={title} />;
  }

  const getStyleBlock = (state, lastStep) => {
    return {
      margin: 10,
      border: state
        ? "1px solid rgb(0, 148, 255)"
        : lastStep
        ? "1px solid rgb(0, 128, 0)"
        : "none",
      color: state || lastStep ? "white" : "",
      boxShadow: state
        ? `inset 0 -4px 0 ${theme.palette.primary.main}`
        : lastStep
        ? `inset 0 -4px 0 ${theme.palette.success.main}`
        : "",
      background: state
        ? "rgba(0, 148, 255, 0.3)"
        : lastStep
        ? "rgba(0, 128, 0, 0.3)"
        : "none",
    };
  };

  const addNextButtonStyle =
    (activeStep === 0 && stepOneComplete) ||
    (activeStep === 1 && stepTwoComplete) ||
    (activeStep === 2 && stepThreeComplete);

  const lastStep = activeStep === steps.length - 1;

  const backButtonDisable =
    activeStep === 0 || (activeStep === 1 && stepOneComplete);

  const stepFourDisable = !verifyPassword();
  const { recruiterName, recruiterEmail, recruiterPhone } = recruiterInfo;
  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{
        minHeight: "100vh",
        display: "grid",
        placeContent: "center",
        placeItems: "center",
        gap: "2em",
      }}
    >
      <RegOnbAppBar
        appBarTitle="Onboarding"
        recruiterName={recruiterName}
        recruiterFontSize="1.1rem"
        recruiterEmail={recruiterEmail}
        recruiterPhone={recruiterPhone}
      />
      {(recruiterEmail || recruiterPhone) && (
        <Toolbar style={{ minHeight: 23 }} />
      )}
      <Toolbar />
      {steps[activeStep]}
      <Toolbar />
      <AppBar className={classes.appBar}>
        <Toolbar disableGutters>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={4}>
              <MobileStepper
                style={{ background: "none", width: "100%" }}
                steps={steps.length}
                position="static"
                variant="progress"
                activeStep={activeStep}
                nextButton={
                  <Button
                    onClick={lastStep ? completeRegistration : next}
                    disabled={
                      (activeStep === 0 && !stepOneComplete) ||
                      (activeStep === 1 && !stepTwoComplete) ||
                      (activeStep === 2 && !stepThreeComplete) ||
                      (lastStep && stepFourDisable)
                    }
                    endIcon={<KeyboardArrowRight />}
                    style={getStyleBlock(
                      addNextButtonStyle,
                      lastStep && !stepFourDisable
                    )}
                  >
                    {lastStep ? "Finish" : "Next"}
                  </Button>
                }
                backButton={
                  <Button
                    onClick={previous}
                    disabled={backButtonDisable}
                    startIcon={<KeyboardArrowLeft />}
                    style={getStyleBlock(!backButtonDisable)}
                  >
                    Prev
                  </Button>
                }
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <UtilityLoading loadingState={authStore.loading}>
        Loading...
      </UtilityLoading>
    </div>
  );
});

export default OnBoard;
