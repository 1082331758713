import React from "react";
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Typography, Box, Grid } from "@mui/material";
import ProductionCard from "./ProductionCard";
import InnerLoading from "../../../../common/InnerLoading";
import { reduceDate } from "../../../../services/utils";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function tabProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const CurrentProduction = (props) => {
  const {
    classes,
    productionPageValue,
    handleProductionPageChange,
    agentProduction,
    allCarrierSalesData,
    year,
  } = props;
  if (agentProduction.length === 0) {
    return <InnerLoading>Loading Production!</InnerLoading>;
  }

  const mainProductionData = (year) => {
    return agentProduction.production.filter((prod) => {
      return prod.year === year;
    });
  };

  const salesYearData = (year) => {
    return allCarrierSalesData.filter((data) => {
      const reducedDate = reduceDate(data.effectivedate);
      return reducedDate.substring(reducedDate.length - 4) === year;
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      align="center"
      style={{ marginBottom: "1em" }}
    >
      <Typography className={classes.text} variant="h5" gutterBottom>
        Current Production
      </Typography>
      <AppBar position="static" style={{ maxWidth: 600 }}>
        <Tabs
          variant="fullWidth"
          value={productionPageValue}
          onChange={handleProductionPageChange}
        >
          <LinkTab label={year[0]} href={`/${year[0]}`} {...tabProps(0)} />
          <LinkTab label={year[1]} href={`/${year[1]}`} {...tabProps(1)} />
          <LinkTab label={year[2]} href={`/${year[2]}`} {...tabProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={productionPageValue} index={0}>
        {ProductionCard(
          classes,
          mainProductionData(String(year[0])),
          salesYearData(String(year[0])),
          year[productionPageValue]
        )}
      </TabPanel>
      <TabPanel value={productionPageValue} index={1}>
        {ProductionCard(
          classes,
          mainProductionData(String(year[1])),
          salesYearData(String(year[1])),
          year[productionPageValue]
        )}
      </TabPanel>
      <TabPanel value={productionPageValue} index={2}>
        {ProductionCard(
          classes,
          mainProductionData(String(year[2])),
          salesYearData(String(year[2])),
          year[productionPageValue]
        )}
      </TabPanel>
    </Grid>
  );
};

export default CurrentProduction;
