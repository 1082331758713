import React, { useState } from "react";
import { useSpring, animated as a } from "react-spring";
import ProductionDataTable from "./ProductionDataTable";
import ProductionDataSales from "./ProductionDataSales";

export default function ProductionCard(
  classes,
  agentProduction,
  allCarrierSalesData,
  year
) {
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [flipped, setFlipped] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(2500px) rotateY(${flipped ? 0 : 180}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  var maTotal = 0;
  var msTotal = 0;
  var pdpTotal = 0;

  agentProduction.forEach((data) => {
    maTotal += data.ma;
    msTotal += data.ms;
    pdpTotal += data.pdp;
  });

  const selectCarrier = (data) => {
    const carrier =
      data.carrier === "Aetna MA" ? "Aetna US Health Care" : data.carrier;
    setSelectedCarrier(carrier);
    setFlipped((state) => !state);
  };

  const backToFront = () => {
    setSelectedCarrier(null);
    setFlipped((state) => !state);
  };

  const filteredData = allCarrierSalesData.filter((data) =>
    data.carrierName.includes(selectedCarrier)
  );

  return (
    <div style={{ height: 600, placeItems: "center", display: "grid" }}>
      <a.div
        style={{
          willChange: "transform, opacity",
          opacity: opacity.interpolate((o) => 1 - o),
          transform,
          pointerEvents: !flipped ? "all" : "none",
          position: "absolute",
          height: 600,
          width: 600,
          maxWidth: "100%",
        }}
      >
        <ProductionDataTable
          agentProduction={agentProduction}
          selectCarrier={selectCarrier}
          selectedCarrier={selectedCarrier}
          maTotal={maTotal}
          msTotal={msTotal}
          pdpTotal={pdpTotal}
          filteredData={filteredData}
          setFlipped={setFlipped}
        />
      </a.div>
      <a.div
        style={{
          willChange: "transform, opacity",
          opacity,
          transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
          pointerEvents: flipped ? "all" : "none",
          position: "absolute",
          height: 600,
          width: 600,
          maxWidth: "100%",
        }}
      >
        <ProductionDataSales
          selectedCarrier={selectedCarrier}
          filteredData={filteredData}
          classes={classes}
          backToFront={backToFront}
          year={year}
        />
      </a.div>
    </div>
  );
}
